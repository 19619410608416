@import '../../variables.sass';
.home-heading {
    width: 100%;
    display: inline-block;
    h2 {
        font-size: 40px;
        line-height: 44px;
        color: #12111B;
        margin: 0;
        position: relative;
        padding: 0 0 25px;
        font-weight: 700;
        @media (max-width: 1550px) {
            font-size: 32px; }
        @media (max-width: $breakpoint-ipad-pro) {
            font-size: 27px;
            padding: 0 0 18px; }
        @media (max-width: $breakpoint-ipad) {
            font-size: 18px;
            line-height: 1.2; }
        span {
            color: #852EF2; }
        &:after,
        &::before {
            content: '';
            bottom: 0;
            position: absolute; }
        &::after {
            width: 59px;
            height: 8px;
            background: #5540BF;
            border-radius: 100px;
            left: 0; }
        &::before {
            width: 14px;
            height: 8px;
            background: #25A375;
            border-radius: 100px;
            left: 63px; }
        &.light-heading {
            color: #fff;
            span {
                color: #fff; }
            &::after {
                background-color: #FF7A00; }
            &::before {
                background-color: #25A375; } } }
    &.center-heading {
        text-align: center;
        @media (max-width: $breakpoint-ipad) {
            text-align: left; }
        h2 {
            width: auto;
            display: inline-block; } } }
