$body-color: #757575;
$primary-color: #3032C8;
$light-blue-color: #506bf0;
$dark-grey-color: #1B1B1B;
$purple-bg-color: #6F70ED;
$grey-color: #212121;
$light-grey-color: #ececec;
$secondary-grey-color: #0B132A;
$secondary-light-grey-color: #4F5665;
$optional-grey-color: #18181B;
$optional-light-grey-color: #E2E2E2;
$light-bg-color: #F7F7F7;
$light-greybg-color: #757575;
$charcoal-color: #676767;
$white-color: #ffffff;
$black-color: #000000;
$red-color: #FF0000;
$green-color: #46E169;
$dark-green-color: #1DA33A;
$facebook-color: #3B5998;
$google-color: #2D3748;
$breakpoint-big-desktop: 1920px;
$breakpoint-md-desktop: 1440px;
$breakpoint-small-desktop: 1599px;
$breakpoint-laptop-desktop: 1550px;
$breakpoint-mini-desktop: 1399px;
$breakpoint-ipad-pro: 1025px;
$breakpoint-mac-desktop: 1270px;
$breakpoint-ipad: 991px;
$breakpoint-mobile: 767px;
$ligh-bg-gradient: linear-gradient(180deg, #6F70ED 30.03%, rgba(111, 112, 237, 0.2) 100%);
$light-green: #30C891;
$light-grey-link: #757575;
$grey-link: #9B9B9B;
$box-shadow-color: #a1a1f3;
$in-progress-color: #F1BA2B;
$success-color: #5cb85c;
$inner-grey-color: #EEEEEE;
$inner-active-color: #D3D3FC;
$light-purple-color: #993EE0;
$blue-color: #044AFF;
$grey-background-color: #cacaca;
$grey-light-progress-color: #CDCDCD;
$grey-font-color: #333739;
$green-success-color: #C5FFDC;
$orange-disabled-color: #FF6363;
$green-url-color: #23632A;
$blue-title-color: #091FA4;
$purple-heading-color: #9D50FF;
$green-circle-color: #25A375;
$orange-secondary-color: #FF7A00;
