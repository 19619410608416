@import 'variables.sass';
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');
.upload-feature-img-wrap {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 13px 0 0;
    .upload-feature-img {
        background-color: $white-color;
        border: 1px dashed $body-color;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 289px;
        max-width: 100%;
        height: 178px;
        margin: 0 auto;
        font-size: 18px;
        line-height: 1.5;
        color: $body-color;
        position: relative;
        @media (max-width: $breakpoint-mobile) {
            font-size: 14px;
            height: 140px; }
        img {
            width: 100%;
            height: 100%;
            max-width: calc(100% + 4px);
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            object-fit: cover;
            border-radius: 8px;
            position: absolute;
            left: -2px; } } }
.url-control {
    .form-control,
    .input-select-menu-link .ris-input {
        background-image: url('/images/link.png');
        background-position: 15px center;
        background-repeat: no-repeat;
        padding-left: 50px !important; } }
.dashboard-wrapper {
    width: 100%;
    display: inline-block;
    background-color: $light-bg-color;
    @media (max-width: $breakpoint-ipad-pro) {
        overflow: hidden; }
    .drawer-toggle {
        position: absolute;
        right: 16px;
        top: 24px;
        line-height: 29px;
        width: 29px;
        height: 29px;
        background-color: rgba(196, 196, 196, 0.5);
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        @media (max-width: $breakpoint-ipad) {
            display: none; }
        // @media (max-width: $breakpoint-ipad)
        //     line-height: 43px
        //     width: 46px
        //     height: 46px
        //     background-color: rgba(39, 39, 39, 0.5)
        //     border-radius: 4px
        //     right: -20px
        svg {
            transform: rotate(180deg); }
        // .mobile-drawer-icon
        //     display: none
        //     @media (max-width: $breakpoint-ipad)
        //         display: inline-block
        // .desktop-drawer-icon
        //     display: inline-block
        //     @media (max-width: $breakpoint-ipad)
 }        //         display: none
    .dashboard-menu {
        width: 265px;
        display: inline-block;
        vertical-align: top;
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.18));
        background-color: $light-blue-color;
        // padding: 55px 19px 55px 16px
        position: fixed;
        top: 0;
        transform: translateX(-211px);
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: 17px 56px 55px 16px;
        z-index: 10;
        transition: all 500ms;
        @media (max-width: $breakpoint-ipad-pro) {
            transform: translateX(-265px); }
        @media (max-width: $breakpoint-mobile) {
            width: 240px; }
        .sidebar-logo {
            width: 100%;
            display: inline-block;
            text-align: left;
            padding: 0 16px;
            img {
                width: 128px; } }
        .sidebar-view-site {
            width: 100%;
            display: inline-block;
            text-align: left;
            padding: 0 16px;
            margin: 18px 0 17px;
            a {
                color: $white-color;
                font-size: 14px;
                font-weight: 600;
                span {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: underline; }
                svg {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 3px; } } }
        ul {
            li {
                margin: 0 0 15px;
                @media (max-width: $breakpoint-ipad-pro) {
                    margin: 0 0 5px; }
                a {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: bold;
                    color: $white-color;
                    padding: 16px;
                    border-radius: 16px;
                    @media (max-width: $breakpoint-ipad-pro) {
                        padding: 10px 15px;
                        font-size: 14px; }
                    svg {
                        path,
                        rect {
 } } }                           // fill: $white-color
                &:hover,
                &:active,
                &:focus {
                    a {
                        text-decoration: none;
                        color: $white-color;
                        &:hover,
                        &:active,
                        &:focus {
                            color: $dark-grey-color;
                            background: $white-color;
                            svg {}
                            path,
                            rect {
                                fill: $dark-grey-color; } } } }
                &.active {
                    & > a {
                        background-color: $white-color;
                        color: $dark-grey-color;
                        &:hover,
                        &:active,
                        &:focus {
                            color: $dark-grey-color; }
                        svg {
                            path,
                            rect {
                                fill: $dark-grey-color; } } } } }
            .sub-menu {
                padding: 10px 0 0 18px;
                li {
                    a {
                        padding: 8px; } } } }
        // &.expand-menu
        //     width: auto
        //     padding: 55px 10px
        //     ul
        //         li
        //             a
        //                 font-size: 0
        //                 padding: 5px
        // @media (max-width: $breakpoint-ipad)
        //     width: auto
        //     padding: 55px 10px
        //     ul
        //         li
        //             a
        //                 font-size: 0
        //                 padding: 5px
        &.toggle-sidebar {
            transform: translateX(0);
            padding-right: 16px;
            & + .dashboard-data {
                margin-left: 265px;
                width: calc(100% - 265px);
                @media (max-width: $breakpoint-ipad-pro) {
                    margin-left: 0;
                    width: 100%;
                    margin-left: 0;
                    transform: translateX(265px); }
                @media (max-width: $breakpoint-mobile) {
                    transform: translateX(240px); } }
            .drawer-toggle {
                svg {
                    transform: rotate(0deg); } } } }
    .dashboard-data {
        width: 80%;
        width: calc(100% - 56px);
        margin-left: 56px;
        position: relative;
        display: inline-block;
        vertical-align: top;
        padding: 30px 80px 100px 25px;
        transition: all 500ms;
        @media (max-width: $breakpoint-ipad) {
            width: 100%;
            margin-left: 0;
            transform: none;
            padding: 30px 30px 50px; }
        @media (max-width: $breakpoint-mobile) {
            padding: 20px 10px 50px; }
        .dashboard-body {
            width: 100%;
            display: inline-block;
            .dashboard-header {
                .connect-social {
                    a {
                        margin: 0 5px 0 0; }
                    img {
                        width: 28px; } }
                display: block;
                width: 100%;
                justify-content: space-between;
                align-items: flex-start;
                @media (max-width: $breakpoint-mobile) {
                    flex-direction: column; }
                .dashboard-title {
                    h1 {
                        color: $dark-grey-color;
                        font-size: 34px;
                        font-weight: 900;
                        line-height: 1.3;
                        margin: 0;
                        @media (max-width: $breakpoint-ipad-pro) {
                            font-size: 28px; }
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 20px; }
                        .dashboard-site-name {
                            color: $dark-grey-color;
                            font-size: 34px;
                            font-weight: 900;
                            line-height: 1.3;
                            max-width: 355px;
                            overflow: hidden;
                            // text-overflow: ellipsis
                            // white-space: nowrap
                            // display: inline-block
                            padding: 0;
                            margin: 0;
                            vertical-align: bottom;
                            @media (max-width: $breakpoint-ipad-pro) {
                                font-size: 28px; }
                            @media (max-width: $breakpoint-mobile) {
                                font-size: 20px; } }
                        a {
                            color: $body-color;
                            font-size: 14px;
                            line-height: 1.5;
                            text-decoration: underline;
                            font-weight: normal;
                            &:hover {
                                color: $primary-color;
                                font-weight: 600; } } }
                    h3 {
                        color: $dark-grey-color;
                        font-size: 28px;
                        font-weight: 900;
                        line-height: 1.3;
                        margin: 0; }
                    h4 {
                        color: $dark-grey-color;
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 1.4;
                        margin: 0; }
                    h5 {
                        color: $primary-color;
                        font-size: 16px;
                        line-height: 1.5;
                        letter-spacing: 0.5px;
                        font-weight: 400;
                        margin: 0;
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 14px; }
                        a {
                            padding: 0 2px;
                            color: $primary-color;
                            &:hover,
                            &:focus,
                            &:active {
                                text-decoration: none;
                                color: $primary-color; } }
                        // .success
                        //     color: $green-color
                        //     svg
                        //         path
                        //             fill: $green-color
                        // .in-progress
                        //     color: $primary-color
                        //     svg
                        //         path
 }                        //             fill: $primary-color
                    .dashboard-btns {
                        margin: 17px 0 0; } }
                .dashboard-actions {
                    width: 54%;
                    display: inline-block;
                    vertical-align: top;
                    text-align: right;
                    @media (max-width: $breakpoint-mobile) {
                        width: 100%;
                        text-align: center;
                        margin: 20px 0 0; }
                    .search-form {
                        width: 297px;
                        max-width: 60%;
                        margin-right: 0;
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        @media (max-width: $breakpoint-mobile) {
                            max-width: calc(100% - 60px);
                            margin-right: 5px; }
                        .form-group {
                            margin: 0;
                            .form-control {
                                padding: 10px 15px 10px 45px;
                                @media (max-width: $breakpoint-mobile) {
                                    padding: 10px 10px 10px 35px; } } }
                        .btn {
                            &.btn-primary {
                                &.btn-search {
                                    background-color: transparent;
                                    border: none;
                                    position: absolute;
                                    top: 13px;
                                    left: 15px;
                                    padding: 0;
                                    @media (max-width: $breakpoint-mobile) {
                                        top: 11px;
                                        left: 10px; }
                                    img {
                                        @media (max-width: $breakpoint-mobile) {
                                            max-width: 18px; } } } } } }
                    .btn-filter {
                        display: inline-block;
                        vertical-align: top;
                        background-color: $white-color;
                        border-radius: 4px;
                        width: 48px;
                        height: 48px;
                        line-height: 48px;
                        text-align: center;
                        margin-left: 25px;
                        @media (max-width: $breakpoint-mobile) {
                            width: 35px;
                            height: 35px;
                            line-height: 27px;
                            padding: 4px;
                            margin-left: 10px; }
                        img {
                            display: inline-block; } } }
                .dashboard-btns {
                    .btn {
                        padding: 8px 20px; } }
                .author-info {
                    hr {
                        border-top: 1px solid $black-color; }
                    .form-group {
                        margin: 0 0 5px;
                        input,
                        textarea {
                            font-size: 14px;
                            border: 1px solid #ced4da;
                            padding: 8px;
                            border-radius: 5px; }
                        input {
                            min-height: 0;
                            height: auto; } }
                    .author-img {
                        cursor: pointer;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 15px;
                        width: 90px;
                        img {
                            width: 80px;
                            height: 80px;
                            border-radius: 50px;
                            display: inline-block;
                            object-fit: cover; } }
                    .author-name {
                        cursor: pointer;
                        display: inline-block;
                        vertical-align: middle;
                        text-align: left;
                        max-width: 70%;
                        max-width: calc(100% - 105px);
                        h5 {
                            font-size: 20px;
                            line-height: 1.3;
                            font-weight: 700;
                            color: $dark-grey-color;
                            margin: 0;
                            text-transform: capitalize;
                            word-break: break-word;
                            @media (max-width: $breakpoint-mobile) {
                                font-size: 16px; } }
                        h6 {
                            color: #9E9E9E;
                            font-size: 16px;
                            line-height: 1.5;
                            font-weight: 400;
                            letter-spacing: 0.5px;
                            margin: 0 0 8px;
                            @media (max-width: $breakpoint-mobile) {
                                font-size: 14px; } } } } }
            .dashboard-body-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 35px 0 15px;
                h2 {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 1.3;
                    color: $dark-grey-color;
                    margin: 0;
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 18px; } }
                .btn.btn-primary {
                    @media (max-width: $breakpoint-mobile) {
                        padding: 11px 30px; } } }
            .dashboard-table {
                background-color: $white-color;
                border-radius: 24px;
                padding: 24px;
                margin: 0 0 30px;
                overflow: auto;
                max-height: 270px;
                table {
                    width: 100%;
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: 0.5px;
                    color: $dark-grey-color;
                    th {
                        color: $body-color;
                        font-size: 14px;
                        line-height: 1.4;
                        font-weight: normal; }
                    th,
                    td {
                        padding: 20px; }
                    a {
                        color: $dark-grey-color;
                        img {
                            width: 16px; } }
                    .view-tags {
                        color: $green-color;
                        margin-left: 8px; }
                    .table-action-btns {
                        margin-right: 8px;
                        display: inline-block;
                        vertical-align: middle;
                        span {
                            margin-left: 8px;
                            display: inline-block;
                            vertical-align: middle; }
                        img {
                            display: inline-block;
                            vertical-align: middle; }
                        svg {
                            display: inline-block;
                            vertical-align: middle; } }
                    .custom-control {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 8px;
                        .custom-control-label {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 1.4; } }
                    .table-post-title {
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 1.4;
                        letter-spacing: 0.5px;
                        display: inline-block;
                        vertical-align: middle; }
                    tbody {
                        tr {
                            border-bottom: 1px solid $optional-light-grey-color;
                            &:last-child {
                                border-bottom: none; } } } } } }
        &.blog-dashboard {
            .inject-script {
                width: 100% !important; }
            .dashboard-body {
                .dashboard-header {
                    align-items: flex-end;
                    @media (max-width: $breakpoint-mobile) {
                        flex-direction: unset; }
                    .dashboard-title {
                        @media (max-width: $breakpoint-mobile) {
                            vertical-align: middle;
                            width: 100%; }
                        h5 {
                            font-weight: 700;
                            color: $dark-grey-color; }
                        h1 {
                            // color: $primary-color
                            // font-weight: normal
                            width: auto;
                            display: inline-block;
                            vertical-align: middle; }
                        .dashboard-body-actions {
                            width: auto;
                            display: inline-block;
                            vertical-align: middle;
                            margin-left: 25px;
                            position: relative;
                            @media (max-width: $breakpoint-mobile) {
                                margin: 10px 0 0;
                                width: 100%;
                                display: inline-block; }
                            .sort-blog-wrap {
                                display: inline-block;
                                position: relative;
                                @media (max-width: $breakpoint-mobile) {
                                    margin-left: 20px; } }
                            .btn.btn-primary {
                                &.sort-blogs {
                                    background-color: $white-color;
                                    border-color: $white-color;
                                    border-radius: 10px;
                                    color: $dark-grey-color;
                                    font-size: 12px;
                                    padding: 5px 9px;
                                    display: none;
                                    position: relative;
                                    svg {
                                        margin-right: 7px;
                                        display: inline-block;
                                        vertical-align: middle; }
                                    @media (max-width: $breakpoint-mobile) {
                                        display: inline-block; } }
                                &.add-new-blog {
                                    @media (max-width: $breakpoint-mobile) {
 } } }                                       // display: none
                            ul.wrap-drop.drop {
                                position: absolute;
                                top: 100%;
                                z-index: 2;
                                background-color: $white-color;
                                padding: 5px 0;
                                border-radius: 10px;
                                margin: 5px 0 0;
                                min-width: 165px;
                                box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
                                right: 0;
                                li {
                                    font-size: 14px;
                                    font-weight: 700;
                                    padding: 8px 20px;
                                    &.active {
                                        background-color: $light-bg-color; }
                                    a {
                                        color: $dark-grey-color; } } } } }
                    .dashboard-actions {
                        @media (max-width: $breakpoint-mobile) {
                            width: 137px;
                            margin: 0 0 0 15px;
                            vertical-align: middle; }
                        .search-form {
                            @media (max-width: $breakpoint-mobile) {
                                width: 100%;
                                max-width: 100%;
                                margin: 0; } } } } } }
        .draft-posts {}
        border-top: 1px solid $optional-light-grey-color; }
    .data-box {
        background-color: $white-color;
        border-radius: 16px;
        padding: 16px 25px 25px;
        margin-top: 9px;
        max-width: 407px;
        width: 100%;
        @media (max-width: $breakpoint-mobile) {
            padding: 15px 10px 15px;
            max-width: 100%; }
        &:first-child {
            margin-top: 0; }
        .data-box-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            h3 {
                font-size: 24px;
                line-height: 1.3;
                font-weight: 700;
                color: $dark-grey-color;
                margin: 0 0 9px;
                @media (max-width: $breakpoint-mobile) {
                    font-size: 18px; } } }
        .addIcon {
            width: 24px;
            height: 24px;
            background: #3032C8;
            border-radius: 5px;
            display: inline-block;
            text-align: center;
            line-height: 20px;
            color: #fff;
            font-weight: 900;
            font-size: 24px;
            vertical-align: middle;
            margin-left: 4px;
            &:hover,
            &:focus {
                text-decoration: none; } }
        .flexEnd {
            display: flex;
            justify-content: flex-end; } }
    .blog-dashboard-data {
        width: 100%;
        display: inline-block;
        margin: 22px 0;
        .blog-dashboard-report {
            margin-right: .8%;
            width: 32.3%;
            display: inline-block;
            vertical-align: top;
            @media (max-width: $breakpoint-small-desktop) {
                width: 44%; }
            @media (max-width: $breakpoint-ipad-pro) {
                width: 100%;
                margin-bottom: 9px; } }
        .blog-dashboard-graph {
            width: 66.9%;
            display: inline-block;
            vertical-align: top;
            @media (max-width: $breakpoint-small-desktop) {
                width: 55%; }
            @media (max-width: $breakpoint-ipad-pro) {
                width: 100%;
                margin-bottom: 9px; } } }
    .blog-dashboard-steps {
        list-style: none;
        margin: 0;
        padding: 0;
        &.customGrid {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            li {
                padding: 0;
                border: 3px solid $dark-grey-color;
                background: $inner-grey-color;
                color: $inner-grey-color;
                width: 92px;
                height: 92px;
                margin: 0 0 6px;
                border-radius: 5px;
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin: 0 5px 0 0;
                @media (max-width: $breakpoint-mobile) {
                    width: 86px;
                    height: 86px; }
                &.active,
                &:hover,
                &:focus {
                    border: 3px solid $primary-color;
                    background: $inner-active-color;
                    color: $primary-color;
                    h4 {
                        color: $primary-color;
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 12px; } } }
                @media (max-width: $breakpoint-mobile) {
                    padding: 8px; }
                &:last-child {
                    margin-right: 0; }
                h4 {
                    margin: 0;
                    font-size: 18px;
                    line-height: 1.1;
                    color: $dark-grey-color;
                    font-weight: 700;
                    letter-spacing: 0;
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 14px; } }
                .btn {
                    &.btn-primary {
                        background-color: transparent;
                        color: $primary-color;
                        text-decoration: underline;
                        padding: 0;
                        border: none;
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 900;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                        text-decoration: none;
                        height: 100%;
                        color: $dark-grey-color;
                        margin: 0;
                        font-size: 18px;
                        line-height: 1.1;
                        color: $dark-grey-color;
                        font-weight: 700;
                        letter-spacing: 0;
                        &:hover,
                        &:active,
                        &:focus,
                        &:not(:disabled):not(.disabled):active,
                        &:not(:disabled):not(.disabled):active:focus, {
                            background-color: transparent;
                            color: $primary-color;
                            text-decoration: underline;
                            padding: 0;
                            border: none;
                            text-decoration: none; }
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 12px; } } } } } }
    .dashboard-reports-percent {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        @media (max-width: $breakpoint-mobile) {
            flex-direction: column; }
        li {
            margin-left: 2.3%;
            width: 31.8%;
            border: 2px solid $primary-color;
            border-radius: 8px;
            padding: 2px;
            text-align: center;
            display: inline-block;
            vertical-align: top;
            word-break: break-word;
            @media (max-width: $breakpoint-mobile) {
                margin-left: 0;
                margin-bottom: 8px;
                width: 100%; }
            &:first-child {
                margin-left: 0; }
            h4 {
                font-size: 30px;
                line-height: 1.3;
                letter-spacing: 0.18px;
                font-weight: 700;
                color: $dark-grey-color;
                margin: 0;
                @media (max-width: $breakpoint-small-desktop) {
                    font-size: 26px; }
                @media (max-width: $breakpoint-mobile) {
                    font-size: 20px; } }
            span {
                font-size: 18px;
                line-height: 18px;
                font-weight: 400;
                color: $dark-grey-color;
                font-family: 'Inter', sans-serif;
                display: inline-block;
                @media (max-width: $breakpoint-mobile) {
                    font-size: 14px; } } } }
    .blog-dashboard-graph {
        .dashboard-reports-percent {
            li {
                margin-left: 1%;
                width: 24%;
                @media (max-width: $breakpoint-mobile) {
                    margin-left: 0;
                    width: 100%; } } } } }
.blog-creation {
    width: 100%;
    display: inline-block;
    .blog-creation-head {
        width: 100%;
        display: inline-block;
        margin: 0 0 30px;
        .blog-creation-head-left {
            display: inline-block;
            vertical-align: top;
            width: 75%;
            width: calc(100% - 290px);
            @media (max-width: $breakpoint-ipad-pro) {
                width: 100% !important;
                margin: 0 0 15px; }
            .blog-title-group {
                &.form-group {
                    margin: 0;
                    .form-control {
                        border-radius: 0;
                        font-size: 34px;
                        line-height: 1.3;
                        color: $black-color;
                        border: 1px solid rgba(51, 51, 51, 0.3);
                        &::-webkit-input-placeholder {
                            opacity: .2; }
                        &:-moz-placeholder {
                            opacity: .2; }
                        &::-moz-placeholder {
                            opacity: .2; }
                        &:-ms-input-placeholder {
                            opacity: .2; }
                        &::-ms-input-placeholder {
                            opacity: .2; }
                        @media (max-width: $breakpoint-ipad) {
                            font-size: 22px; }
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 18px; } } } } }
        .blog-creation-author-box {
            width: 245px;
            margin: 0 0 0 45px;
            display: inline-block;
            vertical-align: top;
            @media (max-width: $breakpoint-ipad-pro) {
                width: 100% !important;
                margin: 0; }
            h5 {
                font-size: 18px;
                line-height: 1.5;
                text-align: center;
                letter-spacing: 0.5px;
                color: $light-greybg-color; }
            .author-info {
                margin: 5px 0 12px;
                .author-img {
                    display: inline-block;
                    vertical-align: bottom;
                    margin: 0 16px 0 0;
                    img {
                        width: 78px;
                        height: 78px;
                        border-radius: 16px;
                        object-fit: cover; } }
                .author-name {
                    display: inline-block;
                    vertical-align: bottom;
                    margin: 0 0 5px;
                    width: 40%;
                    width: calc(100% - 95px);
                    span {
                        display: block;
                        font-size: 20px;
                        line-height: 1.3;
                        letter-spacing: 0.18px;
                        font-weight: 700;
                        color: $dark-grey-color;
                        text-transform: capitalize;
                        margin: 0 0 9px;
                        @media (max-width: $breakpoint-ipad) {
                            font-size: 18px; } }
                    a {
                        display: block;
                        font-size: 16px;
                        line-height: 1;
                        letter-spacing: 0.5px;
                        font-weight: 900;
                        color: $primary-color;
                        text-transform: capitalize;
                        text-decoration: underline; } }
                .author-bio {
                    @media (max-width: $breakpoint-mobile) {
                        margin: 10px 0 0; }
                    h6 {
                        font-size: 16px;
                        line-height: 1.4;
                        letter-spacing: 0.5px;
                        color: $dark-grey-color;
                        font-weight: bold; }
                    p {
                        font-size: 16px;
                        line-height: 1.5;
                        color: $dark-grey-color; } } }
            .author-social-links {
                width: 100%;
                display: inline-block;
                margin: 37px 0 0;
                padding: 0;
                list-style: none;
                @media (max-width: $breakpoint-mobile) {
                    margin: 10px 0 0; }
                li {
                    display: inline-block;
                    vertical-align: top;
                    margin-left: 24px;
                    &:first-child {
                        margin-left: 0; }
                    &::after {
                        display: none; }
                    a {
                        display: inline-block;
                        width: 24px;
                        height: 24px;
                        background-color: $white-color;
                        text-align: center; } } } } }
    .blog-creation-content {
        margin-top: -30px;
        @media (max-width: $breakpoint-mobile) {
            margin-top: 0; }
        .word-count {
            width: 100%;
            display: inline-block;
            margin: 0 0 5px;
            .word-count-progressbar {
                width: 245px;
                max-width: 100%;
                display: inline-block;
                label {
                    color: $black-color;
                    font-size: 16px;
                    line-height: 1.5;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    margin: 0;
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 14px; } }
                .progress {
                    .progress-bar {
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 14px; } } } } } }

    .blog-editor {
        background-color: $optional-light-grey-color; }
    .blog-action {
        width: 100%;
        display: inline-block;
        margin: 15px 0 0;
        .blog-action-box {
            width: 254px;
            max-width: 100%;
            border: 1px solid $optional-light-grey-color;
            border-radius: 24px;
            padding: 24px 16px 10px;
            @media (max-width: $breakpoint-mobile) {
                padding: 15px; }
            h5 {
                font-size: 16px;
                line-height: 1.4;
                color: $dark-grey-color;
                font-weight: 700;
                letter-spacing: 0.5px;
                display: flex;
                justify-content: space-between;
                align-items: center; }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    width: 100%;
                    display: inline-block;
                    color: $optional-light-grey-color;
                    font-size: 14px;
                    line-height: 1.4;
                    border: 1px solid $optional-light-grey-color;
                    border-radius: 8px;
                    padding: 10px 16px;
                    margin: 10px 0 0;
                    text-transform: capitalize;
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 12px;
                        padding: 8px 10px; }
                    &.active {
                        border: 2px solid $primary-color;
                        background: rgba(48, 50, 200, 0.1);
                        color: $primary-color; }
                    span {
                        margin: 0 16px;
                        @media (max-width: $breakpoint-mobile) {
                            margin: 0 5px; } }
                    a {
                        float: right;
                        font-weight: 900;
                        color: $primary-color;
                        text-decoration: underline;
                        font-size: 16px;
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 12px; } } } } } } }
.related-blog {
    width: 100%;
    display: inline-block;
    padding: 70px 0 60px;
    border-bottom: 1px solid $optional-light-grey-color;
    @media (max-width: $breakpoint-mobile) {
        padding: 30px 0; }
    h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 0.18px;
        color: $dark-grey-color; }
    .blog-list {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin-left: 3%;
            width: 31.2%;
            display: inline-block;
            vertical-align: top;
            @media (max-width: $breakpoint-mobile) {
                width: 100%;
                margin: 0 0 20px; }
            &:nth-child(3n+1) {
                margin-left: 0;
                clear: left; }
            .blog-box {
                width: 100%;
                display: inline-block;
                .blog-img {
                    width: 100%;
                    display: inline-block;
                    margin: 0 0 12px;
                    img {
                        width: 100%;
                        height: 150px;
                        border-radius: 16px;
                        object-fit: cover; } }
                .blog-detail {
                    width: 100%;
                    display: inline-block;
                    .blog-title {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        h4 {
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 1.4;
                            color: $dark-grey-color;
                            letter-spacing: 0.5px; }
                        span {
                            font-size: 14px;
                            line-height: 1.4;
                            color: $black-color; } }
                    p {
                        font-size: 16px;
                        line-height: 1.5;
                        color: $body-color;
                        margin: 0 0 17px; }
                    .read-more {
                        color: $primary-color;
                        font-size: 16px;
                        line-height: 1;
                        text-decoration: underline;
                        font-weight: 900; } } } } } }
.blog-seo-booster {
    width: 100%;
    display: inline-block;
    padding: 16px 0;
    border-bottom: 1px solid $optional-light-grey-color;
    .seo-heading {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $body-color;
        font-size: 20px;
        line-height: 1.3;
        letter-spacing: 0.18px;
        font-weight: 700; }
    .seo-content {
        width: 100%;
        display: inline-block;
        padding: 25px 0 0;
        p {
            color: $charcoal-color;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.5px; }
        .focus-keyword {
            .form-text {
                color: $primary-color; } }
        .form-text {
            justify-content: space-between;
            display: flex;
            margin-top: 4px;
            span.primary {
                color: $primary-color; } }
        .seo-progress-bar {
            max-width: 75%;
            span {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                line-height: 1;
                font-weight: 900;
                margin: 0 8px 0 0;
                &.success {
                    color: $green-color; }
                &.danger {
                    color: $red-color; } }
            .seo-strength {
                display: inline-block;
                vertical-align: middle;
                width: 280px;
                .progress {
                    height: 8px !important;
                    border-radius: 24px !important;
                    border: none !important;
                    .progress-bar {
                        height: 8px; }
                    &.success {
                        .progress-bar {
                            background-color: $green-color; } }
                    &.danger {
                        .progress-bar {
                            background-color: $red-color; } } } } }
        .seo-form {
            padding: 24px 0 0;
            .col {
                @media (max-width: $breakpoint-mobile) {
                    flex: 0 0 100%;
                    max-width: 100%; } } }
        .seo-preview {
            .seo-meta-preview {
                width: 100%;
                display: inline-block;
                background-color: $white-color;
                border: 1px solid $optional-light-grey-color;
                border-radius: 24px;
                padding: 24px;
                margin: 0 0 6px;
                word-break: break-word;
                h4 {
                    color: $primary-color;
                    font-size: 16px;
                    line-height: 1.4;
                    font-weight: 700;
                    margin: 8px 0 0;
                    .seprator {
                        width: 2px;
                        height: 21px;
                        background-color: $primary-color;
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 5px; } }
                a {
                    color: $dark-green-color;
                    font-size: 14px;
                    line-height: 1.4; }
                p {
                    font-size: 14px;
                    line-height: 1.4;
                    color: $body-color;
                    margin: 8px 0 0; } } } } }
.blog-btns {
    width: 100%;
    display: inline-block;
    margin: 25px 0 0;
    @media (max-width: $breakpoint-mobile) {
 } }        //text-align: center

.custom-control.custom-switch {
    .custom-control-label {
        color: $dark-grey-color;
        // &::before
        //     width: 32px
        //     height: 20px
        //     border-radius: 15px
        //     border: 1px solid $primary-color
        // &::after
        //     width: 16px
        //     height: 16px
        //     background-color: $primary-color
 } }        //     top: 5.5px
.custom-rte-toolbar {
    width: 100%;
    display: inline-block;
    background-color: $white-color;
    border: 1px solid $optional-light-grey-color;
    padding: 0  !important;
    margin: 0 !important;
    .toolbar-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        .toolbar-box {
            width: auto;
            display: inline-block;
            position: relative;
            padding: 0 4.5px;
            @media (max-width: $breakpoint-mobile) {
                padding: 0; }
            &::after {
                content: '';
                position: absolute;
                background-color: $optional-light-grey-color;
                top: 0;
                bottom: 0;
                right: 0;
                width: 1px;
                @media (max-width: $breakpoint-mobile) {
                    display: none; } }
            &:last-child {
                &::after {
                    display: none; } } }
        span {
            display: inline-block;
            vertical-align: middle;
            min-width: 30px;
            line-height: 28px;
            text-align: center;
            border: 1px solid transparent;
            @media (max-width: $breakpoint-mobile) {
                min-width: 0; }
            svg {
                @media (max-width: $breakpoint-mobile) {
                    width: 23px;
                    padding: 0 2px; }
                @media (max-width: 413px) {
                    width: 15px;
                    padding: 0; } }
            &.button-active {
                border-color: rgba(0,0,0,.1); } }
        .editor-icons {
            padding: 8.3px;
            display: inline-block; }
        .custom-select {
            border: none;
            padding: 0 33px 0 13px;
            height: 35px;
            @media (max-width: $breakpoint-mobile) {
                padding: 0 13px 0 5px;
                background-position: right;
                font-size: 13px; }
            @media (max-width: 413px) {
                font-size: 12px; } } } }
.rte-editor-content {
    width: 100%;
    display: inline-block;
    background-color: $optional-light-grey-color;
    border: 1px solid $optional-light-grey-color;
    padding: 24px 52px;
    @media (max-width: $breakpoint-mobile) {
        padding: 4px; }
    .editor-content {
        background-color: $white-color;
        padding: 56px;
        min-height: 570px;
        @media (max-width: $breakpoint-mobile) {
            padding: 8px;
            min-height: 400px; } } }
.accordion {
    .card {
        border: none;
        box-shadow: none;
        background-color: transparent;
        .card-header {
            padding: 15px 0;
            margin: 0 0 10px;
            border: none;
            box-shadow: none;
            background-color: transparent;
            @media (max-width: $breakpoint-mobile) {
                padding-bottom: 0; }
            .btn.btn-link {
                color: $body-color;
                font-size: 20px;
                line-height: 1.3;
                letter-spacing: 0.18px;
                font-weight: 700;
                background-color: transparent;
                border: none;
                padding: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &:hover,
                &:active,
                &:focus {
                    outline: none;
                    background-color: transparent;
                    border: none;
                    text-decoration: none; }
                span {
                    .heading-icon {
                        display: inline-block;
                        vertical-align: middle;
                        width: 24px;
                        height: 24px;
                        line-height: 22px;
                        background-color: $white-color;
                        border-radius: 4px;
                        margin-right: 10px; } } } }
        .card-body {
            border: none;
            box-shadow: none;
            background-color: transparent;
            margin: 0;
            padding: 0; } } }
.edit-site-panel {
    width: 100%;
    display: inline-block;
    padding: 17px 0;
    border-bottom: 2px solid rgba(51, 51, 51, 0.3);
    .edit-site-btns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        .image-box {
            overflow: hidden;
            border-radius: 15px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            height: 43px;
            //border: 3px solid transparent
            &:hover,
            &:active,
            &:focus {
                filter: unset;
                box-shadow: 0 0 0 3px $primary-color;
 }                //border: 3px solid #3032c8
            img {
                border-radius: 15px; } }

        @media (max-width: $breakpoint-big-desktop) {
            flex-wrap: wrap;
 }            //justify-content: flex-start
        .form-group {
            width: auto;
            display: inline-block;
            margin: 0 0 0 0;
            @media (max-width: $breakpoint-big-desktop) {
                margin: 0 1.5% 1.5% 0;
                width: 14.8%;
                &.update-site {
                    margin: 0 0 1.5% 0 !important;
                    .btn {
                        width: 100% !important; } } }
            @media (max-width: $breakpoint-md-desktop) {
                width: auto; }
            @media (max-width: $breakpoint-mini-desktop) {
                margin: 0 1.5% 1.5% 0;
                width: 20.8%; }
            @media (max-width: $breakpoint-mobile) {
                margin: 0 0 15px 0;
                width: 100%; }
            &:first-child {
                margin-left: 0; }
            &.edit-header-footer {
                width: 166px;
                @media (max-width: $breakpoint-mini-desktop) {
                    width: 18.4%;
                    margin-bottom: 0; }
                @media (max-width: $breakpoint-mobile) {
                    width: 100%; }
                .btn {
                    width: 100%;
                    height: 40px; } }
            label {
                font-size: 14px;
                line-height: 1.4;
                font-weight: 700;
                color: $dark-grey-color; }
            .edit-site-btn {
                background-color: $white-color;
                border-radius: 15px;
                padding: 7px 35px 7px 12px;
                display: inline-block;
                width: 100%;
                background-image: url('/images/chevron-down.png');
                background-repeat: no-repeat;
                background-position: right 12px center;
                font-size: 16px;
                line-height: 1.5;
                color: $dark-grey-color;
                height: auto;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                &:hover,
                &:active,
                &:focus {
                    box-shadow: 0 0 0 3px $primary-color; }
                @media (max-width: $breakpoint-mac-desktop) {
                    background-position: right 5px center;
                    padding: 7px 28px 7px 12px;
                    font-size: 14px;
                    height: 39px; }
                img {
                    padding: 0 10px 0 0; }
                &.no-arrow {
                    background-image: none;
                    padding-right: 12px;
                    text-align: center; }
                .checkbox-colors {
                    margin: 0;
                    padding: 0 10px 0 0;
                    line-height: 0;
                    span {
                        width: 24px;
                        height: 24px;
                        vertical-align: middle; } }
                &.logo-preview-btn {
                    height: 40px;
                    img {
                        width: auto;
                        max-height: 100%;
                        padding: 0; } } } }
        .logo {
            @media (max-width: $breakpoint-mini-desktop) {
                margin: 0 1.5% 1.5% 0;
                width: 14.5%; }
            @media (max-width: $breakpoint-mobile) {
                margin: 0 0 15px 0;
                width: 100%; } }
        .favicon {
            @media (max-width: $breakpoint-mini-desktop) {
                margin: 0 1.5% 1.5% 0;
                width: 9.5%; }
            @media (max-width: $breakpoint-mobile) {
                margin: 0 0 15px 0;
                width: 100%; } }
        .menu-links {
            @media (max-width: $breakpoint-mini-desktop) {
                margin: 0 1.5% 1.5% 0;
                width: 7.2%; }
            @media (max-width: $breakpoint-mobile) {
                margin: 0 0 15px 0;
                width: 100%; } }
        .update-site {
            .btn {
                font-size: 14px;
                padding: 13px 30px 13px 30px;
                @media (max-width: $breakpoint-mac-desktop) {
                    padding: 13px 20px 13px 20px; } }
            @media (max-width: $breakpoint-mini-desktop) {
                margin: 0 0 1.5% 0;
                width: 18.8%;
                //line-height: 6
                text-align: right; }
            @media (max-width: $breakpoint-mobile) {
                width: 100%; } } }
    &.travel-edit-site-panel {
        .edit-site-btns {
            justify-content: flex-start;
            .edit-header-footer {
                @media (max-width: $breakpoint-mobile) {
                    margin: 0 0 15px; } } } } }

.color-palette-popup {
    .color-palatte {
        .color-palatte-selector {
            .styled-radio-btn {
                margin-left: 15px;
                @media (max-width: $breakpoint-ipad) {
                    // margin-left: 0
 }                    // width: 100%
                @media (max-width: $breakpoint-ipad) {
                    clear: none;
                    margin-left: 5px;
                    margin-right: 5px;
                    width: auto; }
                &:nth-child(2n+1) {
                    margin-left: 15px;
                    clear: none;
                    @media (max-width: $breakpoint-ipad) {
 }                        //margin-left: 0
                    @media (max-width: $breakpoint-ipad) {
                        clear: none;
                        margin-left: 5px;
                        margin-right: 5px; } }
                &:nth-child(3n+1) {
                    margin-left: 0;
                    clear: left;
                    @media (max-width: $breakpoint-ipad) {
                        clear: none;
                        margin-left: 5px;
                        margin-right: 5px; } } } } } }
.dashboard-edit-site-wrap {
    .dashboard-header {
        &.site-filter-header {
            align-items: center;
            padding: 17px 0; } } }
.pages-listing {
    width: 100%;
    display: inline-block;
    ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            vertical-align: top;
            width: 32.2%;
            margin-left: 1.5%;
            margin-bottom: 1.5%;
            @media (max-width: $breakpoint-ipad) {
                width: 49%; }
            @media (max-width: $breakpoint-mobile) {
                width: 100%;
                margin-bottom: 30px; }
            &:nth-child(3n+1) {
                margin-left: 0;
                @media (max-width: $breakpoint-ipad) {
                    margin-left: 1.5%; }
                @media (max-width: $breakpoint-mobile) {
                    margin-left: 0; } }
            &:nth-child(2n+1) {
                @media (max-width: $breakpoint-ipad) {
                    margin-left: 0; } }
            &.add-new-page {
                //border: 2px solid $primary-color
                background-color: $white-color;
                box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.18);
                position: relative;
                border-radius: 24px;
                min-height: 318px;
                //height: 305px
                @media (max-width: $breakpoint-mobile) {
                    min-height: 295px; }
                .alert {
                    z-index: 5;
                    top: 15px;
                    left: 4px;
                    right: 4px;
                    width: calc(100% - 8px);
                    display: inline-block;
                    a {
                        text-decoration: underline;
                        color: #721c24; } }
                .btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 900;
                    width: 166px;
                    padding: 11px 25px;
                    z-index: 2; }
                p {
                    color: $light-blue-color;
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: 0.5px;
                    text-align: center;
                    position: absolute;
                    bottom: 29px;
                    margin: 0;
                    left: 32px;
                    right: 32px;
                    z-index: 2; }
                .animation-gif {
                    z-index: 1;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    max-height: 90%; } }
            &.active {
                .page-detail {
                    background-color: $white-color;
                    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.18);
                    .page-layout {
                        background-color: $light-bg-color; } } }
            .page-detail {
                width: 100%;
                display: inline-block;
                border: 1px solid $optional-light-grey-color;
                border-radius: 24px;
                padding: 21px 17px;
                .page-name {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    h6 {
                        color: $dark-grey-color;
                        font-size: 16px;
                        line-height: 1.4;
                        font-weight: bold;
                        letter-spacing: 0.5px;
                        margin: 0;
                        .spinner-border {
                            border: .25em solid $primary-color;
                            border-right-color: transparent;
                            margin-left: 5px; }
                        a {
                            display: block;
                            color: $primary-color; } }
                    .custom-control.custom-switch {
                        padding-left: 0;
                        padding-right: 2.25rem;
                        .custom-control-label {
                            font-size: 14px;
                            line-height: 1.4;
                            color: $body-color;
                            &::before {
                                left: auto;
                                right: -2.25rem;
                                top: 2px;
                                border-color: $primary-color;
                                background-color: $primary-color; }
                            &::after {
                                left: auto;
                                right: calc(-2.25rem + 13px);
                                top: 3px; } }
                        .custom-control-input {
                            left: auto;
                            right: 0; } } }
                .page-layout {
                    width: 100%;
                    display: inline-block;
                    position: relative;
                    background-color: $white-color;
                    border-radius: 8px;
                    margin: 8px 0 16px;
                    text-align: center;
                    padding: 12px;
                    svg {
                        max-width: 100%; }
                    a {
                        position: absolute;
                        top: 45%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 900;
                        color: $primary-color;
                        text-decoration: underline; } }
                .page-action {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .btn {
                        font-size: 14px;
                        line-height: 1.4;
                        color: $body-color;
                        border: none;
                        padding: 0;
                        border-radius: 5px;
                        margin-right: 10px;
                        font-weight: normal;
                        &:hover,
                        &:active,
                        &:focus {
                            color: $primary-color;
                            svg {
                                path {
                                    stroke: $primary-color; } } }
                        &.delete-icon {
                            &:hover,
                            &:active,
                            &:focus {
                                color: $red-color;
                                svg {
                                    path {
                                        stroke: $red-color; } } } }
                        svg {
                            margin-right: 8px; }
                        &:last-child {
                            margin-right: 0; } } }
                .page-edit {
                    a {
                        color: $primary-color;
                        font-size: 16px;
                        font-weight: 900; } } } } } }
.trash-listing {
    width: 100%;
    display: inline-block;
    margin: 17px 0;
    border-top: 1px solid $optional-light-grey-color; }

.niche-edit-modal {
    .input-radio-label {
        @media (max-width: $breakpoint-mobile) {
          padding: 10px 10px 10px 30px;
          background-position: 8px center;
          font-size: 14px; } } }
.menu-detail {
    visibility: visible !important;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 8px;
    width: 75%;
    width: calc(100% - 50px);
    padding: 9px 10px 9px 17px;
    cursor: pointer;
    .menu-detail-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .menu-action {
            font-size: 16px;
            line-height: 1;
            text-decoration: underline;
            color: $primary-color;
            font-weight: 900;
            text-transform: capitalize;
            @media (max-width: $breakpoint-mobile) {
                font-size: 14px; } }
        .menu-name {
            svg {
                display: inline-block;
                vertical-align: middle; }
            span {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                line-height: 1.5;
                font-weight: bold;
                color: $dark-grey-color;
                margin:  0 0 0 8px; }
            .menu-drag {
                width: 11px;
                height: 23px;
                position: relative;
                margin: 0 12px 0 0 !important;
                svg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    &:last-child {
                        bottom: 0;
                        top: auto; }
                    &:first-child {
                        transform: rotate(180deg); } } } } } }
.edit-menu-listing {
    @media (max-width: $breakpoint-mobile) {
        padding: 0; }
    .input-select-dropdown {
        box-shadow: 4px 1px 1px 1px #ccc;
        border: 1px solid #ccc;
        max-height: 120px !important;
        overflow: auto;
        .input-select-dropdown-option {
            padding: 10px !important;
            &:hover {
                cursor: pointer;
                background: $primary-color;
                color: $white-color; } } }

    .menu-link-url {
        padding-left: 50px !important;
        width: 327px !important;
        border: #fff !important; }
    .menu-link-item {
        visibility: visible !important;
        opacity: 1 !important; }
    width: 100%;
    display: inline-block;
    .menu-listing {
        width: 55%;
        margin-right: 1.5%;
        display: inline-block;
        vertical-align: top;
        @media (max-width: $breakpoint-mobile) {
            width: 100%;
            margin: 0 0 30px; }
        .add-icon-btn {
            color: $primary-color;
            border: 1px solid #506BF0;
            border-radius: 8px;
            @media (max-width: $breakpoint-mobile) {
                width: 25px;
                height: 25px; } }
        ol {
            width: 100%;
            display: inline-block;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                display: flex;
                align-items: center;
                width: 100%;
                margin: 0 0 5.5px;
                .menu-order {
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 8px;
                    //border: 1px solid $optional-light-grey-color
                    font-size: 14px;
                    line-height: 38px;
                    font-weight: bold;
                    color: $body-color;
                    text-align: center;
                    width: 40px;
                    height: 40px;
                    @media (max-width: $breakpoint-mobile) {
                        width: 25px; } }
                .menu-detail {
                    visibility: visible !important;
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 8px;
                    border: 1px solid $optional-light-grey-color;
                    border-radius: 8px;
                    width: 75%;
                    width: calc(100% - 50px);
                    padding: 10px 10px 8px 17px;
                    svg {
                        cursor: row-resize; }
                    @media (max-width: $breakpoint-mobile) {
                        padding: 6px 10px 6px 15px; }
                    .menu-detail-inner {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .menu-action {
                            font-size: 16px;
                            line-height: 1;
                            text-decoration: underline;
                            color: $primary-color;
                            font-weight: 900;
                            @media (max-width: $breakpoint-mobile) {
                                font-size: 14px; } }
                        .menu-name {
                            svg {
                                display: inline-block;
                                vertical-align: middle; }
                            span {
                                display: inline-block;
                                vertical-align: middle;
                                font-size: 14px;
                                line-height: 1.5;
                                font-weight: bold;
                                color: $dark-grey-color;
                                margin:  0 0 0 8px; } } } } }
            .active {
                .menu-order {
 }                    //border: 3px solid $primary-color !important
                .menu-detail {
                    border-color: $primary-color;
                    box-shadow: 0 0 0 2px $primary-color; } } } }

    .add-menu {
        width: 43.5%;
        display: inline-block;
        vertical-align: top;
        min-height: 238.59px;
        @media (max-width: $breakpoint-mobile) {
            width: 100%;
            min-height: 219.2px; }
        .new-tab-control {
            &.tab-disabled {
                opacity: 0; } }
        .form-group {
            margin: 0 0 16px;
            .form-label {
                margin: 0 0 8px; }
            .form-control,
            .ris-name {
                text-transform: capitalize; }
            .ris-input,
            .form-control {
                width: 100% !important;
                border: 1px solid #F7F7F7 !important;
                background-color: #F7F7F7;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: 0.5px;
                border-radius: 24px;
                padding: 12px 16px; }
            .ris-options {
                z-index: 1;
                left: 50% !important;
                .ris-option {
                    font-size: 16px;
                    line-height: 1.5;
                    letter-spacing: 0.5px;
                    padding: 12px 16px;
                    &:hover {
                        color: $white-color;
                        background-color: $primary-color; } } }
            .newtab_input {
                width: 24px;
                height: 24px;
                position: relative;
                display: inline-block;
                vertical-align: middle;
                margin: 0 8px 0 0;
                input {
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    &:checked {
                        & ~ .newtab_custom_input {
                            background-image: url('/images/tab-checkbox-checked.png'); } } }
                .newtab_custom_input {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-image: url('/images/tab-checkbox.png'); } } } } }
.menu-link-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin: -20px 0 30px !important;
    @media (max-width: $breakpoint-mobile) {
        flex-direction: column; }
    .modal-btns {
        width: 166px;
        @media (max-width: $breakpoint-mobile) {
            width: 100%;
            padding: 0 0 50px !important; }
        .btn {
            padding: 12px 15px;
            width: 100%;
            letter-spacing: 0.05em; } } }
.recent-pages {
    @media (max-width: $breakpoint-mobile) {
        margin: 0 0 20px;
        width: 100%; }
    h4 {
        font-size: 16px;
        line-height: 1.3;
        font-weight: 500;
        color: #000;
        margin: 0 0 7px; }
    ul {
        width: 100%;
        display: inline-block;
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            margin: 0 7px 0 0;
            background-color: #E2E2E2;
            border-radius: 5px;
            font-size: 14px;
            line-height: 1.3;
            font-weight: 500;
            color: #000000;
            padding: 3px 10px;
            text-transform: capitalize;
            cursor: pointer;
            &:hover {
                background-color: $primary-color;
                color: #fff; } } } }
.profile-avtar-info {
    width: 100%;
    display: inline-block;
    margin: 8px 0 29px;
    .profile-avtar {
        width: 107px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        .edit-profile-avtar {
            position: absolute;
            right: -13px;
            bottom: -13px;
            padding: 6px;
            background-color: $white-color;
            border-radius: 8px;
            &:hover {
                background: $primary-color;
                svg {
                    path {
                        stroke: $white-color; } } } }
        .upload-feature-img-wrap {
            .upload-feature-img {
                width: 107px;
                height: 107px;
                font-size: 12px; } } }
    .profile-main-info {
        margin-left: 29px;
        width: 60%;
        width: calc(100% -  302px);
        display: inline-block;
        vertical-align: middle;
        text-transform: capitalize;
        @media (max-width: $breakpoint-mobile) {
            width: 100%;
            margin: 20px 0 0; }
        h6 {
            font-size: 16px;
            line-height: 1.3;
            color: $dark-grey-color;
            letter-spacing: -0.016em;
            font-weight: normal;
            font-family: 'Inter', sans-serif; }
        h5 {
            font-size: 24px;
            line-height: 1;
            font-weight: normal;
            color: $dark-grey-color;
            letter-spacing: 0.18px; }
        a {
            color: $primary-color !important;
            font-size: 16px;
            line-height: 1;
            font-weight: 900;
            text-decoration: underline !important; }
        .btn.btn-primary {
            color: $primary-color !important;
            font-size: 16px;
            line-height: 1;
            font-weight: 900;
            text-decoration: underline !important;
            background: transparent;
            border: none;
            padding: 0;
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus,
            &:hover,
            &:active,
            &:focus {
                color: $primary-color !important;
                text-decoration: underline !important;
                background: transparent;
                border: none;
                color: $dark-grey-color !important; } } }
    .btn.btn-secondary {
        background-color: transparent;
        padding: 11px 25px;
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus,
        &:hover,
        &:active,
        &:focus {
            background-color: transparent; } }
    .profile-delete-account {
        width: 166px;
        display: none;
        vertical-align: middle;
        @media (max-width: $breakpoint-mobile) {
            width: 100%;
            margin: 20px 0 0; } }
    .profile-save-btn {
        display: none; } }
.profile-edit-info {
    .profile-save-btn {
        display: inline-block; }
    .profile-delete-account {
        display: inline-block; }
    .edit-info-btn {
        display: none; } }
.profile-personal-info {
    width: 100%;
    display: inline-block;
    .displayInline {
        display: inline-block;
        vertical-align: bottom !important;
        text-align: right; }
    .form-group {
        margin-left: 2%;
        width: 32%;
        vertical-align: top;
        @media (max-width: $breakpoint-ipad) {
            margin-left: 0;
            width: 100%; }
        &:nth-child(3n+1) {
            margin-left: 0;
            clear: left; } } }
.profile-subscription {
    width: 100%;
    display: inline-block;
    margin: 48px 0 20px;
    &.row {
        display: flex !important; }
    .profile-active-plan {
        .subscribed-plan {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(194.37deg, #A854FC -11.72%, #3A0D68 158.72%);
            border: 3px solid rgba(51, 51, 51, 0.3);
            border-radius: 24px;
            height: 254px;
            text-align: center;
            h6 {
                font-size: 20px;
                line-height: 1.3;
                font-weight: 700;
                color: $white-color;
                margin: 0; }
            h4 {
                font-size: 34px;
                line-height: 1.3;
                font-weight: normal;
                color: $white-color;
                margin: 8px 0 28px;
                sub {
                    font-size: 59%; } } } } }
.profile-left {
    display: inline-block;
    width: 32%;
    vertical-align: top;
    @media (max-width: $breakpoint-ipad) {
        width: 100%;
        margin-bottom: 30px; } }
.profile-right {
    display: inline-block;
    width: 66%;
    margin-left: 2%;
    vertical-align: top;
    @media (max-width: $breakpoint-ipad) {
        margin: 0 0 30px;
        width: 100%; } }
.profile-subhead {
    font-size:  16px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: $dark-grey-color;
    margin: 0 0 8px; }
.blogs-table {
    &.dashboard-table {
        background-color: $white-color;
        border-radius: 24px;
        padding: 24px;
        margin: 0 0 30px;
        table {
            width: 100%;
            min-width: 1105px;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.5px;
            color: $dark-grey-color;
            @media (max-width: $breakpoint-small-desktop) {
                min-width: 980px; } } } }
.profile-add-payment {
    .expiration-date {
        width: 48%;
        margin-right: 4%;
        display: inline-block;
        vertical-align: top;
        @media (max-width: $breakpoint-mobile) {
            width: 100%;
            margin-right: 0; } }
    .security-number {
        width: 48%;
        display: inline-block;
        vertical-align: top;
        @media (max-width: $breakpoint-mobile) {
            width: 100%; } } }
.card-actions {
    text-align: left;
    margin: 24px 0; }
.profile-card-listing {
    min-height: auto;
    max-height: none;
    overflow: visible;
    ul {
        max-height: 164px !important;
        overflow: auto;
        padding-right: 5px !important;
        li {
            margin: 0 0 15px !important;
            label {
                margin-bottom: 0; } } }
    .paymetMethod {
        margin-bottom: 18px;
        font-size: 16px;
        line-height: 1.4;
        font-weight: 500;
        letter-spacing: 0; }
    @media (max-width: $breakpoint-mobile) {
        margin-bottom: 0; }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        max-height: 200px;
        li {
            width: 100%;
            display: inline-block;
            margin: 0 0 24px;
            border: 1px solid #E2E2E2;
            border-radius: 8px;
            padding: 8px 16px;
            position: relative;
            &:last-child {
                margin-bottom: 0; }
            .card-radio {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                padding: 0;
                input {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 2;
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    cursor: pointer;
                    opacity: 0; } }
            label {
                width: 100%;
                display: flex;
                align-items: center;
                @media (max-width: $breakpoint-mobile) {
 }                    //flex-wrap: wrap
                & > div {
                    display: inline-block;
                    margin-right: 48px;
                    @media (max-width: $breakpoint-mobile) {
                        margin-right: 25px; }
                    &:last-child {
                        margin-right: 0; } }
                .card-radio-btn {
                    width: 16px;
                    height: 16px;
                    background-image: url('/images/radio.png');
                    background-repeat: no-repeat;
                    background-position: center; } }
            &.active {
                border: 2px solid #3032C8;
                background: linear-gradient(0deg, rgba(48, 50, 200, 0.1), rgba(48, 50, 200, 0.1)), #FFFFFF;
                label {
                    .card-radio-btn {
                        background-image: url('/images/radio-checked.png'); } } } } } }
.dashboard-table {
    &.billing-detail-table {
        table {
            min-width: 600px; } } }
.logo-upload-modal {
    .modal-dialog {
        .modal-content {
            .logo-preview-modal {
                .logo-preview {
                    text-align: center;
                    width: 375px;
                    max-width: 100%;
                    float: right;
                    @media (max-width: $breakpoint-mobile) {
                        float: none;
                        margin: 20px auto 0; }
                    h4 {
                        font-size: 18px;
                        line-height: 1.3;
                        margin: 0 0 5px;
                        color: $dark-grey-color; }
                    .preview-logo {
                        border: 1px solid rgba(51, 51, 51, 0.3);
                        border-radius: 15px;
                        margin: 0;
                        padding: 15px;
                        flex-direction: column;
                        @media (max-width: $breakpoint-mobile) {
                            margin-top: 10px; }
                        img {
                            width: auto;
                            height: auto;
                            object-fit: cover;
                            border-radius: 15px;
                            margin: 0; } }
                    .clear-logo {
                        margin: 15px 0 0;
                        text-transform: capitalize;
                        color: $red-color;
                        font-weight: 700;
                        width: 100%;
                        display: inline-block; } } } } } }
.dashboard-wrapper {
    .dashboard-data.blog-dashboard {
        .dashboard-body {
            width: 774px;
            max-width: 100%; } } }
.blog-custom-list-table {
    margin: 23px 0;
    background-color: $white-color;
    border-radius: 24px;
    padding: 24px;
    @media (max-width: $breakpoint-mobile) {
        padding: 0;
        background-color: transparent; }
    .blog-custom-list {
        width: 100%;
        display: inline-block;
        .blog-list-header {
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            border-bottom: 1px solid $optional-light-grey-color;
            padding: 13px 0;
            @media (max-width: $breakpoint-mobile) {
                display: none; }
            .blog-list-column {
                display: inline-block;
                vertical-align: middle;
                margin-right: 20px;
                color: $body-color;
                font-size: 14px;
                line-height: 1.4;
                font-weight: normal;
                text-align: center;
                @media (max-width: $breakpoint-mobile) {
                    margin-right: 5px; }
                &.headerSort {
                    cursor: pointer; }
                &.blog-list-live {
                    width: 35px; }
                &.blog-list-date {
                    width: 110px;
                    svg {
                        display: none !important;
                        @media (max-width: $breakpoint-mobile) {
                            display: inline-block !important; } }
                    @media (max-width: $breakpoint-mobile) {
                        width: 115px; }
                    span {
                        display: inline-block;
                        vertical-align: middle; } }
                &.blog-list-views {
                    width: 96px;
                    @media (max-width: $breakpoint-mobile) {
                        width: 85px; }
                    svg {
                        display: none !important;
                        @media (max-width: $breakpoint-mobile) {
                            display: inline-block !important; } } }
                &.blog-list-comments {
                    width: 70px;
                    @media (max-width: $breakpoint-mobile) {
                        width: 65px; } }
                &.blog-list-delete {
                    //width: 42px
                    @media (max-width: $breakpoint-mobile) {
                        width: 100%;
                        display: none; } }
                &.blog-list-title {
                    width: 267px;
                    max-width: calc(100% - 459px);
                    transition: all 500ms;
                    @media (max-width: $breakpoint-mobile) {
                        max-width: calc(100% - 45px); } }
                &.blog-list-domain {
                    width: 400px; }
                &.blog-list-date,
                &.blog-list-views,
                &.blog-list-comments {
                    @media (max-width: $breakpoint-mobile) {
                        margin: 15px 0;
                        display: none; } } }
            &.blog-list-table {
                .toggle-blog-detail {
                    display: none;
                    @media (max-width: $breakpoint-mobile) {
                        display: inline-block; } }
                &.active {
                    position: relative;
                    @media (max-width: $breakpoint-mobile) {
                        padding-bottom: 50px; }
                    .blog-list-date,
                    .blog-list-views,
                    .blog-list-comments,
                    .blog-list-delete {
                        @media (max-width: $breakpoint-mobile) {
                            display: inline-block; } }
                    .toggle-blog-detail {
                        @media (max-width: $breakpoint-mobile) {
                            width: 100%;
                            display: inline-block;
                            text-align: center;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            padding: 10px 0 10px; }
                        svg {
                            transform: rotate(180deg); } } }
                @media (max-width: $breakpoint-mobile) {
                    display: inline-block;
                    width: 100%;
                    background-color: $white-color;
                    border-radius: 24px;
                    padding: 9px;
                    margin: 0 0 14px; }
                .blog-list-column {
                    font-size: 16px;
                    line-height: 1.5;
                    color: $dark-grey-color;
                    span {
                        display: inline-block;
                        vertical-align: middle;
                        margin-left: 3px; }
                    svg {
                        display: inline-block;
                        vertical-align: middle; }
                    &.blog-list-title {
                        text-align: left;
                        padding-top: 17px;
                        padding-bottom: 17px;
                        @media (max-width: $breakpoint-mobile) {
                            padding-top: 0;
                            padding-bottom: 0; } }
                    .table-post-title {
                        font-weight: bold;
                        letter-spacing: 0.5px;
                        a {
                            font-size: 12px;
                            line-height: 1.4;
                            color: $body-color;
                            text-decoration: underline;
                            margin: 0 0 0 5px;
                            &:hover {
                                color: $primary-color;
                                font-weight: bold; }
                            @media (max-width: $breakpoint-mobile) {
                                color: $primary-color; } } } } }
            .sort-column {
                display: inline-block;
                vertical-align: middle; }
            .headerSort {
                .sort-icon {
                    display: inline-block;
                    vertical-align: middle;
                    width: 11px;
                    line-height: 0;
                    margin: 0 0 0 10px;
                    .active {
                        svg {
                            path {
                                fill: #506bf0; } } }
                    svg {
                        display: inline-block !important; }
                    .sort-up {
                        svg {
                            transform: rotate(180deg); } } }
                &.headerSortDown {
                    .sort-icon {
                        .sort-up {
                            display: none; } } }
                &.headerSortUp {
                    .sort-icon {
                        .sort-down {
                            display: none; } } } } }
        .table-action-btns {
            background-color: #F2F2F2;
            border: 1px solid #9E9E9E;
            border-radius: 10px;
            color: $black-color;
            font-size: 14px;
            line-height: 1.4;
            font-family: 'Inter', sans-serif;
            padding: 3px 6px;
            display: inline-block;
            text-align: center;
            margin-right: 6px;
            &.dropdown-toggle {
                &::after {
                    vertical-align: middle; } }
            &.active {
                &:hover {
                    svg {
                        path {
                            stroke: $white-color;
                            fill: none; } } } }
            &:hover {
                background: $light-blue-color;
                border: 1px solid $light-blue-color;
                color: $white-color;
                svg {
                    path {
                        fill: $white-color; } } }
            @media (max-width: $breakpoint-mobile) {
                margin-right: 13px; }
            &:last-child {
                margin-right: 0; }
            &:hover,
            &:focus,
            &:active {
                text-decoration: none; }
            span {
                margin-left: 3px;
                display: inline-block;
                vertical-align: middle; }
            svg {
                display: inline-block;
                vertical-align: middle; }
            &.table-action-btns-delete {
                box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.6);
                &:hover {
                    svg {
                        path {
                            stroke: $white-color;
                            fill: none; } } }
                @media (max-width: $breakpoint-mobile) {
                    margin-left: 13px;
                    box-shadow: none; } } } } }

.blog-list-actions {
    .hover-actions {
        position: absolute;
        bottom: 13px;
        left: 50px;
        opacity: 0;
        visibility: hidden;
        transition: all 500ms;
        @media (max-width: $breakpoint-mobile) {
            position: relative;
            bottom: auto;
            left: auto;
            display: inline-block;
            opacity: 1;
            visibility: visible; } } }
.blog-list-table {
    @media (max-width: $breakpoint-mobile) {
        text-align: center; }
    &.blog-list-publish.blog-list-header {
        cursor: pointer;
        &:hover {
            .blog-list-column.blog-list-title {
                padding-bottom: 34px;
                padding-top: 0;
                @media (max-width: $breakpoint-mobile) {
                    padding-bottom: 0; } }
            .blog-list-actions {
                .hover-actions {
                    //transform: translateX(0)
                    opacity: 1;
                    visibility: visible; } } } } }
.no-post {
    text-align: center;
    justify-content: center;
    font-weight: bold; }
.editor-content {
    font-family: 'Inter', sans-serif !important;
    span {
        font-family: 'Inter', sans-serif !important; }
    p,
    ul,
    ol,
    li {
        font-size: 16px;
        line-height: 1.3;
        letter-spacing: 0.18px;
        color: $black-color;
        @media (max-width: $breakpoint-mobile) {
            font-size: 14px; } }
    p,
    ul,
    ol, {
        margin: 0 0 33px; }
    ul {
        list-style: disc; }
    ul,
    ol {
        list-style-position: inside; }
    li {
        margin: 0 0 10px; }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 20px;
        font-weight: 600;
        color: $black-color; }
    h1 {
        font-size: 45px;
        @media (max-width: $breakpoint-mobile) {
            font-size: 35px; } }
    h2 {
        font-size: 40px;
        @media (max-width: $breakpoint-mobile) {
            font-size: 30px; } }
    h3 {
        font-size: 30px;
        @media (max-width: $breakpoint-mobile) {
            font-size: 28px; } }
    h4 {
        font-size: 28px;
        @media (max-width: $breakpoint-mobile) {
            font-size: 22px; } }
    h5 {
        font-size: 25px;
        @media (max-width: $breakpoint-mobile) {
            font-size: 18px; } }
    h6 {
        font-size: 20px;
        @media (max-width: $breakpoint-mobile) {
            font-size: 14px; } }
    a {
        &:hover {
            text-decoration: none; } }
    blockquote {
        margin: 1em;
        color: $black-color;
        font-size: 22px; }
    img {
        margin: 0 auto 45px;
        display: inline-block; } }
.header-profile-img {
    background-color: $optional-light-grey-color;
    position: absolute;
    left: 0;
    right: 0;
    padding: 0 0 0 16px;
    //bottom: 100%
    bottom: 0;
    margin: 0 !important;
    .notification-menu {
        width: 60px !important;
        display: inline-block !important;
        vertical-align: top !important;
        text-align: center;
        padding: 17px 0 !important;
        // @media (max-width: $breakpoint-ipad)
        //     padding: 8px 0 !important
        .notification-link {
            width: auto;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            svg {
                rect {
                    fill: #F7F7F7 !important; }
                path {
                    fill: $dark-grey-color !important; } } }
        .notification-bubble {
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background-color: $red-color;
            color: $white-color;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            text-align: center;
            display: inline-block;
            position: absolute;
            bottom: -6px;
            right: -7px;
            // @media (max-width: $breakpoint-ipad)
 } }            //     right: 4px
    .dropdown {
        width: 100%;
        //width: calc(100% - 60px)
        display: inline-block;
        vertical-align: top;
        //border-right: 1px solid $black-color
        padding: 0 23px 0 0;
        position: unset;
        .dropdown-menu {
            min-width: 265px;
            left: 0 !important;
            border-radius: 0;
            bottom: -1px !important;
            padding: 15px !important;
            border: none;
            background-color: $white-color;
            z-index: 10;
            //position: relative !important
            transform: none !important;
            left: 0 !important;
            right: 0 !important;
            top: auto !important;
            bottom: 100% !important;
            padding: 10px 0 !important;
            // @media (max-width: $breakpoint-ipad)
            //     top: 100% !important
            //     bottom: auto !important
            //     padding: 0 24px !important
            .dropdown-item,
            a {
                font-size: 16px;
                line-height: 1.4;
                letter-spacing: 0.5px;
                color: $dark-grey-color;
                padding: 10px 20px !important;
                margin: 0;
                &:hover,
                &:active,
                &:focus {
                    color: $white-color;
                    //background-color: $optional-light-grey-color
                    background-color: $black-color;
                    border-radius: 0; }
                &:last-child {
                    margin-bottom: 0; } } }
        .dropdown-toggle {
            display: flex;
            //justify-content: space-between
            align-items: center;
            width: 100%;
            &.btn.btn-primary {
                padding: 13px 0;
                background-color: transparent;
                border: none;
                max-width: 240px;
                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled):active:focus,
                &:hover,
                &:active,
                &:focus {
                    outline: none;
                    box-shadow: none;
                    background-color: transparent;
                    border: none; }
                svg {
                    margin-left: auto; } }
            &::after {
                display: none; }
            .nav-profile-pic {
                width: 35px;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                img {
                    width: 35px;
                    height: 35px;
                    border-radius: 100%; } } }
        .dropdown-label {
            font-size: 16px;
            font-weight: bold;
            color: $black-color;
            margin: 0 9px;
            display: inline-block;
            vertical-align: middle; }
        &.show {
            .dropdown-toggle {
                svg {
                    transform: rotate(180deg); } } } } }
.blog-mobile-action {
    width: 100%;
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    @media (max-width: $breakpoint-mobile) {
        display: inline-block; }
    ul {
        margin: 0 -10px;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #9E9E9E;
        padding: 6px 27px;
        background-color: $white-color; } }
.emoji-mart {
    position: absolute;
    top: 100%;
    left: -50%;
    background-color: $white-color;
    z-index: 2;
    @media (max-width: $breakpoint-mobile) {
        max-width: 300px;
        width: 300px;
        left: -90%; }
    @media (max-width: 413px) {
        left: -140%; } }
button.emoji-mart-search-icon {
    top: 4px; }
.emoji-mart-skin-swatches {
    padding: 3px 5px 5px;
    height: auto; }
.custom-rte-toolbar .toolbar-wrapper span.emoji-mart-skin-swatch {
    width: 16px;
    height: 16px;
    min-width: unset;
    line-height: 0;
    padding: 0;
    margin: 0 1px;
    &.selected {
        &::after {
            background-color: $white-color;
            opacity: 1;
            width: 6px;
            height: 6px; } }
    .emoji-mart-skin {
        width: 16px;
        height: 16px;
        min-width: unset;
        max-width: unset;
        line-height: 0;
        display: inline-block; } }
.emoji-mart-category-label {
    font-size: 12px;
    color: $dark-grey-color;
    font-weight: 700;
    span {
        font-size: 12px;
        color: $dark-grey-color;
        font-weight: 700; } }
.emoji-mart-preview {
    height: auto;
    width: 100%;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    .emoji-mart-preview-data {
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        transform: none;
        text-align: center;
        .emoji-mart-preview-name,
        .emoji-mart-preview-shortnames,
        .emoji-mart-preview-emoticons {
            font-size: 12px;
            color: $dark-grey-color;
            font-weight: 700;
            span {
                font-size: 12px;
                color: $dark-grey-color;
                font-weight: 700; } } }
    .emoji-mart-preview-skins {
        left: auto;
        right: 15px;
        top: auto;
        bottom: auto;
        transform: none;
        width: 120px; } }
.emoji-mart-preview-emoji {
    width: 50px;
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    transform: none;
    margin-right: 15px; }
.emoji-mart-category .emoji-mart-emoji:hover:before {
    top: 4px;
    left: 2px; }
.video-mart {
    padding: 5px;
    input {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: $dark-grey-color;
        font-weight: normal;
        border: 1px solid $dark-grey-color;
        background-color: $white-color;
        border-radius: 0;
        padding: 5px 10px;
        width: 60%;
        width: calc(100% - 70px);
        height: 33px;
        &:focus {
            border: 1px solid $dark-grey-color; } }
    span {
        display: inline-block;
        vertical-align: middle;
        background-color: $primary-color;
        color: $white-color;
        border-radius: 0;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        padding: 2px 5px 1px;
        text-transform: capitalize;
        width: 70px;
        height: 33px;
        margin-left: -2px; } }
.bloggerProfile {
    .bloggerImgSection {
        display: flex;
        margin: 0;
        flex-direction: row;
        align-items: center;
        .removeBtn {
            background: transparent !important;
            border: 0 !important;
            color: #3032C8 !important;
            font-size: 12px !important;
            font-weight: 600 !important;
            text-decoration: underline !important;
            padding: 0 !important;
            display: inline-block;
            vertical-align: baseline;
            margin-right: 10px; }
        a {
            text-decoration: underline;
            font-weight: bold;
            font-size: 12px;
            color: $primary-color; }
        .author-img {
            display: flex !important;
            align-items: flex-end;
            .edit-profile-avtar {
                margin-left: -20px;
                background: #fff;
                padding: 5px;
                height: 30px;
                width: 30px;
                border-radius: 8px;
                display: flex;
                svg {
                    width: 20px;
                    height: 20px; }
                &:hover {
                    background: $primary-color;
                    svg {
                        path {
                            stroke: $white-color; } } } } }
        .author-name {
            display: flex !important;
            flex-direction: row;
            align-items: center;
            max-width: 60%;
            input {
                border: 1px solid;
                padding: 5px;
                border-radius: 5px; }
            a {
                margin-left: 5px; } } }
    .about {
        h6 {
            color: $black-color;
            font-weight: bold; }
        .removeBtn {
            background: transparent !important;
            border: 0 !important;
            color: #3032C8 !important;
            font-size: 12px !important;
            font-weight: 600 !important;
            text-decoration: underline !important;
            padding: 0 !important;
            display: inline-block;
            vertical-align: baseline;
            margin-right: 10px; }
        textarea {
            border: 1px solid;
            resize: none; }
        p {
            color: $black-color;
            font-weight: 500; }
        a {
            text-decoration: underline;
            font-weight: bold;
            font-size: 12px;
            color: $primary-color; } }
    .connect-social {
        h6 {
            color: $black-color;
            font-weight: bold;
            margin: 0 0 10px; } } }


.page-content {
    input {
        width: 22% !important;
        height: 13px !important;
        min-height: 34px !important;
        font-size: 21px !important;
        //line-height: 1.1 !important
        padding: 5px !important; } }

.page-creation-content {
    margin: 0 !important; }
//
.createNewPage {
    &.createNewPage2 {
        .blog-creation-author-box {
            width: 342px !important;
            margin-left: 25px !important;
            @media (max-width: $breakpoint-ipad-pro) {
                width: 100% !important;
                margin-left: 0 !important; } } }
    .blog-creation-head-left {
        width: calc(100% - 368px) !important;
        @media (max-width: $breakpoint-mobile) {
            width: 100% !important; }
        .blog-btns {
            .btn-outline-primary {
                border: 2px solid $primary-color;
                color: $primary-color;
                &:hover,
                &:focus {
                    background: $primary-color;
                    color: $white-color; }
                @media (max-width: $breakpoint-ipad) {
                    padding: .375rem 0.75rem; } }
            .btn-primary {
                @media (max-width: $breakpoint-ipad) {
                    padding: .375rem 0.75rem; } } } }
    .blog-creation-author-box {
        width: 100% !important;
        margin: 0 !important;
        text-align: center;
        @media (max-width: $breakpoint-mobile) {
            width: 100% !important; }
        .upload-feature-img-wrap {
            width: 650px;
            max-width: 100%;
            //height: 300px
            @media (max-width: $breakpoint-mobile) {
                width: 100%;
 }                //height: auto
            .upload-feature-img {
                width: 100%;
                height: 300px;
                margin: 0;
                @media (max-width: $breakpoint-mobile) {
                    height: auto; } } } }
    .upload-feature-img {
        display: flex;
        flex-direction: column;
        color: $black-color;
        width: 100%; }
    .blog-creation {
        .blog-title-group {
            .form-control {
                background: $white-color;
                border: 1px solid rgba(51, 51, 51, 0.3) !important;
                border-radius: 5px !important;
                font-size: 34px; } }
        .upload-feature-img {
            margin-bottom: 26px;
            background: rgba(196, 196, 196, 0.5);
            padding: 0 5px;
            font-size: 13px;
            justify-content: space-evenly;
            cursor: pointer;
            margin-top: -15px;
            min-height: 200px;
            .addImgList {
                flex-direction: row;
                justify-content: space-evenly;
                width: 100%;
                .list-group-item {
                    padding: 0;
                    width: 50%;
                    max-width: 50px;
                    height: 50px;
                    border: 0;
                    background: transparent;
                    img {
                        width: 100%;
                        object-fit: contain; } } } }
        .hashTag {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            .blog-btns {
                width: auto !important;
                display: flex;
                justify-content: flex-end;
                @media (max-width: $breakpoint-mobile) {
                    width: 100% !important;
                    justify-content: center; }
                .btn-outline-primary {
                    border: 2px solid $primary-color;
                    color: $primary-color;
                    &:hover,
                    &:focus {
                        background: $primary-color;
                        color: $white-color; } } }
            .blogCreateSection {
                display: flex;
                align-items: flex-start;
                flex-direction: row;
                @media (max-width: $breakpoint-mobile) {
                    flex-wrap: wrap;
                    justify-content: center; }
                .hashTagGroup {
                    span {
                        display: flex;
                        height: 28px; } }
                .inputHeight {
                    label {
                        color: $black-color;
                        font-weight: 500; }
                    textarea {
                        height: 74px !important;
                        min-height: 74px !important;
                        border-radius: 5px;
                        border: 1px solid rgba(51, 51, 51, 0.3);
                        padding: 5px;
                        resize: none; } }
                .form-group {
                    max-width: 200px;
                    width: 100%;
                    margin: 0;
                    .form-control {
                        height: 29px !important;
                        font-size: 14px !important;
                        min-height: 29px;
                        border-radius: 5px;
                        border: 1px solid rgba(51, 51, 51, 0.3);
                        padding: 5px; }
                    span {
                        font-size: 12px; } }
                .hashtaglist {
                    small {
                        background: $optional-light-grey-color;
                        border-radius: 5px;
                        padding: 5px;
                        font-weight: 500;
                        font-size: 14px; } } } } }
    .blogCreateSection {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        @media (max-width: $breakpoint-mobile) {
            flex-wrap: wrap;
            justify-content: center; }
        @media (max-width: $breakpoint-ipad) {
            flex-direction: row;
            justify-content: space-between; }
        a {
            color: $primary-color;
            text-decoration: underline;
            font-weight: 800;
            text-transform: uppercase;
            font-size: 12px;
            margin-left: 5px;
            @media (max-width: $breakpoint-mobile) {
                display: inline-block;
                vertical-align: middle; } }
        .form-group {
            max-width: 200px;
            width: 100%;
            margin: 0 10px;
            @media (max-width: $breakpoint-mobile) {
                margin: 0 5px 0 0; }
            .form-control {
                height: 29px;
                font-size: 18px !important;
                min-height: 29px;
                padding-left: 5px;
                width: 100% !important; } }
        p {
            color: $black-color; }
        .font-bold {
            font-weight: 800; }
        .blog-slug {
            color: $black-color;
            width: 100%;
            display: flex;
            align-items: flex-start;
            @media (max-width: $breakpoint-mobile) {
                flex-direction: column; }
            &.edited {
                .blog-slug-link {
                    max-width: calc(100% - 270px);
                    @media (max-width: $breakpoint-mobile) {
                        max-width: 100%;
                        padding: 0 0 10px; } } }

            .blog-slug-link {
                display: inline-block;
                padding-right: 10px;
                word-break: break-all;
                max-width: 100%;
                @media (max-width: $breakpoint-mobile) {
                    max-width: 100%;
                    padding: 0 0 10px; } }
            .slug-form {
                display: inline-block;
                min-width: 260px;
                @media (max-width: $breakpoint-mobile) {
                    width: 100%; }
                .form-group {
                    display: inline-block; }
                a {
                    display: inline-block; } } } } }


.input-select-menu-link {
  textarea {
    width: 350px !important;
    max-width: 100%;
    border: 1px solid #ddd !important;
    border-radius: 24px !important;
    min-height: 42px;
    padding: 10px !important;
    padding-left: 25px !important;
    font-size: 18px !important; }
  .ris-options {
    position: absolute;
    top: 100%;
    left: 53% !important;
    margin: 0;
    width: calc(100% - 1%) !important;
    background: #fff;
    transform: translate(-50%, 0);
    border: 1px solid #eee;
    overflow: auto;
    box-shadow: 0 2px 3px 1px #eee;
    max-height: 245px;
    /* height: 35px; */
    font-size: 18px !important; } }
.dashboard-edit-site-wrap {
    main.dashboard-data.blog-dashboard {
        padding: 0;
        margin: 0;
        border-top: none; } }
//
.billing-address {
    .dashboard-title {
        h2 {
            font-weight: 900;
            font-size: 34px;
            color: $black-color; } }
    .row {
        .profile-subhead {
            .nextBillDate {
                font-size: 14px;
                font-weight: normal;
                color: $light-greybg-color;
                margin-left: 5px;
                b {
                    font-weight: 500; } } }
        .profile-subscription {
            .adjust {
                margin-top: 7px; }
            @media (max-width: $breakpoint-ipad) {
                .profile-cards-sec {
                    .d-flex {
                        .btn {
                           padding: 12px 15px; } } } }
            @media (max-width: $breakpoint-mobile) {
                .profile-cards-sec {
                    .d-flex {
                        flex-direction: column; } } } }
        .profile-billing-info {
            &.profile-right {
                width: 72%;
                @media (max-width: $breakpoint-mac-desktop) {
                    width: 84%; }
                @media (max-width: $breakpoint-ipad) {
                    width: 100%; }
                @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                    padding: 0 15px; }
                .addRefreshIcon {
                    flex-direction: row;
                    justify-content: space-between;
                    svg {
                        width: 30px;
                        height: 30px;
                        cursor: pointer;
                        background: #fff;
                        padding: 5px;
                        border: 2px solid #3132c8;
                        border-radius: 4px; } } } }
        margin: 0;
        .paymetMethod {
            color: $black-color; }
        .subscribed-plan {
            background: $white-color !important;
            border: 1px solid rgba(51, 51, 51, 0.3);
            min-height: 238px;
            position: relative;
            &.annual-state {
                .annual-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 0;
                    width: 106px;
                    height: 27px;
                    background: $blue-color;
                    color: #fff;
                    border-radius: 16px 0px 5px;
                    font-weight: 600;
                    font-size: 18px; } }
            .subscribed-plan-info {
                padding: 30px 15px 0;
                .businessPlan {
                    background: $light-purple-color;
                    border-radius: 10px;
                    color: $white-color !important;
                    line-height: 1.3;
                    width: auto;
                    height: 32px;
                    font-weight: bold !important;
                    font-size: 18px !important;
                    max-width: 120px;
                    width: 100%;
                    margin: 8px auto 15px;
                    .plan_tag {
                        display: none; } }
                &.unsubscribed-state {
                    h6 {
                        margin-bottom: 2.5rem; } }
                h6 {
                    font-weight: 700;
                    font-size: 32px;
                    color: $black-color !important;
                    line-height: 34px;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (max-width: $breakpoint-ipad) {
                        font-size: 26px; }
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 26px; } }
                h4 {
                    color: $light-grey-link;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    margin: 0 0 10px;
                    span {
                        font-weight: normal; } } } }
        .alignBox {
            display: flex;
            flex-direction: column;
            @media (max-width: $breakpoint-mobile) {
                align-items: center; }
            .cancelLink {
                color: $red-color;
                font-size: 13px;
                margin-top: 10px;
                line-height: 1.4;
                letter-spacing: 0.5px;
                &:hover,
                &:focus {
                    text-decoration: none; } } } }
    .profile-cards {
        background-color: #ffffff;
        border-radius: 24px;
        padding: 24px 10px;
        margin: 0 0 30px;
        //overflow: auto
        //height: auto
        border: 1px solid rgba(51, 51, 51, 0.3);
        //min-height: 238px
        //max-height: 254px
        .profile-right {
            width: 98%;
            ul {
                li {
                    padding: 8px 8px !important;
                    &.active {
                        label {
                            & > div {
                                color: $primary-color;
                                font-weight: bold; } } }
                    label {
                        justify-content: space-between;
                        .card-number {
                            max-width: 108px;
                            width: auto;
                            min-width: 80px; }
                        .card-exp-date {
                            max-width: 120px;
                            width: 100%; }
                        & > div {
                            margin-right: 11px !important;
                            @media (max-width: $breakpoint-ipad) {
                                margin-right: 18px !important; }
                            @media (max-width: $breakpoint-mobile) {
                                margin-right: 10px !important; } } } } }
            @media (max-width: $breakpoint-mobile) {
                width: 100%; } }
        .profile-cards-sec {
            height: 100%;
            .d-flex {
                @media (max-width: $breakpoint-mobile) {
                    flex-direction: column;
                    justify-content: center !important;
                    align-items: center !important; } } } }
    .billing-detail-table {
        border: 1px solid rgba(51, 51, 51, 0.3);
        max-height: none !important;
        overflow: auto !important;
        .tableScroll {
            max-height: 270px !important;
            overflow: auto !important; }
        .font-bold {
            font-weight: 700; } } }
.website-plan {
    .price-slider {
        .row {
            & > div {
                margin-bottom: 8px; } } }
    .new_plan_box {
        border: 1px solid rgba(51, 51, 51, 0.3);
        box-shadow: 0 0 0 3px #ffffff;
        border-radius: 16px;
        margin: 5px;
        height: 100%;
        &:hover {
            border-color: #3032C8;
            box-shadow: 0 0 0 3px #3032c8; }
        &.active {
            border-color: $dark-green-color;
            box-shadow: 0 0 0 3px $dark-green-color;
            .annual-box {
                background: $dark-green-color; }
            .plan-action {
                .btn.btn-primary {
                    background-color: $dark-green-color !important;
                    border-color: $dark-green-color;
                    &:disabled {
                        opacity: 1;
                        background-color: $dark-green-color; }
                    &:hover,
                    &:active,
                    &:focus {
                        color: $white-color; } } } }
        .plan-name {
            margin-top: 0 !important; } }
    .managePlanTitle {
        color: $dark-grey-color;
        font-weight: 900;
        font-size: 34px;
        margin-bottom: -45px;
        margin-top: 5px;
        @media (max-width: $breakpoint-mac-desktop) {
            margin-bottom: 0; }
        @media (max-width: $breakpoint-mobile) {
            margin-bottom: 0;
            text-align: center; }
        @media (max-width: $breakpoint-ipad) {
            margin-bottom: 0; } }
    .titleHeading {
        font-weight: 700;
        font-size: 48px;
        line-height: 65px;
        margin-bottom: 20px;
        @media (max-width: $breakpoint-ipad) {
            font-size: 32px;
            line-height: 45px; } }
    .subheading {
        font-weight: 600;
        font-size: 22px;
        line-height: 25px;
        color: $black-color;
        strong {
            color: $blue-color; }
        @media (max-width: $breakpoint-mobile) {
            font-size: 20px; } }
    .select_plan {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 24px;
        p {
            margin: 0;
            font-weight: 800;
            color: $black-color;
            font-size: 20px; }
        .custom-control {
            margin: 0 20px;
            transform: translateX(0.25rem);
            height: 28px;
            .custom-control-input {
                &:focus {
                    &~.custom-control-label {
                        box-shadow: none;
                        &:before {
                            box-shadow: none;
                            border: $light-greybg-color solid 5px; } } }
                &:checked {
                    &~.custom-control-label {
                            &:after {
                                transform: translateX(1.75rem);
                                background: $purple-bg-color; } } } }
            .custom-control-label {
                &:before {
                    border: $light-greybg-color solid 5px;
                    height: 28px;
                    width: 58px;
                    border-radius: 25px;
                    left: -3.25rem;
                    background: transparent;
                    top: 0; }
                &:after {
                    background: $purple-bg-color;
                    height: 26px;
                    width: 26px;
                    border-radius: 100px;
                    top: 1px;
                    left: calc(-3.25rem + 2px); } }
            ~ &.custom-control-label {
                &:after {
                    transform: translateX(1.75rem); } } } }
    .plan-detail {
        padding: 9px 46px 40px;
        @media (max-width: $breakpoint-ipad) {
            padding: 30px 12px 10px !important; }
        // &:hover
        //     box-shadow: 0 0 0 3px $primary-color
        //     border-radius: 16px
        //     border: 1px solid $primary-color
        .plan-detail-inner {
            .plan-name {
                font-weight: bold;
                font-size: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                span.plan_tag {
                    background: $light-purple-color;
                    border-radius: 10px;
                    color: $white-color;
                    font-weight: bold;
                    font-size: 24px;
                    //width: 80px
                    display: flex;
                    height: 38px;
                    align-items: center;
                    justify-content: center;
                    margin-left: 10px;
                    padding: 8px 10px;
                    &.primary-color {
                        background: $primary-color; } } }
            ul {
                li {
                    font-size: 14px; } }
            .plan-action {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                span {
                    font-size: 14px;
                    color: $black-color; }
                button {
                    padding: 12px 45px;
                    width: 100%;
                    @media (max-width: $breakpoint-mobile) {
                        padding: 12px 18px; } } } } }
    .pricePlanText {
        display: none; } }
.modalCancle {
    .modal-dialog {
        max-width: 720px;
        height: 92vh;
        .modal-content {
            background: $light-bg-color;
            padding: 32px 21px 65px;
            .profile-subhead {
                font-size: 24px;
                line-height: 1.4;
                font-weight: 500;
                margin: 0 0 35px; }
            .inline-container {
                display: flex;
                align-items: stretch;
                justify-content: space-evenly;
                @media (max-width: $breakpoint-mobile) {
                    flex-direction: column; }
                .subscribed-plan-info {
                    min-height: 200px;
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    padding: 15px;
                    width: 33%;
                    margin-left: 2.5%;
                    justify-content: center;
                    border-radius: 10px;
                    background: $white-color;
                    &.disabledModalState {
                        opacity: 0.6;
                        pointer-events: none;
                        position: relative;
                        .ribbenText {
                            display: none; } }
                    &:nth-child(3n+1) {
                        margin-left: 0; }
                    @media (max-width: $breakpoint-mobile) {
                        width: 100%;
                        margin-bottom: 30px;
                        margin-left: 0; }
                    h6 {
                        font-weight: 800;
                        font-size: 18px;
                        color: $black-color;
                        text-align: center;
                        min-height: 60px;
                        span {
                            font-weight: 400;
                            display: block;
                            font-size: 16px; } }
                    p {
                        color: $black-color;
                        font-size: 14px;
                        margin-bottom: 30px;
                        line-height: 19.6px; }
                    .btn {
                        margin-top: auto;
                        padding: 12px 8px;
                        &.cancleBtn {
                            background: $red-color;
                            border-color: $red-color;
                            &:hover,
                            &:focus {
                                background: transparent;
                                border-color: $red-color;
                                color: $red-color; } } } } } } } }
.updateBill {
    .formHeight {
        height: auto; }
    input {
        &::-moz-placeholder {
            color: $black-color !important;
            opacity: 0.6 !important;
            font-weight: 500 !important;
            font-size: 14px; }
        &:-moz-placeholder {
            color: $black-color !important;
            opacity: 0.6 !important;
            font-weight: 500 !important;
            font-size: 14px; }
        &::-webkit-input-placeholder {
            color: $black-color !important;
            opacity: 0.6 !important;
            font-weight: 500 !important;
            font-size: 14px; }
        &:-ms-input-placeholder {
            color: $black-color !important;
            opacity: 0.6 !important;
            font-weight: 500 !important;
            font-size: 14px; } }
    .modal-dialog {
        height: 92vh;
        display: flex;
        align-items: center; }
    .modal-content {
        max-width: 400px !important;
        background: $light-bg-color;
        width: 100%;
        .profile-subhead {
            margin-top: 20px; }
        .profile-add-payment {
            text-align: center;
            width: 100% !important;
            @media (max-width: $breakpoint-mobile) {
                margin-bottom: 0 !important; }
            .expiration-date {
                //border-right: 2px solid #E2E2E2
                margin-right: 0;
                width: 52%;
                @media (max-width: $breakpoint-mobile) {
                    width: 100%; }
                .form-group {
                    border-right: 2px solid $optional-light-grey-color;
                    margin-bottom: 0 !important;
                    @media (max-width: $breakpoint-mobile) {
                        border-right: 0; } } }
            form {
                padding: 30px;
                .form-group {
                    border-bottom: 2px solid $optional-light-grey-color;
                    margin-bottom: 0 !important;
                    p {
                        display: none; }
                    input {
                        &.__PrivateStripeElement-input {
                            position: inherit !important;
                            height: 30px !important; } } }
                .form-label {
                    display: none; }
                .form-control {
                    border-radius: 0;
 }                    //border-bottom: 2px solid $optional-light-grey-color
                .StripeElement {
                    background: #fff;
                    padding: 20px; } } }
        .card-actions {
            text-align: center;
            margin-bottom: 0; } } }
.subscribed-plan-info {
    .plan_tag {
        background: $primary-color;
        border-radius: 10px;
        color: $white-color;
        font-weight: bold;
        font-size: 24px;
        line-height: 1.3;
        width: auto;
        height: auto;
        margin-left: 5px;
        padding: 3px 10px; }
    .plan-price {
        font-weight: normal;
        font-size: 34px;
        line-height: 1.3;
        color: $black-color;
        font-weight: 600;
        margin-bottom: 10px;
        sup {
            font-weight: 600;
            font-size: 12px;
            top: -1.25em; }
        sub {
            font-weight: bold;
            font-size: 20px; } }
    .btn {
        font-size: 18px; } }
.profile-edit-info {
    margin: 23px 0; }


.billing-boxes {
    .profile-subhead {
        font-size: 24px;
        line-height: 1.4;
        margin: 0; }
    .profile-subscription {
        margin: 23px 0;
        @media (max-width: $breakpoint-ipad-pro) {
            .profile-active-plan {
                margin: 0 0 30px; }
            .profile-active-plan,
            .profile-cards-sec, {
                max-width: 100%;
                flex: 0 0 100%; } } }
    .profile-subhead-wrap {
        margin: 0 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $breakpoint-mobile) {
            flex-direction: column; }
        .btn {
            padding: 8px 22px;
            @media (max-width: $breakpoint-mobile) {
                margin-top: 10px; } } } }
.profile-billing-history {
    width: 100%;
    max-width: 100%;
    @media (max-width: $breakpoint-ipad-pro) {
        width: 100%; } }
.dashboard-wrapper {
    .dashboard-data {
        &.dashboard-multiple-site {
            .dashboard-body {
                .dashboard-header {
                    .dashboard-actions {
                        text-align: left;
                        .search-form {
                            @media (max-width: $breakpoint-mobile) {
                                max-width: 100%;
                                margin: 0 0 20px; } } }
                    .action-btn {
                        @media (max-width: $breakpoint-mobile) {
                            width: 100%;
                            text-align: right; }
                        .btn {
                            padding: 8px 15px 8px 12px;
                            border: 2px solid $primary-color;
                            background-color: transparent;
                            color: $primary-color;
                            margin-left: 16px;
                            border-radius: 24px;
                            font-weight: normal;
                            &:first-child {
                                margin-left: 0; }
                            &.active {
                                color: $white-color;
                                background-color: $primary-color; }
                            &:focus {
                                outline: none;
                                box-shadow: none; }
                            svg {
                                margin-right: 5px; }
                            &.grid-btn {
                                svg {
                                    path {
                                        fill: $primary-color; } }
                                &.active {
                                    svg {
                                        path {
                                            fill: $white-color; } } } }
                            &.list-btn {
                                svg {
                                    path {
                                        stroke: $primary-color; } }
                                &.active {
                                    svg {
                                        path {
                                            stroke: $white-color; } } } } } } } } } } }
.blog-custom-list-table {
    &.page-custom-list-table {
        .blog-custom-list {
            .blog-list-header {
                .blog-list-column {
                    text-align: left;
                    &.blog-list-actions {
                        .hover-actions {
                            left: 0; } } } } } } }
.btn {
    .spinner-border {
        margin-left: 5px; } }

.blog-list-domain {
    a {
        color: $primary-color; } }

.page-name,
.blog-list-domain {
    .statusView {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        line-height: 18px;
        .success {
            background-color: #5cb85c;
            color: #fff;
            margin-left: 10px;
            padding: 2px 5px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 900; }
        .in-progress {
            background-color: #f0ad4e;
            color: #fff;
            margin-left: 10px;
            padding: 2px 5px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 900; } } }
.colorPrimary {
    color: $primary-color; }
.colorBlack {
    color: $black-color; }
.domain-status-btn {
    width: auto;
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    text-align: center;
    line-height: 1;
    letter-spacing: 0.05em;
    font-weight: 600;
    color: $black-color;
    .domain-status-tag {
        font-size: 14px;
        font-weight: bold;
        color: $white-color;
        border-radius: 5px;
        padding: 8px 15px;
        display: block;
        margin: 6px 0 0;
        background-color: $primary-color;
        svg {
            margin-right: 5px;
            display: inline-block; }
        &.in-progress {
            background-color: $in-progress-color; }
        &.connected {
            background-color: $success-color; } } }
.domain-status {
    text-align: center;
    .domain-name {
        margin: 30px 0;
        h4 {
            color: $black-color;
            font-size: 24px;
            line-height: 1.5;
            letter-spacing: 0.5px;
            font-weight: bold;
            margin: 0 0 5px;
            text-transform: capitalize;
            @media (max-width: $breakpoint-mobile) {
                font-size: 20px; } }
        a {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: 0.5px;
            font-weight: normal;
            color: $primary-color;
            @media (max-width: $breakpoint-mobile) {
                font-size: 14px; } } }
    .btn {
        padding: 10px 35px;
        margin-left: 21px;
        display: inline-block;
        vertical-align: middle;
        @media (max-width: $breakpoint-mobile) {
            padding: 10px 12px;
            margin-left: 7px; }
        &:first-child {
            margin-left: 0; } }
    .domain-btns {
        .domain-status-btn {
            margin-left: 15px;
            @media (max-width: $breakpoint-mobile) {
                margin: 20px 15px 0; } } } }
.domain-connect-steps {
    border-radius: 10px;
    background-color: $white-color;
    padding: 16px 31px 16px 24px;
    @media (max-width: $breakpoint-mini-desktop) {
        padding: 16px 10px; }
    h3 {
        font-size: 24px;
        line-height: 1.3;
        font-weight: bold;
        letter-spacing: 0.18px;
        color: $black-color;
        margin: 0;
        @media (max-width: $breakpoint-mobile) {
            font-size: 20px; } }
    form {
        display: inline-block;
        width: 100%; }
    .domain-steps {
        width: 100%;
        display: inline-block;
        margin: 30px 0 0;
        h6 {
            font-size: 20px;
            line-height: 1.3;
            letter-spacing: 0.18px;
            color: $black-color;
            margin: 0 0 15px;
            @media (max-width: $breakpoint-mobile) {
                font-size: 18px; } }
        p,
        ul li {
            font-size: 16px;
            line-height: 1.3;
            font-weight: normal;
            color: $black-color;
            letter-spacing: 0.18px;
            @media (max-width: $breakpoint-mobile) {
                font-size: 14px; } }
        ul {
            list-style: disc;
            padding-left: 20px;
            margin: 0 0 30px;
            li {
                margin: 0 0 15px; } }
        .domain-step-wrap {
            display: inline-block;
            width: 100%;
            .domain-step-status {
                width: 208px;
                text-align: center;
                display: inline-block;
                vertical-align: top;
                @media (max-width: $breakpoint-mobile) {
                    width: 100%; }
                .btn {
                    width: 100%; }
                .domain-status-btn {
                    margin: 15px 0 0;
                    .domain-status-tag {
                        padding: 8px 15px 6px; } } }
            .domain-step-content {
                display: inline-block;
                vertical-align: top;
                width: 70%;
                width: calc(100% - 208px);
                padding: 0 30px 0 0;
                @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                    margin-bottom: 20px;
                    padding: 0; } }
            .form-group {
                width: 437px;
                max-width: 100%;
                .form-control {
                    border: 1px solid rgba(51, 51, 51, 0.3);
                    border-radius: 0;
                    font-size: 14px;
                    line-height: 1.3;
                    padding: 0 5px;
                    min-height: 0; }
                .form-text {
                    font-size: 12px;
                    line-height: 1.3;
                    letter-spacing: 0.18px;
                    color: $black-color;
                    display: inline-block;
                    text-align: right;
                    margin: 5px 0 0; }
                .form-label {
                    font-size: 14px;
                    line-height: 1.3;
                    font-weight: 600;
                    color: $black-color; }
                &.cop-data-group {
                    width: 100%;
                    .form-label {
                        width: 88px;
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 5px 0 0;
                        @media (max-width: $breakpoint-mobile) {
                            width: 100%;
                            margin-bottom: 10px; } }
                    .form-control {
                        display: inline-block;
                        vertical-align: middle;
                        width: 60%;
                        width: calc(100% - 180px);
                        @media (max-width: $breakpoint-mobile) {
                            width: calc(100% - 45px); } }
                    .form-data-copy {
                        display: inline-block;
                        vertical-align: middle;
                        margin: 0 0 0 5px;
                        a {
                            display: inline-block;
                            &:hover {
                                .copy-icon {
                                    background-color: $primary-color;
                                    path {
                                        stroke: $white-color; } }
                                span {
                                    color: $primary-color; } } }
                        span {
                            display: inline-block;
                            vertical-align: middle;
                            font-size: 14px;
                            line-height: 1.4;
                            color: $black-color;
                            @media (max-width: $breakpoint-mobile) {
                                display: none; } }
                        .copy-icon {
                            background-color: rgba(196,196,196,.3);
                            border-radius: 5px;
                            padding: 5px;
                            margin: 0 5px 0 0;
                            @media (max-width: $breakpoint-mobile) {
                                display: inline-block; } } } } } } } }
.domain-step {
    display: flex;
    //height: 90vh
    //height: calc(100vh - 182px)
    flex-direction: column;
    height: 90vh;
    height: calc(100vh - 182px);
    @media (max-width: $breakpoint-mobile) {
        height: auto; }
    .connect-domain-btn {
        padding: 12px 10px;
        min-width: 163px; }
    span.info-icon {
        background: $primary-color;
        color: $white-color;
        width: 20px;
        height: 20px;
        display: inline-block;
        line-height: 20px;
        border-radius: 90px;
        font-weight: bold;
        margin-right: 10px; }
    &.domain-step-begin {
        justify-content: center;
        @media (max-width: $breakpoint-ipad) {
            margin-top: 0; } }
    &.domain-final-step {
        //height: auto !important
        img {
            margin: 0 auto;
            &.circleImg {
                border-radius: 100%; } }
        span {
            text-align: center; }
        .stepTitle {
            color: $black-color;
            font-weight: bold;
            font-size: 24px; }
        p {
            font-weight: normal;
            font-size: 16px;
            max-width: 590px;
            width: 100%;
            margin: 0 auto;
            letter-spacing: -0.5px; }
        .domain-btns {
            text-align: center;
            margin-top: 30px;
            .btn {
                padding: 12px 40px;
                margin: 0 10px;
                min-width: 205px;
                //&.btn-secondary
                //  //border-color: $orange-disabled-color !important
                //  //background: transparent !important
                //  //color: $orange-disabled-color !important
                //  //&:disabled
                //      //border-color: $orange-disabled-color !important
                //      //background: transparent !important
                //      //color: $orange-disabled-color !important
                @media (max-width: $breakpoint-mobile) {
                    min-width: 0;
                    padding: 12px 18px;
                    margin: 0 5px; } } }
        .finalStepTable {
            max-width: 484px;
            width: 100%;
            margin: 0 auto;
            h4 {
                color: $black-color;
                font-size: 24px;
                margin-top: 30px;
                font-weight: 700; }
            .table {
                thead,
                tbody {
                    background: $light-bg-color;
                    th {
                        font-size: 14px;
                        letter-spacing: -0.5px;
                        color: $black-color;
                        font-weight: 400;
                        opacity: 0.8; }
                    td {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 140%;
                        letter-spacing: -0.5px;
                        word-break: break-word;
                        width: 114px; } } } } }
    &.domain-step-begin {
        // height: 90vh
 }        // height: calc(100vh - 182px)
    //
    &.domain-step-1 {
        max-width: 850px;
        margin: 0 auto;
        padding: 10px 0;
        height: auto;
        .btn {
            padding: 12px 15px;
            min-width: 160px; }
        .field_error {
            text-align: left;
            margin: 5px 0 0; }
        .domain-step-wrap {
            width: 100%;
            display: inline-block;
            margin: 0 0 27px;
            &:last-child {
                margin: 0; }
            // display: flex
            // flex-direction: column
            // justify-content: space-between
 }            // height: 100%
        .subDomainTitle {
            font-weight: 400;
            font-size: 16px;
            color: $black-color;
            opacity: 0.8;
            justify-content: center;
            display: flex;
            align-items: center;
            @media (max-width: $breakpoint-mobile) {
                display: inline; }
            svg {
                margin-left: 12px; } }
        .stepTitle {
            color: $black-color;
            font-weight: bold;
            font-size: 30px;
            margin: 0 0 11px;
            @media (max-width: $breakpoint-mobile) {
                font-size: 20px; } }
        .form-text {
            color: $black-color;
            font-weight: normal;
            font-size: 16px;
            line-height: 1.4; }
        .connect-domain-form {
            width: 600px;
            max-width: 100%;
            margin: 0 auto;
            & iframe {
                max-width: 100%; } }
        .inputWithIcon {
            position: relative;
            display: flex;
            margin: 30px 0 3px;
            @media (max-width: $breakpoint-mobile) {
                flex-direction: column; }
            .form-control {
                margin-right: 8px;
                @media (max-width: $breakpoint-mobile) {
                    margin-right: 0;
                    width: 100%;
                    margin-bottom: 15px; } }
            .form-data-copy {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 10;
                width: 75px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                background: $primary-color;
                @media (max-width: $breakpoint-mobile) {
                    width: 65px;
                    height: 40px; }
                svg {
                    path {
                        stroke: $white-color;
                        stroke-width: 0; } } } } }

    &.domain-step-2,
    &.domain-step-3 {
        max-width: 587px;
        margin: 0 auto;
        width: 100%;
        span.showAfterClick {
            position: absolute;
            right: -62px;
            color: #3132c8;
            font-weight: 600; }
        @media (max-width: $breakpoint-mobile) {
            padding-bottom: 46px;
            height: 100vh;
            margin-bottom: 46px !important; }
        .domain-step-wrap {
            //margin-top: 60px
            .domain-step-status {
                margin-top: 30px; } }
        .activeTextCopy {
            display: none; }
        .stepTitle {
            color: $black-color;
            font-weight: bold;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                margin-left: 10px;
                height: 22px;
                margin-top: -4px; } }
        .inputWithIcon {
            position: relative;
            margin-top: 10px;
            margin-bottom: 5px;
            label {
                padding-left: 15px; }
            .form-data-copy {
                position: absolute;
                right: 0;
                z-index: 10;
                width: 75px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                background: $primary-color;
                bottom: 0;
                @media (max-width: $breakpoint-mobile) {
                    width: 65px;
                    height: 40px; }
                &.copied {
                    svg {
                        path {
                            stroke-width: 2.5px; } } }
                svg {
                    path {
                        stroke: $white-color; } } } }
        .domain-step-status {
            max-width: 450px;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .btn {
                height: 42px; } }
        .domain-step-content {
            ul {
                list-style: disc;
                padding-left: 24px;
                margin-top: 30px;
                li {
                    color: $black-color;
                    letter-spacing: -0.5px;
                    font-size: 16px;
                    line-height: 33px; } } }
        .domain-step-status {
            .btn {
                margin: 0 10px; }
            .domain-status-btn {
                margin-top: 20px; }
            .connected {
                background: $green-success-color;
                color: $dark-green-color;
                padding-top: 12px;
                img {
                    width: 16px;
                    margin-right: 5px;
                    vertical-align: top; } }
            .panding {
                background: $in-progress-color;
                color: #fff;
                img {
                    width: 16px;
                    margin-right: 5px;
                    vertical-align: top; } } } } }
.domain-step-status,
.domain-btns {
    .btn {
        &.orangeBtn {
            border-color: $orange-disabled-color !important;
            background: $orange-disabled-color !important;
            color: $white-color !important;
            font-weight: 600 !important;
            &:hover,
            &:focus {
                background: transparent !important;
                color: $orange-disabled-color !important; } } } }
.steper {
    max-width: 178px;
    margin: 0 auto;
    ul {
        display: flex;
        justify-content: space-between;
        li {
            position: relative;
            color: #fff;
            width: 30px;
            height: 30px;
            z-index: 10;
            border-radius: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:first-child {
                &:after {
                    content: none; } }
            &:after {
                content: "";
                border-bottom: 1px dashed #3132c8;
                width: 110px;
                position: absolute;
                right: 34px; }
            &.deactivate-step {
                border: 1px solid $grey-light-progress-color;
                span {
                    background: $grey-light-progress-color;
                    color: $grey-font-color; } }
            &.progress-step {
                border: 1px solid $purple-bg-color;
                span {
                    background: $purple-bg-color; } }
            &.activate-step {
                border: 1px solid $primary-color;
                span {
                    background: $primary-color; } }
            span {
                width: 24px;
                height: 24px;
                border-radius: 90px;
                left: 3px;
                top: 3px;
                display: flex;
                justify-content: center; } } } }

// .active-step-1
//     .domain-step
//         display: none
//         &.domain-step-1
//             display: flex

// .active-step-2
//     .domain-step
//         display: none
//         &.domain-step-2
//             display: flex

// .active-step-3
//     .domain-step
//         display: none
//         &.domain-step-3
//             display: flex

.list-step {
    text-align: left;
    list-style: disc;
    margin: 0 0 0 65px;
    @media (max-width: $breakpoint-ipad) {
        margin: 0 0 0 20px; }
    li {
        margin: 0 0 10px;
        font-size: 18px;
        line-height: 1.4;
        color: $black-color;
        font-weight: 500;
        letter-spacing: -0.5px;
        p {
            margin: 0 0 10px;
            font-size: 18px;
            line-height: 1.4;
            color: $black-color; } } }
.dashboard-view-subscribe {
    list-style: none;
    margin: 0;
    padding: 5px 0 0;
    li {
        width: 49.5%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        @media (max-width: $breakpoint-mobile) {
            width: 100%;
            margin-bottom: 22px;
            border-bottom: 1px solid rgba(0,0,0,.1);
            &:last-child {
                margin-bottom: 0;
                border-bottom: none; } }
        h5 {
            font-size: 16px;
            line-height: 1;
            font-weight: 800;
            color: $light-grey-link;
            text-transform: uppercase;
            @media (max-width: $breakpoint-ipad-pro) {
                font-size: 14px; } }
        h3 {
            font-size: 36px;
            line-height: 1;
            font-weight: 800;
            color: $black-color;
            margin: 21px 0 10px;
            @media (max-width: $breakpoint-ipad-pro) {
                font-size: 28px; }
            sub {
                font-size: 12px;
                line-height: 1.5;
                font-weight: normal;
                bottom: 1px;
                margin-left: 5px;
                svg,
                span {
                    display: inline-block;
                    vertical-align: middle; } } }
        a {
            font-size: 12px;
            line-height: 15px;
            text-decoration: underline !important;
            font-weight: 600;
            color: $primary-color !important; } } }
.cname-example {
    background-color: $white-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 7px 30px;
    margin: 16px 0;
    @media (max-width: $breakpoint-ipad-pro) {
        padding: 7px 10px; }
    .cname-eg-inputs {
        width: 100%;
        display: inline-block;
        .form-group {
            width: auto;
            display: inline-block;
            margin: 0 0 0 21px;
            vertical-align: bottom;
            min-width: 93px;
            @media (max-width: $breakpoint-ipad-pro) {
                margin: 0 0 0 10px;
                min-width: 92px; }
            @media (max-width: $breakpoint-mobile) {
                margin: 0 0 15px; }
            &:first-child {
                margin-left: 0;
                @media (max-width: $breakpoint-mobile) {
                    width: 100%; } }
            &:nth-child(2),
            &:nth-child(4) {
                @media (max-width: $breakpoint-mobile) {
                    width: 70%;
                    width: calc(100% - 95px); } }
            .form-label {
                font-size: 14px;
                line-height: 1.3;
                font-weight: 600;
                color: $black-color; }
            .cname-label {
                &.form-text {
                    margin: 0;
                    font-size: 16px;
                    line-height: 36px;
                    font-weight: 700;
                    color: $black-color;
                    @media (max-width: $breakpoint-mobile) {
                        line-height: 1.3; } } }
            .cname-copy {
                &:hover {
                    cursor: pointer; }
                &.form-text {
                    margin: 0;
                    font-size: 14px;
                    line-height: 1.4;
                    color: $black-color;
                    font-weight: 400;
                    @media (max-width: $breakpoint-mobile) {
                        margin-left: 10px; }
                    span {
                        display: inline-block;
                        vertical-align: middle;
                        &.cname-copy-icon {
                            background-color: rgba(196,196,196,0.3);
                            padding: 6px 7px;
                            margin-right: 8px; } } } }
            .form-control {
                font-size: 14px;
                line-height: 1.4;
                color: $black-color;
                font-weight: 500;
                border: 1px solid rgba(51, 51, 51, 0.3);
                padding: 1px 9px;
                border-radius: 0;
                height: auto;
                min-height: 36px;
                pointer-events: none;
                &.cname_value_input {
                    width: 155px;
                    @media (max-width: $breakpoint-ipad-pro) {
                        width: 130px; }
                    @media (max-width: $breakpoint-mobile) {
                        width: 100%; } }
                &.host_input {
                    width: 123px;
                    @media (max-width: $breakpoint-ipad-pro) {
                        width: 103px; }
                    @media (max-width: $breakpoint-mobile) {
                        width: 100%; } } } } } }

//
.seoSection {
    //max-width: 780px
    width: 100%;
    margin-top: 20px;
    .editLink {
        font-weight: normal;
        letter-spacing: 0.18px;
        font-size: 18px;
        color: $blue-title-color;
        text-decoration: underline;
        &:hover {
            text-decoration: none; } }
    .seoHeading {
        font-size: 24px;
        font-weight: 800;
        letter-spacing: 0.18px;
        color: $black-color; }
    .seoDataPreview {
        background: #FFFFFF;
        border-radius: 5px;
        margin: 10px 0 0;
        padding: 30px;
        @media (max-width: $breakpoint-mobile) {
            padding: 10px; }
        .seoTitle {
            font-size: 28px;
            font-weight: 500;
            line-height: 1.3;
            letter-spacing: 0.18px;
            color: #091FA4;
            @media (max-width: $breakpoint-mobile) {
                font-size: 20px; } }
        .seoURL {
            margin-bottom: 10px;
            .seoUrl {
                font-weight: 500;
                font-size: 18px;
                line-height: 1.3;
                letter-spacing: 0.18px;
                color: #23632A;
                @media (max-width: $breakpoint-mobile) {
                    word-break: break-word;
                    font-size: 16px; } } }
        .seoContent {
            font-weight: normal;
            font-size: 16px;
            line-height: 1.3;
            letter-spacing: 0.18px;
            margin-bottom: 0;
            color: $black-color;
            width: 729px;
            max-width: 100%;
            @media (max-width: $breakpoint-mobile) {
                font-size: 14px; }
            span {
                text-transform: uppercase;
                color: $body-color; } }
        .seo-form {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 15px 0;
            &:first-child {
                margin-top: 0; }
            @media (max-width: $breakpoint-mobile) {
                flex-direction: column; }
            .form-group {
                margin: 0 15px 0 0;
                @media (max-width: $breakpoint-mobile) {
                    margin:  0 0 10px; }
                .form-control {
                    border: 1px solid rgba(51, 51, 51, 0.3);
                    border-radius: 100px;
                    font-size: 14px;
                    padding: 9px 15px;
                    height: auto;
                    min-height: 0;
                    &:focus {
                        outline: none; } } }
            .btn {
                @media (max-width: $breakpoint-mobile) {
                    width: 100%; } } } } }
.innerChanges {
    display: flex;
    flex-direction: column;
    justify-content: inherit;
    align-items: center;
    max-width: 290px;
    height: 155px; }
.blog-categories {
    &.right-side {
        width: 368px;
        display: inline-block;
        padding: 0 0 0 40px;
        @media (max-width: $breakpoint-ipad-pro) {
            width: 100%;
            padding: 0; }
        .dflexBox {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            min-height: 429px;
            @media (max-width: $breakpoint-ipad-pro) {
                min-height: 0; } }
        .blog-btns {
            text-align: center;
            margin-bottom: 0 !important;
            @media (max-width: $breakpoint-ipad-pro) {
                width: 100%;
                text-align: left; }
            .btn {
                max-width: 180px;
                width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-right: 0 !important;
                margin-bottom: 20px;
                @media (max-width: $breakpoint-ipad-pro) {
                    margin-bottom: 0;
                    margin-right: 15px !important;
                    @media (max-width: $breakpoint-mobile) {
                        margin-right: 0 !important;
                        margin-bottom: 10px; }
                    &:last-child {
                        margin-right: 0 !important; } }
                @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                    max-width: unset; }
                &:last-child {
                    margin-bottom: 0; }
                &.btn-outline-primary {
                    margin-bottom: 20px;
                    &:disabled {
                        border-color: $primary-color !important; }
                    @media (max-width: $breakpoint-ipad) {
                        margin-bottom: 0;
                        margin-right: 20px !important; } } } }
        .input-text {
            textarea {
                background: #ffffff;
                border: 1px solid rgba(51, 51, 51, 0.3) !important;
                border-radius: 5px !important; } }
        .items {
            margin-top: 8px;
            ul {
                margin-bottom: 0; }
            h3 {
                font-weight: 500;
                font-size: 16px;
                color: $black-color; } } }
    h4 {
        font-size: 18px;
        font-weight: 700;
        color: $black-color;
        text-align: center;
        margin: 0 0 12px; }
    input {
        font-size: 18px !important;
        min-height: 29px !important;
        padding-left: 5px;
        width: 100% !important;
        background: #ffffff;
        border: 1px solid rgba(51, 51, 51, 0.3) !important;
        border-radius: 5px !important; }
    .editLink {
        font-weight: normal;
        letter-spacing: 0.18px;
        font-size: 18px;
        color: $blue-title-color;
        text-decoration: underline;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 12px;
        &:hover {
            text-decoration: none; } }
    .badge {
        font-weight: 500;
        font-size: 14px;
        color: #000000 !important;
        margin-bottom: 8px !important;
        border-radius: 5px !important;
        background: #E2E2E2 !important;
        padding: 5px 8px !important;
        word-break: break-word;
        display: inline-block;
        vertical-align: middle;
        a {
            margin-left: 4px; } } }

.blog-categories {
  .default {
    background: $primary-color !important;
    color: $white-color !important;
    svg {
        path {
            fill: $white-color !important; } } } }
.website-title-edit-form {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 15px;
    @media (max-width: $breakpoint-ipad) {
        flex-direction: column; }
    .btn {
        margin: 0 10px;
        @media (max-width: $breakpoint-ipad) {
            margin: 15px 0 5px; } } }

.toggle-sidebar-closed {
    &.dashboard-menu {
        ul {
            li {
                &.header-profile-img {
                    .dropdown {
                        padding: 0 12px 0 0;
                        .dropdown-toggle {
                            justify-content: flex-end; }
                        .dropdown-label,
                        svg {
                            display: none; } } } } } } }
.seoSection {
    .field_error {
        color: red;
        font-weight: normal; }
    .seoDataPreview {
        & > .field_error {
            position: relative;
            left: 95px;
            display: block;
            top: -12px;
            @media (max-width: $breakpoint-mobile) {
                left: 0; } }
        .seo-form-label {
            margin: 0 0 15px;
            display: flex;
            //align-items: center
            position: relative;
            @media (max-width: $breakpoint-mobile) {
                flex-direction: column;
                align-items: self-start; }
            .seoTitle,
            a.seoUrl,
            .seoContent,
            .blog-slug-link,
            .form-group .form-control,
            .seoUrl {
                font-size: 16px;
                line-height: 1.3;
                font-weight: normal;
                color: #1B1B1B;
                margin: 0;
                @media (max-width: $breakpoint-mobile) {
                    font-size: 14px; } }
            label {
                font-size: 14px;
                line-height: 1.3;
                color: #1B1B1B;
                opacity: 0.5;
                margin: 13px 15px 0 0;
                min-width: 75px;
                @media (max-width: $breakpoint-mobile) {
                    margin: 0 0 8px;
                    min-width: 0; }
                .blog-slug-link {
                    opacity: 1 !important; } }
            .form-group {
                margin: 0;
                .form-control {
                    border: 1px solid rgba(27, 27, 27, 0.15);
                    background: transparent;
                    border-radius: 7px;
                    height: auto;
                    min-height: 0;
                    padding: 12px 15px;
                    @media (max-width: $breakpoint-mobile) {
                        padding: 10px 12px; }
                    &::placeholder {
                        opacity: .4; } }
                textarea {
                    &.form-control {
                        height: 46.8px;
 } } } }                        //border-radius: 10px
        .btn {
            padding: 12px 30px;
            svg {
                margin-right: 9px; }
            &.edit-btn {
                margin-top: 20px;
                background: #DBEBFF;
                color: #506BF0;
                border-color: #DBEBFF;
                &:hover,
                &:active,
                &:focus {
                    background: #506BF0;
                    color: #DBEBFF;
                    border-color: #506BF0;
                    svg {
                        path {
                            fill: #DBEBFF; } } } } }
        .blog-slug {
            display: flex;
            width: 100%;
            //align-items: center
            @media (max-width: $breakpoint-mobile) {
                flex-direction: column;
                align-items: flex-start; }
            .field_error {
 }                //position: absolute
            .blog-slug-link {
                margin: 13px 15px 0 0;
                display: inline-block;
                vertical-align: middle;
                white-space: pre;
                //min-width: 300px
                @media (max-width: $breakpoint-mobile) {
                    margin: 0 0 8px 0;
                    white-space: unset; } }
            .form-group {
                display: inline-block;
                vertical-align: middle;
                //width: 60%
                //width: calc(100% - 355px)
                //width: 300px
 } } } }                //max-width: 100%
.cover-img-upload-select {
    text-align: center;
    .preview-logo {
        margin: 0 0 10px;
        img {
            border-radius: 15px;
            border: 2px solid $primary-color;
            max-height: 200px; } }
    .upload-file-- {
        &::after {
            display: none; }
        svg {
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle; }
        span {
            display: inline-block;
            vertical-align: middle; } } }
.blog-editor {
    .field_error {
        position: relative;
        left: 50px;
        top: -13px;
        @media (max-width: $breakpoint-mobile) {
            top: -3px;
            left: 5px; } } }
.domain-step-example {
    width: 953px;
    max-width: 100%;
    display: block;
    padding: 0 17px;
    margin: 61px 0 20px;
    @media (max-width: $breakpoint-mobile) {
        padding: 0;
        margin: 30px 0 20px; }
    .stepTitle {
        font-size: 24px;
        line-height: 1.4;
        font-weight: bold;
        color: #1B1B1B;
        margin: 0 0 27px;
        @media (max-width: $breakpoint-mobile) {
            font-size: 20px;
            margin: 0 0 15px; } }
    h5 {
        font-size: 20px;
        line-height: 1.4;
        font-weight: bold;
        color: #1B1B1B;
        text-align: center;
        margin: 0 0 14px;
        @media (max-width: $breakpoint-mobile) {
            font-size: 16px;
            margin: 0 0 8px; } }
    .domain-step-preview {
        margin: 0 0 20px;
        .domain-step-preview-img {
            img {
                max-width: 100%; } }
        &.domain-step-preview-1 {
            .domain-step-preview-img {
                padding-left: 30px;
                @media (max-width: $breakpoint-mobile) {
                    padding-left: 0; } } } } }
.domain-step {
    &.domain-final-step {
        text-align: center;
        padding: 70px 0 0;
        width: 100%;
        //height: auto
        justify-content: center;
        height: 90vh;
        height: calc(100vh - 182px);
        @media (max-width: $breakpoint-mobile) {
            padding: 0; }
        .domain-final-step-wrap {
            width: 625px;
            max-width: 100%;
            margin: 0 auto;
            .domain-final-icon {
                width: 100%;
                display: inline-block;
                img {
                    max-width: 100%; }
                .react-loading-skeleton {
                    margin: 0 0 30px;
                    @media (max-width: $breakpoint-mobile) {
                        margin: 0 0 20px;
                        height: 130px !important;
                        width: 130px !important; } } }
            .domain-connect-info {
                width: 100%;
                display: inline-block;
                margin: 16px 0 34px;
                @media (max-width: $breakpoint-mobile) {
                    margin-bottom: 0; }
                .react-loading-skeleton {
                    margin-bottom: 5px;
                    @media (max-width: $breakpoint-mobile) {
                        height: 15px !important; } }
                h4 {
                    font-size: 24px;
                    line-height: 1.4;
                    letter-spacing: -0.5px;
                    color: #1B1B1B;
                    font-weight: bold;
                    margin: 0 0 12px;
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 20px; } }
                p {
                    font-size: 16px;
                    line-height: 1.4;
                    letter-spacing: -0.5px;
                    color: #1B1B1B;
                    margin: 0 0 20px;
                    max-width: 565px;
                    margin-left: auto;
                    margin-right: auto;
                    @media (max-width: $breakpoint-mobile) {
                        //font-size: 14px
                        margin: 0 0 10px; } }
                .btn {
                    margin: 14px 0 0;
                    font-size: 16px;
                    line-height: 1.3;
                    letter-spacing: -0.2px;
                    padding: 15px 22px 13px;
                    font-weight: normal;
                    @media (max-width: $breakpoint-mobile) {
                        padding: 12px 15px; } } }
            .domain-cname-detail {
                width: 100%;
                display: inline-block;
                .react-loading-skeleton {
                    @media (max-width: $breakpoint-mobile) {
                        height: 22px !important; } }
                h3 {
                    font-size: 24px;
                    line-height: 1.4;
                    color: #1B1B1B;
                    letter-spacing: -0.5px;
                    font-weight: bold;
                    margin: 0 0 16px;
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 18px; } }
                .domain-cname-table {
                    width: 100%;
                    overflow: auto; }
                table {
                    text-align: left;
                    width: 625px;
                    @media (max-width: $breakpoint-mobile) {
                        width: 400px; }
                    //background-color: #fff
                    th,
                    td {
                        padding: 15px 15px 15px 0;
                        @media (max-width: $breakpoint-mobile) {
                            padding: 12px 0; } }
                    thead {
                        th {
                            font-size: 14px;
                            line-height: 1.4;
                            color: #1B1B1B;
                            opacity: 0.8;
                            letter-spacing: -0.5px;
                            font-weight: normal; } }
                    tbody {
                        tr {
                            border-top: 1px solid #000000; }
                        td {
                            font-size: 18px;
                            font-weight: 600;
                            line-height: 1.4;
                            letter-spacing: -0.5px;
                            color: #1B1B1B;
                            @media (max-width: $breakpoint-mobile) {
                                font-size: 14px; } } } } } } } }
.menu-edit-header {
    .row {
        @media (max-width: $breakpoint-mobile) {
            flex-direction: column; }
        .col {
            @media (max-width: $breakpoint-mobile) {
                flex: 0 0 100%;
                max-width: 100%; } } }
    .alert {
        @media (max-width: $breakpoint-mobile) {
            display: inline-block;
            margin-top: 15px;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            z-index: 999999; } } }
.warning-fixed-alert {
    width: 100%;
    display: inline-block;
    text-align: center;
    top: -5px;
    position: relative;
    margin: 0 0 20px;
    @media (max-width: $breakpoint-mobile) {
        top: 0;
        margin: 0 0 15px; }
    .alert {
        height: auto;
        width: auto;
        display: inline-block;
        background-color: rgba(255, 243, 136, 0.92);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: #000;
        padding: 11px 15px !important;
        margin: 0 !important;
        .close-alert {
            margin-left: 15px; }
        .red-msg {
            color: #FF0000; }
        a {
            color: #000;
            text-decoration: underline; }
        .bold {
            font-weight: 700; } } }
.website-listing {
    .blog-custom-list-table {
        .blog-custom-list {
            .blog-list-header {
                &.blog-list-table {
                    @media (max-width: $breakpoint-mobile) {
                        text-align: left; }
                    .blog-list-column {
                        @media (max-width: $breakpoint-mobile) {
                            width: auto; }
                        &.blog-list-title {
                            @media (max-width: $breakpoint-mobile) {
                                width: 100%;
                                max-width: 100%; } }
                        &.blog-list-actions {
                            @media (max-width: $breakpoint-mobile) {
                                margin-top: 12px; } } } } } } } }
.inject-script-wrap {
    @media (max-width: $breakpoint-ipad) {
        flex-direction: column; }
    .col-md-5,.col-md-7 {
        @media (max-width: $breakpoint-ipad) {
            flex: 0 0 100%;
            max-width: 100%; } }
    form {
        @media (max-width: $breakpoint-ipad) {
            margin: 15px 0 25px; } }
    .form-group {
        textarea {
            &.form-control {
                min-height: 175px; } } } }
.phone-number-form {
    display: flex;
    justify-content: center;
    @media (max-width: $breakpoint-ipad) {
        flex-direction: column; }
    .form-group {
        margin: 0 15px 0 0;
        width: 60%;
        width: calc(100% - 225px);
        @media (max-width: $breakpoint-ipad) {
            margin: 0 0 15px;
            width: 100%; }
        .form-control {
            border: 2px solid rgba(51, 51, 51, 0.3); } }
    .btn {
        padding: 15px ​62px;
        margin: 29px 0 0;
        height: auto;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        @media (max-width: $breakpoint-ipad) {
            margin: 0;
            width: 100%; } } }
.social-connect-modal {
    .styled-radio-btn {
        .styled-radio {
            &:hover,
            &.active {
                & + .input-radio-label {
                    border: 1px solid #3032C8;
                    box-shadow: 0 0 0 1px #3032c8;
                    background-color: rgba(48, 50, 200, 0.1); } } } } }
.downgrade-modal {
    &.modal {
        .modal-dialog {
            width: 633px;
            .modal-header {
                padding: 20px 25px 0;
                border: none;
                justify-content: flex-end;
                background-color: #e5e5e5;
                @media (max-width: $breakpoint-mobile) {
                    padding: 15px 15px 0; } }
            .modal-body {
                padding: 10px 25px 35px;
                background-color: #e5e5e5;
                @media (max-width: $breakpoint-mobile) {
                    padding: 15px 15px 20px; }
                h5 {
                    font-size: 17px;
                    line-height: 1;
                    letter-spacing: 0.05em;
                    color: $black-color;
                    font-weight: 600;
                    margin: 0 0 15px;
                    @media (max-width: $breakpoint-mobile) {
                        font-size: 16px;
                        line-height: 1.3; }
                    span {
                        display: block;
                        margin: 0 0 5px;
                        @media (max-width: $breakpoint-mobile) {
                            margin: 0 0 8px; } } }
                .btn {
                    padding: 12px 25px;
                    min-width: 166px;
                    &.btn-secondary {
                        background-color: transparent;
                        &:hover,
                        &:active,
                        &:focus {
                            background-color: $primary-color; } } }
                .downgrade-btns {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 30px 0;
                    @media (max-width: $breakpoint-mobile) {
                        flex-direction: column;
                        margin: 20px 0 5px; }
                    .btn {
                        margin-left: 30px;
                        @media (max-width: $breakpoint-mobile) {
                            margin: 0 0 15px; }
                        &:first-child {
                            margin-left: 0; } } }
                .downgrade-plans {
                    h6 {
                        font-size: 18px;
                        line-height: 1.5;
                        font-weight: 500;
                        color: $black-color;
                        letter-spacing: 0.5px;
                        margin: 0 0 8px;
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 14px;
                            line-height: 1.3; } }
                    p {
                        font-size: 15px;
                        font-weight: 700;
                        letter-spacing: 0.5px;
                        line-height: 1.5;
                        color: #646464;
                        margin: 0;
                        @media (max-width: $breakpoint-mobile) {
                            font-size: 14px;
                            line-height: 1.3; }
                        b {
                            font-weight: 900; } } }
                .downgrade-site-list {
                    margin: 25px 0 0;
                    table {
                        margin: 0 0 38px;
                        background-color: transparent;
                        @media (max-width: $breakpoint-mobile) {
                            margin: 0 0 25px; }
                        p {
                            margin: 0; }
                        tr {
                            width: 100%;
                            display: table-row;
                            td {
                                border: none;
                                padding: 17px;
                                vertical-align: middle;
                                font-size: 14px;
                                line-height: 1.5;
                                font-weight: 600;
                                color: $black-color;
                                @media (max-width: $breakpoint-mobile) {
                                    padding: 12px; }
                                &:nth-child(2) {
                                    color: $primary-color;
                                    text-decoration: underline; }
                                label {
                                    margin: 0;
                                    line-height: 0;
                                    input {
                                        width: 24px;
                                        height: 24px;
                                        box-shadow: 0px 4px 4px rgba(0,0,0,.25);
                                        border: 1px solid rgba(51,51,51,0.3);
                                        background-color: transparent;
                                        @media (max-width: $breakpoint-mobile) {
                                            width: 18px;
                                            height: 18px;
                                            box-shadow: none; } } } } }
                        tbody {
                            tr {
                                border-bottom: 1px solid #A0A0A0;
                                background-color: $white-color;
                                &:first-child {
                                    td {
                                        &:first-child {
                                            border-top-left-radius: 10px; }
                                        &:last-child {
                                            border-top-right-radius: 10px; } } }
                                &:last-child {
                                    &:first-child {
                                        border-bottom-left-radius: 10px; }
                                    &:last-child {
                                        border-bottom-right-radius: 10px; } } } }
                        thead {
                            background-color: transparent;
                            tr {
                                border: none;
                                background-color: transparent; }

                            th {
                                border: none;
                                font-size: 16px;
                                line-height: 1.5;
                                font-weight: 600;
                                letter-spacing: 0.5px;
                                color: #727272;
                                padding: 17px;
                                @media (max-width: $breakpoint-mobile) {
                                    font-size: 14px;
                                    line-height: 1.3;
                                    padding: 12px; } } } } } } } } }

.dropdown-toggle {
    &.dropdown-indicator {
        &:focus {
            box-shadow: unset !important; }
        & .share-web {
            padding-bottom: 6px; }
        &::after {
            display: none; } } }

.rdw-dropdown-selectedtext {
    & span {
        color: #000; }
    &:hover {
        text-decoration: unset; } }

.panel-wrap {
    .panel-header {
        position: relative; }
    .panel-toggle {
        position: absolute;
        top: 2px;
        right: 0px;
        background-color: transparent;
        border: unset; }
    .panel-body {
        iframe {
            max-width: 100%; } } }
