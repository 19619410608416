@import '../../variables.sass';
.publisher-how-work {
    background: linear-gradient(113.13deg, #FFFFFF -136.23%, rgba(255, 255, 255, 0) 70.11%), #422DAA;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    padding: 90px 0;
    @media (max-width: 1550px) {
        padding: 60px 0; }
    @media (max-width: $breakpoint-ipad-pro) {
        padding: 50px 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 0; }
    @media (max-width: $breakpoint-ipad) {
        padding: 30px 0; }
    .publisher-how-work-inner {
        align-items: center;
        display: flex;
        @media (max-width: $breakpoint-ipad) {
            flex-direction: column; } }
    .how-work-img {
        position: relative;
        text-align: center;
        iframe {
            max-width: 100%; }
        .personImg {
            position: relative;
            z-index: 8;
            border-radius: 30px;
            @media (max-width: $breakpoint-ipad) {
                width: 80%; } }
        svg {
            @media (max-width: 1550px) {
                width: 100px;
                height: 100px; } }
        .how-work-img-vector {
            @media (max-width: $breakpoint-ipad-pro) {
                display: none; }
            .vector1,
            .vector2,
            .vector3,
            .vector4 {
                position: absolute; }
            .vector1 {
                top: -90px;
                left: 70px;
                @media (max-width: 1550px) {
                    left: 48px;
                    top: -70px; } }
            .vector2 {
                right: -89px;
                top: 109px;
                @media (max-width: 1550px) {
                    right: -69px; } }
            .vector3 {
                left: -105px;
                bottom: 128px;
                @media (max-width: 1550px) {
                    left: -45px;
                    bottom: 250px; } }
            .vector4 {
                bottom: -106px;
                right: 50px;
                @media (max-width: 1550px) {
                    bottom: -55px;
                    right: 130px; } } }

        a {
            position: absolute;
            z-index: 10;
            display: flex;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            justify-content: center;
            align-items: center; } }
    .how-work-right {
        padding-left: 228px;
        @media (max-width: 1550px) {
            padding-left: 130px; }
        @media (max-width: $breakpoint-ipad-pro) {
            padding-left: 60px; }
        @media (max-width: $breakpoint-ipad) {
            padding-left: 0;
            padding-top: 30px; }
        h3 {
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;
            color: $white-color;
            padding-left: 50px;
            margin-bottom: 60px;
            @media (max-width: 1550px) {
                font-size: 24px;
                margin-bottom: 30px; }
            @media (max-width: $breakpoint-ipad-pro) {
                font-size: 20px;
                margin-bottom: 20px; } }
        ul {
            width: 459px;
            max-width: 100%;
            margin-top: 15px;
            margin-bottom: 20px;
            @media (max-width: 1550px) {
                width: 419px;
                margin-bottom: 15px;
                margin-top: 10px; }
            @media (max-width: $breakpoint-ipad) {
                width: 100%; }
            li {
                position: relative;
                display: flex;
                flex-direction: row;
                padding-top: 23px;
                @media (max-width: 1550px) {
                    padding-top: 18px; }
                @media (max-width: $breakpoint-ipad-pro) {
                    padding-top: 12px; }
                .counter {
                    max-width: 32px;
                    max-height: 32px;
                    left: 0;
                    background: $green-circle-color;
                    border-radius: 100%;
                    color: $white-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    margin-right: 20px;
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: bold;
                    @media (max-width: 1550px) {
                        font-size: 16px;
                        max-width: 28px;
                        max-height: 28px; }
                    @media (max-width: $breakpoint-ipad-pro) {
                        font-size: 14px;
                        max-width: 25px;
                        max-height: 25px; } }
                .how-work-steps {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                    h4 {
                        font-size: 24px;
                        line-height: 30px;
                        font-weight: 700;
                        color: $white-color;
                        margin: 0;
                        @media (max-width: 1550px) {
                            font-size: 20px; }
                        @media (max-width: $breakpoint-ipad-pro) {
                            font-size: 18px; } }
                    p {
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 22px;
                        margin-top: 14px;
                        color: $white-color;
                        margin-bottom: 28px;
                        @media (max-width: 1550px) {
                            font-size: 16px;
                            margin-top: 10px;
                            margin-bottom: 15px; }
                        @media (max-width: $breakpoint-ipad-pro) {
                            font-size: 14px;
                            margin-top: 6px;
                            margin-block-end: 10px; } } } } } }
    .how-work-action {
        display: flex;
        flex-direction: column;
        padding-left: 50px;
        max-width: 362px;
        justify-content: center;
        text-align: center;
        @media (max-width: 1550px) {
            max-width: 320px; }
        @media (max-width: $breakpoint-ipad-pro) {
            max-width: 295px; }
        @media (max-width: $breakpoint-ipad) {
            max-width: 100%;
            padding-left: 0; }
        span {
            color: $white-color;
            margin-top: 10px;
            font-weight: 600;
            font-size: 18px;
            @media (max-width: 1550px) {
                font-size: 1rem; } } } }
