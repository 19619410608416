@import '../../variables.sass';
.publisher-money-time {
    padding: 100px 0;
    @media (max-width: $breakpoint-ipad-pro) {
        padding: 60px 0; }
    .innerGap {
        margin-top: 70px;
        @media (max-width: $breakpoint-ipad-pro) {
            margin-top: 60px; } }
    .saveMoneyBtn {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
        @media (max-width: $breakpoint-ipad-pro) {
            margin-top: 50px; }
        span {
            font-weight: 600;
            font-size: 18px;
            margin-top: 10px;
            color: $black-color;
            @media (max-width: $breakpoint-ipad) {
                font-size: 14px; } }
        .btn.btn-primary.btn-primary-2.home-btn {
            padding: 28px 153px;
            @media (max-width: 1550px) {
                padding: 18px 60px; }
            @media (max-width: $breakpoint-ipad-pro) {
                padding: 12px 14px;
                font-size: 14px; } } }
    .publisher-saving-table {
        margin-top: 26px;
        overflow: auto;
        table {
            width: 100%;
            @media (max-width: $breakpoint-ipad) {
                min-width: 900px; }
            thead {
                tr {
                    td {
                        font-size: 28px;
                        font-weight: 700;
                        color: #12111b;
                        line-height: 34px;
                        border-bottom: 1px solid rgba(18, 17, 27, 0.3);
                        padding: 30px 40px;
                        vertical-align: middle;
                        @media (max-width: 1550px) {
                            font-size: 25px;
                            line-height: 1.3;
                            padding: 25px; }
                        @media (max-width: $breakpoint-ipad-pro) {
                            font-size: 20px;
                            padding: 15px; }
                        @media (max-width: $breakpoint-ipad) {
                            font-size: 18px; }
                        &:nth-child(2) {
                            border-left: 1px solid rgba(18, 17, 27, 0.3);
                            border-right: 1px solid rgba(18, 17, 27, 0.3); }
                        img {
                            width: 128px; } } } }
            tbody {
                tr {
                    td {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 20px;
                        color: #12111b;
                        border-bottom: 1px solid rgba(18, 17, 27, 0.3);
                        padding: 30px 40px;
                        vertical-align: middle;
                        @media (max-width: 1550px) {
                            font-size: 16px;
                            line-height: 1.3;
                            padding: 25px; }
                        @media (max-width: $breakpoint-ipad-pro) {
                            font-size: 14px;
                            padding: 15px; }
                        &:first-child {
                            font-weight: 800; }
                        &:nth-child(2) {
                            border-left: 1px solid rgba(18, 17, 27, 0.3);
                            border-right: 1px solid rgba(18, 17, 27, 0.3); }
                        svg {
                            margin-right: 20px; } } } }
            tfoot {
                tr {
                    td {
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 20px;
                        color: #4B495C;
                        padding: 30px 40px;
                        line-height: 35px;
                        vertical-align: middle;
                        @media (max-width: 1550px) {
                            font-size: 20px;
                            line-height: 1.3;
                            padding: 25px;
                            line-height: 1.5; }
                        @media (max-width: $breakpoint-ipad-pro) {
                            font-size: 18px;
                            padding: 15px; }
                        @media (max-width: $breakpoint-ipad) {
                            font-size: 16px; }
                        &:first-child {
                            font-size: 36px;
                            font-weight: bold;
                            text-align: center;
                            color: #12111B;
                            @media (max-width: 1550px) {
                                font-size: 30px; }
                            @media (max-width: $breakpoint-ipad-pro) {
                                font-size: 25px; }
                            @media (max-width: $breakpoint-ipad) {
                                font-size: 20px; } }
                        b {
                            color: #12111B;
                            font-weight: 800; }
                        &:nth-child(2) {
                            border-left: 1px solid rgba(18, 17, 27, 0.3);
                            border-right: 1px solid rgba(18, 17, 27, 0.3); } } } } } } }
