@import '../../variables.sass';
.publisher-pricing {
    .website-plan {
        padding: 10px 0 57px;
        @media (max-width: $breakpoint-ipad-pro) {
            padding: 10px 0 30px; }
        .select_plan {
            p {
                font-size: 20px;
                @media (max-width: $breakpoint-ipad) {
                    font-size: 18px; } } } }
    .home-container.container {
        @media (max-width: $breakpoint-ipad-pro) {
            max-width: 100% !important;
            padding: 0 !important; } }
    .publisher-pricing-inner {
        .publisher-pricing-boxes {
            .select_plan {
                margin-bottom: 14px;
                .switch_text {
                    font-size: 20px;
                    @media (max-width: $breakpoint-ipad) {
                        font-size: 18px; } } }
            .subheading {
                strong {
                    color: $black-color; }
                margin-bottom: 66px !important;
                @media (max-width: $breakpoint-ipad-pro) {
                    margin-bottom: 30px !important; }
                @media (max-width: $breakpoint-ipad) {
                    font-size: 18px;
                    margin-bottom: 15px !important; } }
            .price-slider {
                .row {
                    .col-md-3 {
                        @media (max-width: $breakpoint-ipad-pro) {
                            flex: 0 0 50%;
                            max-width: 50%; }
                        @media (max-width: $breakpoint-mobile) {
                            flex: 0 0 100%;
                            max-width: 100%; } } } }
            .custom-switch {
                height: 36px;
                margin-right: 45px;
                .custom-control-input {
                    &:focus {
                       & ~ .custom-control-label {
                            &::before {
                                border: 1px solid rgba(51, 51, 51, 0.3); } } }
                    &:checked {
                        & ~ .custom-control-label {
                            &:after {
                                background: $orange-secondary-color;
                                left: calc(-3.25rem + 20px); } } } }
                .custom-control-label {
                    &:before {
                        box-shadow: 4px 4px 20px rgba(255, 122, 0, 0.25) !important;
                        border-radius: 30px;
                        border: 1px solid rgba(51, 51, 51, 0.3);
                        height: 36px;
                        width: 81px; }
                    &:after {
                        background: $orange-secondary-color;
                        width: 28px;
                        height: 28px;
                        top: 4px;
                        left: calc(-3.25rem + 6px); } } } }
        .new_plan_box {
            transition: all 0.5s ease;
            margin: 10px;
            @media (max-width: $breakpoint-mobile) {
                margin: 0; }
            &:hover {
                transform: scale(1.2);
                background: #F1EEFF;
                border: 1px solid rgba(85, 64, 191, 0.6);
                box-shadow: 10px 10px 80px rgba(20, 9, 79, 0.2);
                position: relative;
                z-index: 10;
                .plan-price {
                    color: #5540BF; }
                ul {
                    li {
                        background-image: url('images/checklist-hover.svg') !important; } } }
            .plan-detail {
                padding: 30px 30px 0 !important;
                min-height: 375px;
                @media (max-width: 1550px) {
                    padding: 25px 20px 0 !important;
                    min-height: 365px; }
                @media (max-width: $breakpoint-mobile) {
                    min-height: 0;
                    margin-bottom: 25px; }
                .highlightText {
                    color: #5540BF;
                    font-size: 30px;
                    line-height: 30px;
                    font-weight: 700; }
                .plan-name {
                    font-weight: 400;
                    @media (max-width: 1550px) {
                        font-size: 22px !important; }
                    &.businessPlan {
                        background: #FFF0E2 !important;
                        border-radius: 100px !important;
                        color: $orange-secondary-color !important; }
                    & span.businessPlan {
                        background: #FFF0E2 !important;
                        border-radius: 100px !important;
                        color: $orange-secondary-color !important;
                        font-size: 14px !important;
                        line-height: 30px;
                        max-width: 100px;
                        margin-left: 10px !important; } }
                .plan-price {
                    text-align: left;
                    letter-spacing: -0.5px;
                    font-size: 40px;
                    font-weight: 700;
                    @media (max-width: 1550px) {
                        font-size: 35px; }
                    &.wizard-plan-price {
                        font-size: 32px;
                        @media (max-width: 1550px) {
                            font-size: 25px; } }
                    sub {
                        letter-spacing: -0.5px;
                        color: #4F5665;
                        font-weight: 400; } }
                .billBYyear {
                    text-align: left;
                    @media (max-width: 1550px) {
                        font-size: 12px; } }
                .collectData {
                    color: #4F5665;
                    font-weight: 500;
                    font-size: 16px;
                    @media (max-width: 1550px) {
                        font-size: 14px; } }
                .plan-detail-inner {
                    .plan-name {
                        justify-content: flex-start;
                        & span.businessPlan, &.businessPlan {
                            margin: 0;
                            justify-content: center !important; } }
                    ul {
                        li {
                            background-image: url('images/checklist.svg');
                            background-position: 0 5px;
                            padding: 0 0 0 34px;
                            margin-bottom: 9px;
                            font-size: 16px;
                            letter-spacing: 0;
                            @media (max-width: 1550px) {
                                font-size: 13px; }
                            &:last-chlid {
                                margin-bottom: 0 !important; } } }
                    .plan-feature {
                        margin-top: 20px;
                        margin-bottom: 0; } }
                .annual-box {
                    display: none; } } }
        .publisher-pricing-detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media (max-width: $breakpoint-mobile) {
                text-align: center; }
            h3 {
                font-size: 48px;
                font-weight: 700;
                line-height: 50px;
                color: $black-color;
                margin-bottom: 15px;
                @media (max-width: 1550px) {
                    font-size: 40px;
                    margin-bottom: 5px; }
                @media (max-width: $breakpoint-ipad-pro) {
                    font-size: 30px;
                    margin-bottom: 5px; }
                @media (max-width: $breakpoint-ipad) {
                    font-size: 25px;
                    line-height: 1.3; } }
            h6 {
                font-weight: 500;
                font-size: 30px;
                line-height: 40px;
                color: $black-color;
                margin-bottom: 34px;
                @media (max-width: 1550px) {
                    font-size: 25px;
                    margin-bottom: 25px; }
                @media (max-width: $breakpoint-ipad-pro) {
                    font-size: 20px;
                    margin-bottom: 15px; }
                @media (max-width: $breakpoint-ipad) {
                    font-size: 16px;
                    line-height: 1.3; } }
            .publisher-pricing-action {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-width: 312px;
                width: 100%;
                span {
                    font-weight: 600;
                    font-size: 18px;
                    letter-spacing: 0.18px;
                    color: $black-color;
                    margin-top: 10px; } } } } }
