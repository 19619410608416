@import '../../variables.sass';
.publisher-whta-included {
    padding-top: 117px;
    position: relative;
    @media (max-width: $breakpoint-ipad-pro) {
        padding-top: 60px; }
    @media (max-width: $breakpoint-ipad) {
        padding-top: 30px; }
    .publisher-whta-included-inner {
        position: relative;
        .container {
            position: unset; }
        .publisher-whta-included-row {
            display: flex;
            align-items: center;
            position: unset;
            @media (max-width: $breakpoint-ipad) {
                flex-direction: column; }
            &:before {
                content: "";
                position: absolute;
                background: #F1EEFF;
                left: 0;
                bottom: 0;
                top: 0;
                right: 61%;
                border-top-right-radius: 100px;
                border-bottom-right-radius: 100px;
                @media (max-width: $breakpoint-ipad) {
                    display: none; } } } }
    .home-heading {
        h2 {
            margin-bottom: 60px;
            @media (max-width: $breakpoint-ipad) {
                margin-bottom: 10px; } } }
    .whta-included-left {
        //margin-left: 6.4%
        width: 52.4%;
        position: relative;
        @media (max-width: $breakpoint-ipad-pro) {
            width: 50%; }
        @media (max-width: $breakpoint-ipad) {
            width: 100%; } }
    .whta-included-img {
        position: {}
        //padding: 27px 0 15px
        @media (max-width: $breakpoint-ipad-pro) {
            padding: 0; } }
    .personImg {
        position: relative;
        z-index: 8;
        max-width: 100%;
        width: 100%;
        border-radius: 30px; }
    .whta-included-img-vector {
        @media (max-width: $breakpoint-ipad-pro) {
            display: none; }
        .vector1,
        .vector2,
        .vector3,
        .vector4 {
            position: absolute; }
        .vector1 {
            top: -13px;
            right: 112px;
            @media (max-width: 1550px) {
                top: -13px;
                right: 77px; } }
        .vector2 {
            right: -40px;
            top: 40px;
            opacity: 0; }
        .vector3 {
            left: -25px;
            top: 112px;
            @media (max-width: 1550px) {
                left: -5px;
                top: 72px;
                svg {
                    width: 150px; } } }
        .vector4 {
            bottom: 26px;
            right: 184px;
            @media (max-width: 1550px) {
                bottom: -40px;
                right: 157px;
                svg {
                    width: 150px; } } } }
    .customTab {
        @media (max-width: $breakpoint-mobile) {
            margin-top: 25px; }
        .tab-pane {
            max-width: 396px;
            width: 100%;
            @media (max-width: 1550px) {
                max-width: 345px; }
            h4 {
                font-size: 30px;
                line-height: 36px;
                font-weight: 700;
                color: $black-color;
                margin-bottom: 26px;
                text-transform: uppercase;
                @media (max-width: 1550px) {
                    font-size: 25px;
                    line-height: 1.3; }
                @media (max-width: $breakpoint-ipad) {
                    font-size: 18px;
                    margin: 0 0 10px; } }
            p {
                font-size: 18px;
                line-height: 28px;
                font-weight: 400;
                color: #12111b;
                opacity: 0.8;
                margin: 0;
                letter-spacing: 0;
                @media (max-width: 1550px) {
                    font-size: 16px;
                    line-height: 1.3; } } } }
    .whta-included-right {
        width: 47%;
        padding-left: 6px;
        @media (max-width: $breakpoint-ipad-pro) {
            width: 50%; }
        @media (max-width: $breakpoint-ipad) {
            width: 100%; }
        .row {
            align-items: center; }
        .nav-pills {
            @media (max-width: $breakpoint-ipad) {
                flex-direction: row !important;
                justify-content: center; }
            .nav-item {
                margin-bottom: 30px;
                text-align: right;
                @media (max-width: $breakpoint-ipad) {
                    margin-top: 10px;
                    margin-bottom: 10px; }
                .nav-link {
                    //text-align: center
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    color: #bbb;
                    &.active {
                        background-color: transparent;
                        color: $orange-secondary-color;
                        font-weight: 900; } } } } } }
