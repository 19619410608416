@import '../../components/variables.sass';
.assesment-header {
    .assesment-btns {
        text-align: right;
        @media (max-width: $breakpoint-ipad-pro) {
            display: none; }
        .step-btns {
            display: inline-block;
            vertical-align: middle;
            margin-left: 15px;
            &:first-child {
                margin-left: 0; } } } }
.template-steppers {
    width: 100%;
    display: inline-block;
    background-color: #F1F5F8;
    .container {
        max-width: 1063px !important;
        @media (max-width: $breakpoint-ipad) {
            padding: 0; } }
    .main-header {
        .container {
            max-width: 1063px !important;
            @media (max-width: $breakpoint-ipad) {
                padding: 0 15px; } } } }
.landing-color-stepper {
    &.template-steppers {
        background-color: transparent;
        .template-stepper-wrap {
            padding: 0;
            margin: 0;
            background-color: transparent;
            min-height: 0;
            .stepper-title {
                display: none; } }
        .template-color-preview {
            .wizrd-blog-preview {
                width: 100%; } }
        .template-step-action {
            display: none;
            @media (max-width: $breakpoint-ipad) {
                display: inline-block; } } } }
.wizrd-inner-preview {
    filter: drop-shadow(4px 4px 80px rgba(0, 0, 0, 0.15)); }
.font-color-selector {
    width: 100%;
    display: inline-block;
    text-align: center;
    label {
        display: inline-block;
        vertical-align: middle;
        color: #12111B;
        font-size: 12px;
        line-height: 1.5;
        font-weight: 600;
        opacity: 0.7;
        letter-spacing: -0.5px;
        margin: 0 7px 0 0; }
    .font-color-switcher {
        display: inline-block;
        vertical-align: middle;
        background-color: #fff;
        border-radius: 100px;
        padding: 8px;
        line-height: 0;
        .custom-radio {
            display: inline-block;
            width: 22.21px;
            height: 22.21px;
            border-radius: 100%;
            margin-left: 8px;
            cursor: pointer;
            &:first-child {
                margin-left: 0; }
            &.white-color-font {
                background-color: #fff;
                border: 2px solid rgba(0, 0, 0, 0.2);
                &.active {
                    border-color: #3032C8; } }
            &.black-color-font {
                background-color: #000;
                border: 2px solid #000;
                &.active {
                    border-color: #3032C8; } }
            label {
                margin: 0;
                display: none; }
            .radio-item {
                input {
                    width: 22.21px;
                    height: 22.21px;
                    opacity: 0;
                    cursor: pointer; } } } } }
.uploaded-img-preview {
    width: 100%;
    height: 156px;
    display: inline-block;
    background-color: #F3F1FF;
    //border: 1px dashed rgba(85, 64, 191, 0.5)
    background-image: url('/images/upload-logo-border.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media (max-width: $breakpoint-ipad) {
        height: auto;
        background-image: none;
        background-color: transparent;
        justify-content: flex-start;
        flex-direction: column;
        text-align: left;
        align-items: flex-start; }
    .uploaded-cover-img-preview {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        span {
            color: #ff0000;
            font-size: 14px;
            text-transform: capitalize;
            display: block;
            font-weight: 700;
            font-size: 16px;
            cursor: pointer; } }
    img {
        border-radius: 15px;
        border: 3px solid rgba(85, 64, 191, 1);
        margin: 0 0 5px;
        // width: 100%
        // height: 100%
        //object-fit: cover
        // position: absolute
        // left: -1px
        // right: -1px
        // height: calc(100% + 2px)
        // max-width: calc(100% + 2px)
        // width: calc(100% + 2px)
        @media (max-width: $breakpoint-ipad) {
            position: relative;
            //margin: 0 0 15px
            height: auto; }
        // top: -1px
        // bottom: -1px
        & + button {
            background-color: transparent;
            color: #5540BF;
            z-index: 2;
            .uploaded-img-text {
                color: #5540BF;
                @media (max-width: $breakpoint-ipad) {
                    color: #fff; } }
            @media (max-width: $breakpoint-ipad) {
                background-color: #5540BF;
                color: #fff; }
            svg {
                rect {
                    fill: #5540BF;
                    @media (max-width: $breakpoint-ipad) {
                        fill: #fff; } } } } }
    button {
        background-color: #5540BF;
        border: 2px solid #5540BF;
        border-radius: 1000px;
        padding: 11px 31px;
        @media (max-width: $breakpoint-ipad) {
            width: 100%; }
        .uploaded-img-text {
            color: #fff;
            font-weight: 500;
            padding: 1px 0 0 8px; } }
    &.uploaded {
        height: auto;
        background: none;
        button {
            display: none; } } }
.template-stepper-wrap {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    margin: 8px 0 -66px;
    min-height: calc(100vh - 164px);
    position: relative;
    @media (max-width: $breakpoint-ipad) {
        border-radius: 0;
        margin: 0;
        padding: 30px 0; }
    .stepper-title {
        margin: 0;
        font-size: 22px;
        line-height: 1.3;
        color: #212121;
        letter-spacing: 0.18px;
        font-weight: bold;
        text-align: center;
        @media (max-width: $breakpoint-ipad) {
            padding: 0 15px; }
        @media (max-width: $breakpoint-mobile) {
            font-size: 18px; } }
    .stepper-detail {
        text-align: center;
        font-size: 14px;
        line-height: 1.3;
        color: #212121;
        letter-spacing: 0.18px;
        padding: 0 15px;
        margin: 8px 0 0;
        @media (max-width: $breakpoint-mobile) {
            font-size: 12px; } } }
.template-color-selector {
    width: 100%;
    display: inline-block;
    margin: 30px 0 0;
    @media (max-width: $breakpoint-ipad) {
        display: flex;
        flex-direction: column; }
    .template-color-inputs {
        width: 100%;
        display: inline-block;
        margin: 15px 0 12px;
        @media (max-width: $breakpoint-ipad) {
            order: 2;
            margin: 0;
            overflow: hidden; }
        .form-group {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            margin: 0;
            @media (max-width: $breakpoint-ipad) {
                display: inline-block; }
            .field_error {
                font-size: 12px;
                line-height: 1;
                color: #FF0000; }
            .styled-radio-btn {
                margin: 0;
                display: inline-block !important;
                width: auto !important;
                @media (max-width: $breakpoint-ipad) {
                    width: 20% !important;
                    margin-bottom: 18px !important; }
                &:nth-child(6),
                &:nth-child(7),
                &:nth-child(8),
                &:nth-child(9),
                &:nth-child(10) {
                    @media (max-width: $breakpoint-ipad) {
                        margin-bottom: 0 !important; } }
                &:first-child {
                    margin-left: 0; }
                &:hover {
                    .input-radio-label {
                        background-image: none;
                        background-color: transparent;
                        box-shadow: none;
                        border-color: #3032C8; } }
                .input-radio-label {
                    background-image: none;
                    background-color: transparent;
                    border: 2px solid transparent;
                    padding: 0;
                    border-radius: 100%;
                    padding: 3.5px;
                    line-height: 0;
                    box-shadow: none;
                    position: relative;
                    @media (max-width: $breakpoint-ipad) {
                        width: 58px;
                        height: 58px;
                        line-height: 44px;
                        border: 1px solid rgba(0,0,0,.1);
                        border-radius: 5px; }
                    @media (max-width: $breakpoint-mobile) {
                        width: 50px;
                        height: 50px;
                        line-height: 39px; }
                    .emojiText {
                        display: none;
                        margin: 0 !important;
                        font-size: 14px;
                        line-height: 1.3;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.7);
                        letter-spacing: -0.2px; }
                    &.custom-color-input {
                        .emojiText {
                            display: inline-block;
                            position: absolute;
                            width: auto;
                            word-break: unset;
                            left: 50%;
                            transform: translateX(-50%);
                            text-align: center;
                            white-space: nowrap;
                            bottom: -20px;
                            @media (max-width: $breakpoint-ipad) {
                                display: none; } } }
                    .checkbox-colors {
                        padding: 0;
                        margin: 0;
                        line-height: 0;
                        width: 40px;
                        @media (max-width: $breakpoint-ipad) {
                            width: 30px; }
                        span {
                            width: 40px;
                            height: 40px;
                            @media (max-width: $breakpoint-ipad) {
                                width: 30px;
                                height: 30px; } }
                        img {
                            max-width: 100%; } } }
                .styled-radio {
                    &:checked {
                        & + .input-radio-label {
                            background-image: none;
                            background-color: #EAEBF9;
                            box-shadow: none;
                            border-color: #3032C8; } }
                    & + .selected {
                        &.input-radio-label {
                            background-image: none;
                            background-color: #EAEBF9;
                            box-shadow: none;
                            border-color: #3032C8; } } } } } } }
.template-color-preview {
    text-align: center;
    width: 100%;
    display: inline-block;
    @media (max-width: $breakpoint-ipad) {
        order: 1; }
    .wizrd-blog-preview {
        display: inline-block;
        width: 600px;
        @media (max-width: $breakpoint-ipad) {
            width: 100%; } } }
.template-blog-logo {
    width: 100%;
    display: inline-block;
    margin: 40px 0 0;
    @media (max-width: $breakpoint-ipad) {
        display: flex;
        flex-direction: column;
        margin: 30px 0 0; }
    .template-logo-preview {
        width: 558px;
        max-width: 100%;
        margin-left: 62px;
        display: inline-block;
        vertical-align: top;
        margin-top: 9px;
        @media (max-width: $breakpoint-ipad) {
            order: 1;
            width: 100%;
            margin: 0 0 15px; }
        .wizrd-blog-preview {
            .wizrd-blog-theme {
                .wizrd-site-main {
                    .wizrd-home-banner {
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                        padding: 15px 0 10px;
                        @media (max-width: $breakpoint-ipad) {
                            border-bottom-right-radius: 0;
                            border-bottom-left-radius: 0; }
                        .wizrd-banner-content {
                            .wizrd-description {
                                margin: 0 0 10px; } } } } } } }
    .template-logo-upload {
        width: 45%;
        width: calc(100% - 620px);
        display: inline-block;
        vertical-align: top;
        @media (max-width: $breakpoint-ipad) {
            order: 2;
            width: 100%;
            padding: 0 15px; } } }
.logo-upload-instruction {
    width: 207px;
    max-width: 100%;
    display: inline-block;
    margin: 33px 0 0;
    @media (max-width: $breakpoint-ipad) {
        margin: 0;
        width: 100%; }
    p {
        font-size: 14px;
        line-height: 1.5;
        color: #1B1B1B;
        margin: 0 0 10px;
        &.size-instruction {
            font-size: 12px;
            line-height: 1.2;
            color: #212121;
            &:last-child {
                @media (max-width: $breakpoint-ipad) {
                    margin-bottom: 0; } } } }
    .uploaded-logo-preview {
        margin: 0 0 10px; } }

.template-header-edit {
    width: 100%;
    display: inline-block;
    margin: 28px 0 0;
    @media (max-width: $breakpoint-ipad) {
        padding: 0 15px; }
    .template-header-hero {
        display: inline-block;
        vertical-align: top;
        width: 204px;
        margin-left: 30px;
        background-color: #F3F1FF;
        border-radius: 10px;
        padding: 12px 15px 7px;
        @media (max-width: $breakpoint-ipad) {
            margin-left: 0;
            margin-top: 0;
            width: 100%; }
        .header-img-instruction {
            font-size: 13px;
            line-height: 16px;
            font-weight: 600;
            letter-spacing: 0.01em;
            text-align: center;
            color: #1b1b1b;
            span {
                color: #3032C8; } }
        .uploaded-header-img-preview {
            margin: 11px 0 8px;
            .uploaded-img-preview {
                height: 88px;
                background-image: url('/images/upload-hero-border.png');
                @media (max-width: $breakpoint-ipad) {
                    height: auto;
                    background-image: none;
                    justify-content: center;
                    align-items: center; }
                &.uploaded {
                    height: auto;
                    background: none; } } } }
    .template-header-text {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
        width: calc(100% - 234px);
        @media (max-width: $breakpoint-mobile) {
            width: 100%; } } }
.assesment-color-stepper {
    .template-color-selector {
        .template-color-inputs {
            margin: 0 0 25px;
            .form-group {
                .styled-radio-btn {
                    .input-radio-label {
                        padding: 2.5px;
                        @media (max-width: $breakpoint-ipad) {
                            padding: 0;
                            line-height: 47px; }
                        .checkbox-colors {
                            width: 21.19px;
                            @media (max-width: $breakpoint-ipad) {
                                width: 30px; }
                            span {
                                width: 21.19px;
                                height: 21.19px;
                                @media (max-width: $breakpoint-ipad) {
                                    width: 30px;
                                    height: 30px; } } }
                        &.custom-color-input {
                            .emojiText {
                                font-size: 12px;
                                bottom: -16px; } } } } } } } }
.modal {
    &.image-upload-modal {
        .modal-dialog {
            width: 712px; }
        .modal-content {
            .modal-body {
                padding: 0 10px; }
            .modal-btns {
                margin: 24px 0 0 !important;
                @media (max-width: $breakpoint-ipad) {
                    margin: 15px 0 50px !important; } } } } }
.modal {
    &.new-modal {
        .modal-content {
            background-color: #F3F3F3;
            .modal-header {
                background-color: #5540BF;
                width: 100%;
                display: inline-block;
                @media (max-width: $breakpoint-ipad) {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    padding: 20px 15px; }
                @media (max-width: $breakpoint-mobile) {
                    padding: 15px 10px; }
                .new-modal-header {
                    .modal-title {
                        color: #fff;
                        font-size: 20px;
                        line-height: 29px;
                        font-weight: 600;
                        margin: 0;
                        width: 100%;
                        display: inline-block;
                        text-align: left;
                        @media (max-width: $breakpoint-ipad) {
                            font-size: 16px; } } }
                .close {
                    background-image: url('/images/white-close-icon.png');
                    width: 21.23px;
                    height: 21.23px;
                    position: absolute;
                    top: 19px;
                    right: 30px;
                    @media (max-width: $breakpoint-ipad) {
                        top: 24px;
                        right: 10px; }
                    @media (max-width: $breakpoint-mobile) {
                        top: 29px; } } } }
        .modal-btns {
            text-align: center;
            @media (max-width: $breakpoint-mobile) {
                padding: 0 0 50px; }
            .btn.btn-primary-2,
            .btn.btn-secondary-2 {
                padding: 12px 30px; } } } }
.image-upload-modal-search {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    padding: 0 37px;
    @media (max-width: $breakpoint-ipad) {
        padding: 0;
        flex-direction: column; }
    .powered-by-unsplash {
        font-size: 14px;
        line-height: 1.4;
        @media (max-width: $breakpoint-ipad) {
            font-size: 12px;
            margin-top: 5px; }
        a {
            color: #000000;
            opacity: 0.5; } }
    .form-group {
        margin: 0;
        width: 287px;
        @media (max-width: $breakpoint-ipad) {
            width: 100%; }
        .form-control {
            background-image: url('/images/image-upload-search.png');
            background-repeat: no-repeat;
            background-position: 14px center;
            font-size: 14px;
            line-height: 1.4;
            color: #000000;
            padding: 11px 15px 11px 40px;
            &::placeholder {
                color: #000000;
                opacity: 0.8; } } } }

.image-upload-modal-unsplash-gallery {
    padding: 0 37px;
    max-height: calc(100vh - 415px);
    overflow: auto;
    @media (max-width: $breakpoint-ipad) {
        max-height: calc(100vh - 314px); }
    @media (max-width: $breakpoint-ipad) {
        padding: 0 0; }
    .unsplash-gallery {
        columns: 4 140px;
        column-gap: 1rem;
        @media (max-width: $breakpoint-ipad) {
            columns: 2 70px; }
        & > div {
            color: white;
            margin: 0 17.85px 17.85px 0;
            display: inline-block;
            width: 100%;
            @media (max-width: $breakpoint-ipad) {
                margin: 0 10px 10px 0; }
            img {
                border-radius: 10px;
                cursor: pointer; } } } }
.image-upload-modal-form {
    background: #f7f7f7;
    width: 100%;
    width: calc(100% + 20px);
    margin-left: -10px;
    display: inline-block;
    text-align: center;
    padding: 24px 45px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.3);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    @media (max-width: $breakpoint-ipad) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 15px; }
    @media (max-width: $breakpoint-mobile) {
        padding: 15px 15px 100px; } }
.image-upload-modal-select {
    width: 100%;
    max-width: 100%;
    height: 108px;
    //border: 1px dashed rgba(85, 64, 191, 0.5)
    background-image: url('/images/upload-modal-border.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    border-radius: 15px;
    margin: 0;
    // display: flex
    // justify-content: center
    // align-items: center
    position: relative;
    @media (max-width: $breakpoint-ipad) {
        background: none;
        height: auto;
        padding: 0;
        display: inline-block;
        width: 100%; }
    &.uploaded {
        background: none;
        height: auto;
        .upload-file {
            display: none; } }
    .preview-logo {
        width: 100%;
        display: inline-block;
        img {
            border: 2px solid $primary-color;
            border-radius: 15px;
            max-height: 200px;
            margin: 0 0 5px; }
        .clear-logo {
            color: #ff0000;
            font-size: 14px;
            text-transform: capitalize;
            display: block;
            font-weight: 700;
            font-size: 16px;
            cursor: pointer; } }
    .avatar-user {
        height: 100%; }
    .upload-file {
        margin: 0;
        height: 100%;
        &::after {
            display: none; }
        p {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            @media (max-width: $breakpoint-ipad) {
                display: block; } } }
    p {
        width: auto;
        padding: 0;
        @media (max-width: $breakpoint-ipad) {
            margin: 0; }
        a {
            font-size: 14px;
            line-height: 17px;
            color: #5540BF;
            font-weight: 600 !important;
            padding: 11px 22px;
            border: 1px solid #5540BF;
            border-radius: 100px;
            position: relative;
            display: inline-block;
            width: 100%; } }
    .drag-image-box {
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 0;
        border: none;
        background-color: transparent;
        margin: 5px 0 0;
        @media (max-width: $breakpoint-ipad) {
            display: none; }
        p {
            font-size: 12px;
            line-height: 1.5;
            color: #1B1B1B;
            font-weight: 500; } } }
.modal {
    &.custom-color-modal {
        .modal-dialog {
            width: 1155px;
            .modal-body {
                padding: 40px 90px 40px;
                @media (max-width: $breakpoint-ipad-pro) {
                    padding: 25px; }
                @media (max-width: $breakpoint-ipad) {
                    padding: 25px 0; } } } } }
.device-selection {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 0 0 32px;
    position: relative;
    @media (max-width: $breakpoint-ipad) {
        margin: 0 0 25px; }
    .back-to-home {
        position: absolute;
        left: 0;
        top: 15px; }
    .preview-switch {
        width: auto;
        display: inline-block;
        .preview-switcher-wrap {
            width: auto;
            display: inline-block;
            border: 1px solid rgba(0, 0, 0, 0.2);
            background-color: #fff;
            padding: 7px;
            border-radius: 100px;
            @media (max-width: $breakpoint-mobile) {
                padding: 5px; }
            .preview-switcher {
                width: auto;
                display: inline-block;
                font-size: 14px;
                line-height: 1.4;
                font-weight: 500;
                color: #000;
                opacity: 0.5;
                padding: 9px 10px;
                min-width: 139px;
                text-align: center;
                border-radius: 100px;
                @media (max-width: $breakpoint-mobile) {
                    font-size: 12px;
                    min-width: 90px;
                    padding: 7px 10px; }
                &.active {
                    background-color: #000;
                    color: #fff;
                    opacity: 1; }
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    outline: none; } } } } }
.custom-color-selection {
    width: 100%;
    display: inline-block;
    margin: 0 0 40px;
    @media (max-width: $breakpoint-ipad) {
        display: flex;
        flex-direction: column; }
    .custom-color-template-preview {
        width: 691px;
        display: inline-block;
        vertical-align: top;
        margin-right: 30px;
        @media (max-width: $breakpoint-ipad-pro) {
            width: 636px; }
        @media (max-width: $breakpoint-ipad) {
            width: 100%;
            margin: 0 0 30px; } }
    .custom-color-template-selection {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        width: calc(100% - 721px);
        @media (max-width: $breakpoint-ipad-pro) {
            width: calc(100% - 671px); }
        @media (max-width: $breakpoint-ipad) {
            width: 100%;
            padding: 0 15px;
            text-align: center; } } }
.header-color-selectors {
    width: 100%;
    display: inline-block;
    margin: 10px 0 0; }
.color-selector-group {
    width: 100%;
    display: inline-block;
    margin: 0 0 10px;
    &:last-child {
        margin: 0; }
    label {
        width: 100%;
        display: inline-block;
        font-size: 12px;
        line-height: 1.5;
        font-weight: 600;
        color: #12111B;
        opacity: 0.7;
        margin: 0 0 5px;
        span {
            display: inline-block;
            vertical-align: middle;
            &:first-child {
                width: 135px;
                margin-right: 10px; } } }
    .header-color-selector {
        width: 100%;
        display: inline-block;
        .color-box-view {
            display: inline-block;
            vertical-align: middle;
            width: 135px;
            margin-right: 10px;
            border-radius: 100px;
            padding: 8px;
            background-color: #fff;
            border: 2px solid #fff;
            &.active {
                border-color: #3032C8; }
            .form-control {
                width: 70%;
                width: calc(100% - 40px);
                padding: 0;
                display: inline-block;
                vertical-align: middle;
                border: none;
                background-color: transparent;
                height: auto;
                &:focus {
                    box-shadow: none; } }
            .color-selector-preview {
                width: 27.2px;
                height: 27.2px;
                border-radius: 100%;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px; } }
        .font-color-selector {
            display: inline-block;
            vertical-align: middle;
            width: auto; } } }
.copyright-footer {
    background-color: #2D245B;
    padding: 92px 0 26px;
    text-align: center;
    width: 100%;
    display: inline-block;
    @media (max-width: $breakpoint-ipad) {
        padding: 25px 0; } }
body {
    .mobile-version {
        margin: 0 auto;
        //background-image: url('/images/wizrd-iphone.png')
        background-repeat: no-repeat;
        background-position: 0 0;
        width: 363px !important;
        padding: 14px 0 0 17px;
        height: 674px;
        @media (max-width: $breakpoint-mobile) {
            width: 100% !important;
            padding: 0;
            height: auto; }
        .mobile-img {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            @media (max-width: $breakpoint-mobile) {
                display: none; } }
        .wizrd-blog-theme {
            border-radius: 30px;
            height: 645px;
            width: 304px;
            @media (max-width: $breakpoint-mobile) {
                width: 100%;
                height: auto;
                border-radius: 0; }
            .wizrd-site-header {
                border-top-left-radius: 30px;
                border-top-right-radius: 30px;
                @media (max-width: $breakpoint-mobile) {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0; } } } } }
.wizrd-blog-preview {
    &.wizrd-inner-preview {
        .wizrd-blog-theme {
            .wizrd-site-header {
                @media (max-width: $breakpoint-ipad) {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0; } } } } }
.template-step-action {
    width: 100%;
    margin: 15px 0 0;
    padding-right: 15px;
    display: none;
    @media (max-width: $breakpoint-ipad-pro) {
        display: inline-block; }
    .step-btns {
        display: flex;
        justify-content: space-between;
        .step-btn-inner {
            width: 100%;
            padding-left: 15px;
            .step-btn {
                width: 100%;
                .btn {
                    min-width: 0;
                    width: 100%; } } } } }
.template-form-group {
    .form-group {
        margin: 0 0 15px;
        text-align: left;
        .form-group {
            margin: 0; }
        .small-loader {
            right: -5px;
            top: 0; }
        .domain-available-status {
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            position: absolute;
            top: 39px;
            right: 15px;
            color: #1b1b1b;
            padding: 5px 8px 5px 5px;
            border-radius: 5px;
            @media (max-width: $breakpoint-ipad) {
                top: 32px; }
            svg {
                margin-right: 3px; }
            &.success {
                background-color: #EBF7EE; }
            &.failed {
                background-color: #FCEDEA; } }
        .available-domain-name {
            width: 100%;
            display: inline-block;
            font-size: 14px;
            line-height: 1.5;
            color: #737373;
            margin: 5px 0 0;
            a {
                color: #3032C8;
                text-decoration: underline;
                font-weight: 600; } }
        label {
            font-size: 14px;
            line-height: 1.5;
            font-weight: bold;
            color: #1B1B1B;
            margin: 0 0 5px;
            text-align: left; }
        .form-control {
            border: 1px solid rgba(27,27,27,.2);
            font-size: 14px;
            line-height: 1.5;
            color: #1B1B1B;
            letter-spacing: 0.5px;
            padding: 14px 19px 13px;
            font-weight: 500;
            &::placeholder {
                color: #1B1B1B;
                opacity: 0.3; } } } }

.modal {
    &.custom-color-modal {
        &.header-edit-modal {
            .modal-dialog {
                .modal-body {
                    padding: 40px 40px 45px;
                    @media (max-width: $breakpoint-ipad) {
                        padding: 25px 0; } } }
            .font-color-selector {
                text-align: left; } } } }
.edit-site-hero-upload {
    margin: 0 0 30px;
    width: 100%;
    display: inline-block;
    @media (max-width: $breakpoint-ipad) {
        padding: 0 15px; }
    h5 {
        font-size: 20px;
        color: #1B1B1B;
        margin: 0 0 10px;
        font-weight: bold; }
    .hero-bgcolor-img {
        @media (max-width: $breakpoint-ipad) {
            text-align: left;
            flex-direction: column; }
        .hero-img {
            width: 275px;
            position: relative;
            padding-right: 30px;
            margin-right: 30px;
            display: inline-block;
            vertical-align: top;
            @media (max-width: $breakpoint-ipad) {
                width: 100%;
                padding-right: 0;
                margin-right: 0; }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 1px;
                background-color: #000;
                @media (max-width: $breakpoint-ipad) {
                    display: none; } }
            .image-upload-modal-select {
                align-items: flex-end;
                .upload-file {
                    margin-bottom: -40px;
                    a {
                        border: none;
                        svg {
                            margin: 0 5px 1px 0; } } } } }
        .hero-bgcolor {
            display: inline-block;
            vertical-align: top;
            @media (max-width: $breakpoint-ipad) {
                margin-top: 20px;
                width: 100%; }
            .color-selector-group {
                width: auto;
                margin: 16px 0 0;
                display: inline-block;
                vertical-align: top;
                @media (max-width: $breakpoint-ipad) {
                    margin: 0 0 15px; }
                label {
                    width: auto; }
                .header-color-selector {
                    width: auto;
                    display: block; }
                .color-box-view {
                    margin-right: 30px; } }
            .webfin-color-selector {
                width: 250px;
                display: inline-block;
                vertical-align: top; } } } }
.color-palatte {
    width: 100%;
    display: inline-block;
    .color-palatte-selector {
        width: 100%;
        display: inline-block;
        .form-group {
            width: 100%;
            display: inline-block;
            margin: 0;
            .styled-radio-btn {
                margin: 0;
                margin-left: 2%;
                margin-bottom: 2%;
                width: 32%;
                display: inline-block;
                vertical-align: top;
                @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                    margin: 0 0 10px; }
                &:nth-child(3n+1) {
                    margin-left: 0; }
                .input-radio-label {
                    box-shadow: 0 0 0 1px #F3F3F3;
                    padding: 10px 18px;
                    background-image: none;
                    & > div {
                        display: inline-block;
                        vertical-align: middle; }
                    .checkbox-colors {
                        margin: 0;
                        padding: 0;
                        line-height: 0; } }
                .styled-radio {
                    &:checked {
                        & + .input-radio-label {
                            box-shadow: 0 0 0 1px #3032c8; } } } } } } }
.site-icon-selector-row {
    margin: 0 0 30px;
    .wizrd-blog-preview {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        @media (max-width: $breakpoint-ipad) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0; }
        .wizrd-blog-theme {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            @media (max-width: $breakpoint-ipad) {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0; }
            .wizrd-site-main {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                @media (max-width: $breakpoint-ipad) {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0; }
                .wizrd-home-banner {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    @media (max-width: $breakpoint-ipad) {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0; } } } } } }
.slick-arrow {
    transform: none;
    top: auto;
    &::before {
        display: none; } }
.template-color-inputs {
    padding-right: 11px;
    @media (max-width: $breakpoint-ipad) {
        padding: 30px 0 0; } }
.landing-color-stepper {
    .slick-arrow {
        bottom: 28px; }
    .template-color-inputs {
        margin-top: -65px;
        margin-bottom: 20px;
        @media (max-width: $breakpoint-ipad) {
            margin-top: 0;
            padding-top: 30px; }
        .form-group {
            @media (max-width: $breakpoint-ipad) {
                padding: 0 10px; } }
        .styled-radio-btn {
            margin-top: 83px !important;
            margin-bottom: 12px !important;
            @media (max-width: $breakpoint-ipad-pro) {
                margin-top: 75px !important; }
            @media (max-width: $breakpoint-ipad) {
                margin-top: 0 !important;
                margin-bottom: 0 !important; } } } }

.assesment-color-stepper {
    .slick-arrow {
        bottom: 17px;
        svg {
            width: 10px;
            height: 15px; } }
    .template-color-selector {
        margin-top: 25px; }
    .template-color-inputs {
        text-align: center;
        margin: 0 0 9px !important;
        padding: 0;
        .form-group {
            padding: 0 25px;
            width: 600px;
            max-width: 100%;
            margin: 0 auto;
            @media (max-width: $breakpoint-ipad) {
                padding: 30px 0 0; }
            .styled-radio-btn {
                margin-bottom: 16px !important; } } } }
.wizrd-blog-preview {
    .wizrd-blog-theme {
        .wizrd-site-main {
            .wizrd-home-banner {
                .wizrd-banner-content {
                    h1 {
                        span {
                            padding: 0; } } } }
            .wizrd-recent-blogs {
                .tabs {
                    .nav-tabs {
                        line-height: 0; } } } } } }
.landing-color-pallate {
    .wizrd-blog-preview {
        filter: drop-shadow(4px 4px 80px rgba(0, 0, 0, 0.15)); } }
.modal {
    &.new-modal {
        &.custom-color-modal {
            .modal-btns {
                @media (max-width: $breakpoint-mobile) {
                    padding: 0 15px 100px; } } } } }
.modal {
    &.social-connect-modal {
        .modal-btns {
            @media (max-width: $breakpoint-mobile) {
                padding: 0 0 50px; } }
        .logo-upload-header {
            @media (max-width: $breakpoint-mobile) {
                width: 100%; }
            .modal-title {
                @media (max-width: $breakpoint-mobile) {
                    text-align: left !important;
                    margin: 0 !important; } } } } }
.wizrd-payment {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 12px 0 0;
    .payment-button {
        width: 100%;
        display: inline-block;
        margin: 0 0 15px;
        .payment-btn-group {
            width: 120px;
            display: inline-block;
            cursor: pointer;
            a {
                font-size: 14px;
                line-height: 1;
                font-weight: 700;
                width: 100%;
                display: inline-block;
                text-transform: capitalize;
                border-radius: 10px 10px 0px 0px;
                // background-color: var(--button-font)
                // color: var(--button)
                background-color: #fff;
                color: #000;
                padding: 5px;
                @media (max-width: $breakpoint-mobile) {
                    font-size: 12px; }
                &:last-child {
                    border-radius: 0 0 10px 10px;
                    // background-color: var(--button)
                    // color: var(--button-font)
                    background-color: #000;
                    color: #fff; } }
            &:hover {
                a {
                    // background-color: var(--button)
                    // color: var(--button-font)
                    background-color: #000;
                    color: #fff;
                    &:last-child {
                        // background-color: var(--button-font)
                        // color: var(--button)
                        background-color: #fff;
                        color: #000; } } } } }
    .payment-option {
        display: flex;
        justify-content: center;
        align-items: center;
        h6 {
            text-transform: capitalize;
            font-size: 14px;
            line-height: 1;
            font-weight: 700;
            margin: 0;
            color: var(--header-color);
            @media (max-width: $breakpoint-mobile) {
                font-size: 12px; } }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                display: inline-block;
                margin-left: 2px;
                img {
                    max-width: 90px;
                    @media (max-width: $breakpoint-mobile) {
                        max-width: 24px; } }
                &:last-child {
                    @media (max-width: $breakpoint-mobile) {
                        display: none; } } } } } }
.wizrd-iframe {
    .widgetWrap {
        height: auto; }
    iframe {
        height: 250px !important;
        @media (max-width: $breakpoint-mobile) {
            height: 700px !important; } } }
body {
    .mobile-version {
        .wizrd-payment {
            .payment-option {
                display: inline-block;
                h6 {
                    margin: 0 0 10px;
                    font-size: 12px; }
                ul {
                    li {
                        &:last-child {
                            display: none; } } } }
            .payment-button {
                .payment-btn-group {
                    a {
                        font-size: 12px; } } } } } }
body {
    &.wizrd-travel-template {
        .wizrd-blog-preview {
            .wizrd-blog-theme {
                .wizrd-site-main {
                    .wizrd-home-banner {
                        .container {
                            @media (max-width: $breakpoint-mobile) {
                                max-width: 100% !important;
                                padding: 0 15px !important; } } } } }
            &.mobile-version {
                .wizrd-blog-theme {
                    .wizrd-site-main {
                        .wizrd-home-banner {
                            .container {
                                max-width: 100% !important;
                                padding: 0 15px !important; } } }
                    .index-section {
                        padding: 20px 15px;
                        @media (max-width: $breakpoint-mobile) {
                            display: none; }
                        .container {
                            padding: 0 !important; }
                        &.newsletter {
                            h3 {
                                font-size: 16px;
                                margin: 0 0 10px; }
                            .newsletter--form {
                                .form-group {
                                    .form-control {
                                        font-size: 14px;
                                        padding: 8px 5px;
                                        min-height: 0; } }
                                .btn {
                                    font-size: 14px;
                                    padding: 8px 10px;
                                    margin-left: 8px;
                                    white-space: nowrap; } } }
                        &.services {
                            padding-bottom: 0; } }
                    .travel--heading {
                        h2 {
                            font-size: 16px;
                            margin: 0 0 10px; } }
                    .services__list__block {
                        display: none;
                        width: 100%;
                        .services__list__block__wrap__title {
                            display: none; }
                        .services__list__block__wrap__detail {
                            display: none; }
                        .services__list__block__wrap__img {
                            margin: 0;
                            height: 83px;
                            img {
                                max-height: 90px; } }
                        &:first-child {
                            display: inline-block; } } } } } } }
