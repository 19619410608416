@import "~bulma/sass/utilities/initial-variables";
@import "~bulma";
// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'variables.sass';
@import 'style.sass';

body {
  font-family: 'Inter', sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: $body-color !important;
  background-color: $light-bg-color !important;
  min-height: 100vh;
  @media (max-width: $breakpoint-mobile) {
    font-size: 14px !important;
    line-height: 1.3 !important; }
  &.light-bg {
    background-color: $white-color !important; }
  &.blue-left,
  &.blue-right, {
    position: relative;
    z-index: -2;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      background-color: $light-blue-color;
      z-index: -1;
      @media (max-width: $breakpoint-mobile) {
        display: none; }
      @media (max-width: $breakpoint-ipad) {
        content: none; } } }
  &.white-left {
    position: relative;
    z-index: -2;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      background-color: $white-color;
      z-index: -1;
      @media (max-width: $breakpoint-ipad) {
        display: none; } } }
  &.blue-left {
    &::after {
      left: 0; } }
  &.blue-right {
    &::after {
      right: 0; } }
  &.white-left {
    &::after {
      left: 0; } }
  &.no-footer {
    .site-footer {
      display: none; } } }
.sortableHelper {
   z-index: 10000 !important; }

p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: $body-color;
  letter-spacing: 0.5px;
  @media (max-width: $breakpoint-mobile) {
    font-size: 14px;
    line-height: 1.3; }
  &::last-child {
    margin-bottom: 0; } }
h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 72px;
  line-height: 1; }

h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

h5 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 400; }

.card-number-element {
  display: inline-flex;
  width: 100%;
  .card-icon {
    background: white;
    .pf {
      padding: 10px;
      margin-top: 10px; } }
  .StripeElement {
    width: 100%; } }
.browser-tab-preview {
  width: 150px;
  border: 1px solid #ccc;
  border-bottom: none;
  border-top-right-radius: 11px;
  border-top-left-radius: 11px;
  padding: 3px 11px;
  img {
    width: 20px; }
  span {
    margin-left: 10px;
    color: $dark-grey-color;
    font-size: 13px; } }
.input-disabled {
  pointer-events: none; }
.selectFieldInput {
  width: 350px !important;
  border: 1px solid #ddd !important;
  border-radius: 24px !important;
  min-height: 42px; }
.selectFieldInput::-webkit-input-placeholder::after {
  color: #666;
  content: "Line 1\A " !important; }
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0; }
.slate-custom-tool {
  position: relative !important;
  min-width: 7rem !important;
  box-shadow: 2px 3px 6px 2px #ccc;
  height: auto;
  margin: 0;
  padding: 0;
  li {
    color: #c1c1c1;
    font-size: 13px !important;
    padding: 7px;
    margin: 0 !important; } }

.link-button:hover,
.link-button:focus {
  text-decoration: none; }
.field_error {
  color: red;
  font-size: 14px; }

.container {
  max-width: 1127px !important; }

.unsplash-emtpy {
  text-align: center;
  line-height: 13;
  background: #eaeaea;
  height: 200px;
  p {
    padding-top: 90px; } }
body .btn {
  font-family: 'Inter', sans-serif;
  font-weight: 900;
  font-size: 14px;
  border-radius: 32px;
  text-transform: capitalize;
  border: 2px solid;
  padding: 12px 62px;
  line-height: 1;
  @media (max-width: $breakpoint-mobile) {
    padding: 11px 25px; }
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none; }
  &:disabled {
    opacity: .4; }
  &.btn-primary-2,
  &.btn-secondary-2 {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 600;
    padding: 8px 29px;
    min-width: 160px;
    text-align: center;
    border-width: 1px; }
  &.btn-primary {
    background-color: $primary-color;
    border-color:  $primary-color;
    color: $white-color;
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:hover,
    &:active,
    &:focus {
      background-color: $white-color;
      border-color:  $primary-color;
      color: $primary-color;
      box-shadow: none; }

    &.btn-primary-2 {
      background-color: #F87700 !important;
      border-color:  #F87700 !important;
      color: $white-color !important;
      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled):active:focus,
      &:hover,
      &:active,
      &:focus {
        background-color: $white-color !important;
        border-color:  #F87700 !important;
        color: #F87700 !important;
        box-shadow: none; }
      &.home-btn {
        font-size: 22px;
        padding: 18.5px 30px;
        width: auto;
        border-radius: 100px;
        min-width: 312px;
        @media (max-width: 1550px) {
          font-size: 1.2rem;
          min-width: 280px;
          padding: 16px 30px; }
        @media (max-width: $breakpoint-ipad-pro) {
          font-size: 18px;
          min-width: 250px; }
        @media (max-width: $breakpoint-ipad) {
          min-width: 0;
          font-size: 16px;
          padding: 12px 25px; } } } }
  &.btn-secondary {
    background-color: $white-color;
    border-color:  $primary-color;
    color: $primary-color;
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:hover,
    &:active,
    &:focus {
      background-color: $primary-color;
      border-color:  $primary-color;
      color: $white-color;
      box-shadow: none; } }
  &.btn-secondary-2 {
    background-color: $white-color !important;
    border-color:  #F87700 !important;
    color: #F87700 !important;
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:hover,
    &:active,
    &:focus {
      background-color: #F87700 !important;
      border-color:  #F87700 !important;
      color: $white-color !important;
      box-shadow: nonetant;
      box-shadow: none; } }
  &.btn-light {
    border: none;
    padding-left: 0;
    padding-right: 0;
    color: rgba(48, 50, 200, 0.8);
    border-radius: 0;
    background: transparent;
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:hover,
    &:active,
    &:focus {
      background: transparent;
      box-shadow: none; } } }

.btn-normal,
.btn-normal:not([href]) {
  color: $primary-color;
  font-size: 16px;
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  letter-spacing: 0.5px;
  text-decoration: underline;
  border: none !important;
  padding: 0 !important;
  background-color: transparent !important;
  &:hover,
  &:active,
  &:focus {
    outline: none !important;
    border: none !important;
    padding: 0 !important;
    background-color: transparent !important;
    box-shadow: none; } }
.checkbox-colors {
  padding: 10px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  span {
    width: 31px;
    border: 1px solid #c1c1c1 !important;
    height: 31px;
    border-radius: 62%;
    display: inline-block; } }
ul {
  li {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: $body-color;
    letter-spacing: 0.5px;
    @media (max-width: $breakpoint-mobile) {
      font-size: 14px;
      line-height: 1.8; } } }

::-webkit-scrollbar {
  width: 8px;
  height: 8px; }
::-webkit-scrollbar-track {
  background: $white-color;
  border-radius: 16px; }
::-webkit-scrollbar-thumb {
  background: rgba(51, 51, 51, 0.3);
  opacity: .5;
  border-radius: 16px; }
::-webkit-scrollbar-thumb:hover {
  background: rgba(51, 51, 51, 0.3); }

.content-side {
  @media (max-width: $breakpoint-ipad) {
    flex: 0 0 100% !important;
    max-width: 100% !important;
 }    //text-align: center
  .content-side-inner {
    //width: 415px
    max-width: 100%;
    h4 {
      font-size: 30px;
      line-height: 1.3;
      font-weight: 700;
      letter-spacing: 0.18px;
      color: $black-color;
      margin: 0 0 21px;
      @media (max-width: $breakpoint-mobile) {
        font-size: 22px; } }
    p {
      margin: 0 0 28px;
      @media (max-width: $breakpoint-mobile) {
        margin: 0 0 15px; } }
    ul {
      list-style: none;
      margin: 0 0 46px;
      padding: 0;
      @media (max-width: $breakpoint-mobile) {
        margin: 0 0 20px; }
      li {
        background-image: url('/images/checked-checkbox.svg');
        background-repeat: no-repeat;
        background-position: 0 3px;
        margin: 0 0 20px;
        padding: 0 0 0 30px;
        font-size: 20px;
        line-height: 1.3;
        font-weight: 500;
        @media (max-width: $breakpoint-mobile) {
          font-size: 16px; }
        &::after {
          display: none; }
        @media (max-width: $breakpoint-mobile) {
          width: auto;
          display: inline-block; } } }
    .btn {
      padding-left: 25px;
      padding-right: 25px; } }

  &.light-content {
    .content-side-inner {
      h4 {
        color: $white-color; }
      p {
        color: $white-color; }
      li {
        color: $white-color; } } } }

.small-wrapper {
  width: 343px;
  max-width: 100%;
  margin: 0 auto; }


.form-text {
  font-size: 14px;
  line-height: 1.4;
  color: $body-color;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: left;
  a {
    color: $charcoal-color !important;
    cursor: pointer; }
  @media (max-width: $breakpoint-mobile) {
    font-size: 12px; } }
.form-group {
  position: relative;
  width: 100%;
  display: inline-block;
  p {
    margin: 0 !important; }
  .form-label {
    color: $body-color;
    font-size: 14px;
    line-height: 1.4;
    font-weight: normal;
    text-align: left;
    width: 100%;
    text-transform: capitalize;
    @media (max-width: $breakpoint-mobile) {
      font-size: 12px; } }
  textarea.form-control {
    min-height: 96px; }
  .form-control {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: $dark-grey-color;
    font-weight: normal;
    border: 1px solid $white-color;
    background-color: $white-color;
    border-radius: 24px;
    padding: 10px 15px;
    width: 100%;
    min-height: 48px;
    @media (max-width: $breakpoint-mobile) {
      font-size: 14px;
      line-height: 1.3;
      min-height: 40px; }
    /* Change the white to any color ;) */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 40px white inset !important; }
    &:-webkit-autofill {
 }      //-webkit-text-fill-color: yellow !important
    &::placeholder {
      color: $dark-grey-color;
      opacity: 1; }
    &:focus {
      box-shadow: none; } }
  .password-wrap {
    position: relative;
    .password-swap {
      position: absolute;
      top: 6px;
      right: 15px; } }
  .field_error {
    color: $red-color;
    font-size: 14px;
    line-height: 1;
    font-weight: 300;
    // &::after
    //   content: ""
    //   position: absolute
    //   bottom: 100%
    //   left: 20px
    //   margin-left: -5px
    //   border-width: 5px
    //   border-style: solid
 } }    //   border-color: transparent transparent $red-color transparent

.no-header {
  .main-header {
    display: none;
    @media (max-width: $breakpoint-ipad-pro) {
      display: inline-block;
      width: 100%;
      ul {
        li {
          svg {
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            path,
            rect {
                fill: $dark-grey-color; } }
          &.header-profile-img {
            svg {
              path {
                fill: none;
                a {
                  &:hover {
                    background: $optional-light-grey-color !important; } } } } } } } } } }
.full-width-header {
  .main-header {
    background-color: $white-color;
    position: relative;
    z-index: 11;
    .container {
      max-width: unset !important;
      padding: 0 78px;
      @media (max-width: $breakpoint-mobile) {
        padding: 0 10px; } } } }
.main-header {
  @media (max-width: $breakpoint-ipad-pro) {
    //background-color: $light-blue-color
    box-shadow: 0 0 5px 5px rgba(0,0,0,.05); }
  &.landing-header {
    .mobile-humberger {
      &.change {
        .open-icon {
          display: inline-block; }
        .close-icon {
          display: none; } } } }
  .container {
    max-width: 1222px !important; }
  .header-top {
    padding: 20px 0;
    align-items: center;
    @media (max-width: $breakpoint-ipad-pro) {
      padding: 10px 0; }
    .header-logo {
      text-align: left;
      @media (max-width: $breakpoint-ipad-pro) {
        width: 100%;
        text-align: center; }
      .navbar-brand {
        margin: 0;
        padding: 0;
        min-height: 0;
        justify-content: flex-start;
        @media (max-width: $breakpoint-ipad-pro) {
          justify-content: center; }
        .navbar-item {
          padding: 0; }
        img {
          cursor: pointer;
          max-height: unset;
          width: 128px;
          &.dark-logo {
            display: inline-block;
            @media (max-width: $breakpoint-ipad-pro) {
 } }              //display: none
          &.light-logo {
            display: none;
            @media (max-width: $breakpoint-ipad-pro) {
 } } } } }              //display: inline-block
    .header-menu {
      text-align: right;
      @media (max-width: $breakpoint-ipad-pro) {
        position: absolute;
        width: 46px;
        padding: 0;
        left: 15px; }
      .mobile-humberger {
        display: none;
        cursor: pointer;
        @media (max-width: $breakpoint-ipad-pro) {
          display: inline-block;
          line-height: 0;
          width: 32px;
          height: 30px;
          background-color: $primary-color;
          border-radius: 4px;
          text-align: center;
          svg {
            width: 25px;
            height: 18px;
            margin-top: 6px; } }
        .open-icon {
          display: none; }
        .close-icon {
          display: inline-block; }
        .bar1,
        .bar2,
        .bar3 {
          width: 25px;
          height: 3px;
          background-color: $dark-grey-color;
          margin: 3px 0;
          transition: 0.4s; }
        &.change {
          .bar1 {
            -webkit-transform: rotate(-45deg) translate(-2px, 6px);
            transform: rotate(-45deg) translate(-2px, 6px); }
          .bar2 {
            opacity: 0; }
          .bar3 {
            -webkit-transform: rotate(45deg) translate(-2px, -8px);
            transform: rotate(45deg) translate(-2px, -8px); }
          & + .navbar {
            display: inline-block; } } }
      .navbar {
        background: transparent;
        @media (max-width: $breakpoint-ipad-pro) {
          position: absolute;
          top: calc(100% + 11px);
          left: 0;
          z-index: 20;
          display: none;
          padding: 10px 15px;
          background-color: #fff;
          box-shadow: 0 0 5px 5px rgba(0,0,0,.1); } }
      .navbar-menu {
        justify-content: flex-end;
        @media (max-width: $breakpoint-ipad-pro) {
          display: inline-block;
          width: 100%;
          justify-content: flex-start;
          padding: 0 0;
          box-shadow: none; }
        .main-navigation {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            margin-left: 15px;
            &:first-child {
              margin-left: 0; }
            @media (max-width: $breakpoint-ipad-pro) {
              width: 100%;
              text-align: center;
              margin: 0 0 10px;
              &:last-child {
                border-bottom: none;
                margin: 0;
                padding: 0; } }
            &.header-profile-img {
              a {
                padding-right: 0; } }
            button.dropdown-toggle.btn.btn-primary {
              background-color: transparent;
              padding: 11px 24px;
              border: none;
              &::after {
                display: none; }
              &:focus,
              &:hover,
              &:active {
                box-shadow: none; } }
            // .dropdown-menu
            //   background-color: $white-color
            //   border: none
            //   border-radius: 16px
            //   padding: 16px
            //   transform: translate(-80px,78px) !important
            //   @media (max-width: $breakpoint-ipad)
            //     transform: translate(21px,78px) !important
            // .dropdown-item
            //   opacity: 0.6
            //   font-size: 14px
            //   line-height: 1.4
            //   padding: 0
            //   margin: 8px 0
            //   letter-spacing: 0
            //   font-weight: 400
            //   width: 100%
            //   display: inline-block
            //   &:focus,
            //   &:hover,
            //   &:active
            //     background-color: transparent
            a {
              color: $dark-grey-color;
              font-size: 18px;
              letter-spacing: -0.2px;
              line-height: 1.4;
              font-weight: 500;
              padding: 13px 30px;
              display: inline-block;
              @media (max-width: 1550px) {
                font-size: 1rem;
                padding: 11px 18px; }
              @media (max-width: $breakpoint-ipad-pro) {
                padding: 8px 10px; }
              &:hover,
              &:active,
              &:focus {
                outline: none;
                text-decoration: none; }
              &.btn.btn-secondary,
              &.btn.btn-primary {
                padding: 13px 30px;
                min-width: 160px;
                font-weight: 500;
                @media (max-width: 1550px) {
                  padding: 11px 30px;
                  min-width: 140px; }
                @media (max-width: $breakpoint-ipad-pro) {
                  padding: 8px 18px; } }
              &.btn.btn-secondary {
                color: $primary-color;
                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled):active:focus,
                &:hover,
                &:active,
                &:focus {
                  color: $white-color; } } }
            .nav-profile-pic {
              img {
                height: 24px;
                width: 24px;
                border-radius: 100%;
                display: inline-block;
                vertical-align: middle; } }
            .header-submenu {
              position: absolute;
              top: 100%;
              top: calc(100% + 31px);
              right: 0;
              background: $white-color;
              box-shadow: 0 0px 20px rgba(0, 0, 0, 0.1);
              padding: 15px 10px 20px;
              opacity: 0;
              visibility: hidden;
              transition: all 500ms;
              @media (max-width: $breakpoint-ipad-pro) {
                position: relative;
                box-shadow: none;
                padding: 0 0 0 35px;
                display: none; }
              a {
                padding: 10px; } }
            &.active {
              .header-submenu {
                opacity: 1;
                visibility: visible;
                @media (max-width: $breakpoint-ipad-pro) {
                  display: block; } } } } } } } }

  &.no-login {
    .header-menu {
      display: none; } }
  &.logo-right {
    .header-top {
      flex-direction: row-reverse;
      .header-logo {
        text-align: right;
        @media (max-width: $breakpoint-ipad-pro) {
          text-align: center; }
        .navbar-brand {
          justify-content: flex-end;
          @media (max-width: $breakpoint-mobile) {
            justify-content: center; } } } } }
  &.toggle-sidebar {
    &.admin-header {
      overflow: hidden;
      .container {
        @media (max-width: $breakpoint-ipad-pro) {
          transform: translateX(265px); }
        @media (max-width: $breakpoint-mobile) {
          transform: translateX(240px); } }
      .header-menu {
        .mobile-humberger {
          .close-icon {
            display: none; }
          .open-icon {
            display: inline-block; } } } } } }



// .home-banner
//   padding: 55px 0
//   @media (max-width: $breakpoint-ipad)
//     padding: 30px 0
//   .text-side
//     @media (max-width: $breakpoint-mobile)
//       flex: 0 0 100%
//       max-width: 100%
//       text-align: center
//       margin: 0 0 30px
//     .text-wrap
//       width: 564px
//       max-width: 100%
//     h1
//       font-size: 72px
//       color: $dark-grey-color
//       @media (max-width: $breakpoint-ipad-pro)
//         font-size: 65px
//       @media (max-width: $breakpoint-ipad)
//         font-size: 50px
//       @media (max-width: $breakpoint-mobile)
//         font-size: 38px
//         line-height: 1.1
//     p
//       width: 280px
//       max-width: 100%
//       color: $body-color
//       margin: 65px 0 25px
//       @media (max-width: $breakpoint-ipad-pro)
//         margin: 25px 0 25px
//     .btn
//       width: 340px
//       max-width: 100%
//   .img-side
//     text-align: right
//     @media (max-width: $breakpoint-mobile)
//       flex: 0 0 100%
//       max-width: 100%
//       text-align: center
//     .carousel
//       .carousel-indicators
//         left: 35px
//         top: 35px
//         display: none
//       .carousel-inner
//         position: relative
//         &::after
//           content: ''
//           left: 25px
//           right: 25px
//           top: 25px
//           bottom: 25px
//           //border: 2px solid $light-grey-color
//           position: absolute
//           z-index: 0
//           border-radius: 4px
//         .carousel-item
//           position: relative
//           z-index: 2

.get-website {
  padding: 50px 0;
  @media (max-width: $breakpoint-ipad) {
    padding: 30px 0; }
  .get-website-inner {
    width: 100%;
    display: inline-block;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
      background-color: $light-blue-color;
      @media (max-width: $breakpoint-ipad) {
        width: 55%; }
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        bottom: 50%;
        right: 0; } } }
  .heading-side {
    position: relative;
    z-index: 1;
    @media (max-width: $breakpoint-mobile) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center; }
    .heading-side-inner {
      width: 400px;
      max-width: 100%;
      padding: 142px 0 140px;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        padding: 30px 0;
        margin: 0 0 18px; } }
    h3 {
      color: $white-color;
      font-size: 34px;
      line-height: 44.2px;
      @media (max-width: $breakpoint-ipad) {
        font-size: 26px;
        line-height: 1.3;
        padding-right: 20px; }
      @media (max-width: $breakpoint-mobile) {
        font-size: 22px;
        padding: 0; } } } }

.website-building {
  padding: 135px 0;
  @media (max-width: $breakpoint-ipad) {
    padding: 30px 0; }
  .website-build-img {
    @media (max-width: $breakpoint-mobile) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
      margin: 30px 0 0; } } }

.website-comparison {
  padding: 165px 0;
  @media (max-width: $breakpoint-ipad) {
    padding: 30px 0; }
  .website-compare-img {
    @media (max-width: $breakpoint-mobile) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center;
      margin: 0 0 40px; } } }

.website-plan {
  text-align: center;
  padding: 95px 0;
  @media (max-width: $breakpoint-ipad) {
    padding: 30px 0; }
  @media (max-width: $breakpoint-mobile) {
    padding: 30px 0 0; }
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: $secondary-grey-color; }
  p {
    color: $secondary-light-grey-color; }
  .col {
    @media (max-width: $breakpoint-mobile) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center; } }
  .new_plan_box {
    position: relative;
    .annual-box {
      position: absolute;
      top: -1px;
      left: -1px;
      padding: 0;
      width: 106px;
      height: 27px;
      background: $blue-color;
      color: #fff;
      border-radius: 16px 0px 5px;
      font-weight: 600;
      font-size: 18px;
      text-align: center; } }
  .plan-detail {
    margin: 0;
    // border: 1px solid rgba(0,0,0,.08)
    //width: 333px
    max-width: 100%;
    display: inline-block;
    text-align: left;
    border-radius: 16px;
    padding: 10px 30px !important;
    @media (max-width: $breakpoint-ipad-pro) {
      padding: 62px 30px; }
    @media (max-width: $breakpoint-ipad) {
      padding: 62px 20px;
      margin: 0; }
    @media (max-width: $breakpoint-mobile) {
      padding: 30px;
      margin: 0 0 30px; }
    .plan-detail-inner {
      .plan-img {
        text-align: center; }
      .plan-name {
        text-align: center;
        font-size: 24px !important;
        line-height: 30px;
        letter-spacing: 0.18px;
        font-weight: 700;
        margin: 20px 0 15px;
        & span.businessPlan, &.businessPlan {
          background: $light-purple-color;
          border-radius: 10px;
          color: $white-color !important;
          line-height: 1.3;
          width: auto;
          height: 32px;
          font-weight: bold !important;
          font-size: 18px !important;
          max-width: 120px;
          width: 100%;
          margin: 8px auto 6px; } }
      ul {
        @media (max-width: $breakpoint-mobile) {
          margin: 0 0 15px; }
        li {
          background-image: url('/images/checked.svg');
          background-position: 0 12px;
          background-repeat: no-repeat;
          margin: 0;
          padding: 0 0 0 26px;
          line-height: 30px; } }
      .plan-price {
        text-align: center;
        font-size: 34px;
        line-height: 44.2px;
        color: $secondary-grey-color;
        margin: 10px 0 6px;
        font-weight: 600;
        sup {
          font-weight: 600;
          font-size: 12px;
          top: -1.25em;
          left: -5px; }
        sub {
          font-weight: bold;
          font-size: 20px; }
        @media (max-width: $breakpoint-mobile) {
          margin: 0 0 10px;
          font-size: 28px; } }
      .plan-action {
        text-align: center; }
      .billBYyear {
        font-weight: 500;
        font-size: 13px;
        margin-bottom: 8px;
        color: $light-greybg-color;
        text-align: center; } } } }

.website-faq {
  background-color: $light-blue-color;
  padding: 28px 0 45px;
  @media (max-width: $breakpoint-mobile) {
    padding: 30px 0; } }
.site-footer {
  padding: 93px 0 58px;
  @media (max-width: $breakpoint-ipad) {
    padding: 30px 0; }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    @media (max-width: $breakpoint-mobile) {
      text-align: center; }
    li {
      display: inline-block;
      width: auto;
      font-size: 16px;
      a {
        display: inline-block; } } }
  .footer-top {
    margin-bottom: 35px;
    @media (max-width: $breakpoint-mobile) {
      margin-bottom: 20px; }
    .footer-logo-copyright {
      @media (max-width: $breakpoint-mobile) {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;
        margin: 0 0 15px; }
      .copyright {
        display: inline-block;
        width: 100%;
        color: $black-color;
        font-size: 14px;
        line-height: 1.4;
        font-weight: normal;
        margin: 15px 0 0;
        @media (max-width: $breakpoint-mobile) {
          font-size: 12px; } } }
    .footer-social-icon-menu {
      @media (max-width: $breakpoint-mobile) {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center; }
      li {
        padding-right: 50px;
        @media (max-width: $breakpoint-mobile) {
          padding-right: 25px; }
        &:last-child {
          padding-right: 0; } }
      a {
        font-size: 14px;
        line-height: 1.5;
        font-weight: 700;
        letter-spacing: 0.1px;
        color: $black-color;
        @media (max-width: $breakpoint-mobile) {
          font-size: 12px; } }
      .social-links {
        margin-top: 18px;
        li {
          padding-right: 27px;
          &:last-child {
            padding-right: 0; }
          a {
            background-color: $light-bg-color;
            width: 24px;
            height: 24px;
            text-align: center;
            border-radius: 100%;
            display: inline-block; } } } } }
  .footer-menu {
    li {
      padding-right: 16px;
      &:last-child {
        padding-right: 0; } }
    a {
      color: $primary-color;
      font-size: 16px;
      font-weight: 900;
      text-decoration: underline;
      @media (max-width: $breakpoint-mobile) {
        font-size: 12px; } } } }

.back-to-home {
  text-align: left;
  a {
    font-size: 14px;
    line-height: 1.4;
    color: $body-color !important;
    font-weight: normal;
    svg {
      padding-right: 3px;
      display: inline-block;
      vertical-align: middle; } }
  span {
    cursor: pointer; } }


.section-heading {
  font-size: 34px;
  line-height: 1.3;
  color: $light-blue-color;
  font-weight: normal;
  margin: 0 0 10px;
  @media (max-width: $breakpoint-mobile) {
    font-size: 28px; } }

.heading-detail {
  color: $optional-grey-color;
  font-weight: normal;
  a {
    color: $primary-color;
    text-decoration: underline;
    font-weight: 900; } }

.login-forms {
  text-align: center;
  margin: 8px 0 0;
  @media (max-width: $breakpoint-mobile) {
    margin: 25px 0 50px; }
  form {
    text-align: left;
    margin: 28px 0 0;
    .btn {
      width: 100%;
      margin: 5px 0 0;
      padding: 15px 30px; } } }

.signup-forms {
  text-align: left; }

.login-section {
  padding: 0 0 60px;
  min-height: 90vh;
  min-height: calc(100vh - 102px);
  @media (max-width: $breakpoint-mobile) {
    min-height: 0;
    padding: 0; }
  .back-to-home {
    @media (max-width: $breakpoint-ipad-pro) {
      margin-top: 15px; } } }

.signup-section {
  padding: 0 0;
  @media (max-width: $breakpoint-ipad) {
    padding: 0; }
  .login-forms {
    @media (max-width: $breakpoint-ipad) {
      margin-top: 40px; }
    @media (max-width: $breakpoint-mobile) {
      margin-top: 30px;
      margin-bottom: 0; } }
  .content-side {
    @media (max-width: $breakpoint-ipad) {
      background-color: $white-color;
      padding-top: 30px;
      padding-bottom: 30px;
      margin: 50px 0 0;
      flex: 0 0 100%;
      max-width: 100%;
      @media (max-width: $breakpoint-mobile) {
        margin: 30px 0 0; }
 } }      //text-align: center
  @media (max-width: $breakpoint-ipad) {
    .form-side {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center; } } }

.social-logins {
  .login-or {
    margin: 15px 0; }
  .social-btn {
    margin: 15px 0 0;
    .login-or {
      font-weight: 900;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      position: relative;
      margin: 0;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        background-color: $dark-grey-color;
        height: 1px; }
      span {
        display: inline-block;
        background-color: $light-bg-color;
        padding: 10px;
        position: relative;
        z-index: 1; } } }
  .btn {
    width: 100%;
    svg {
      display: inline-block;
      vertical-align: middle;
      padding-right: 20px;
      @media (max-width: $breakpoint-mobile) {
        padding-right: 10px; } }
    img {
      display: inline-block;
      vertical-align: middle;
      padding-right: 20px;
      @media (max-width: $breakpoint-mobile) {
        padding-right: 10px; } }
    span {
      display: inline-block;
      vertical-align: middle; } }
  .btn.btn-primary {
    padding: 14px 30px;
    @media (max-width: $breakpoint-mobile) {
      padding: 14px 20px; } }
  .btn.btn-primary.btn-facebook {
    background-color: $facebook-color;
    border-color: $facebook-color;
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:hover,
    &:active,
    &:focus {
      background-color: $google-color;
      border-color: $google-color; }
    a {
      color: $white-color;
      outline: none; } }
  .btn.btn-primary.btn-google {
    background-color: $google-color;
    border-color: $google-color;
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    &:hover,
    &:active,
    &:focus {
      background-color: $facebook-color;
      border-color: $facebook-color; }
    a {
      color: $white-color;
      outline: none; } } }
.form-side {
  // min-height: 90vh
  // min-height: calc(100vh - 76px)
  &.form-side-right {
    .small-wrapper {
      //float: right
      margin: 0 auto; } }
  @media (max-width: $breakpoint-ipad) {
    min-height: 0; } }


.forget-form-inner {
  .small-wrapper {
    margin: 0; } }

.positionUnset {
  position: unset; }

.main-form-section {
  position: relative;
  .back-to-home {
    @media (max-width: $breakpoint-ipad) {
      margin-top: 15px; } }
  .crausel-side {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    @media (max-width: $breakpoint-ipad) {
      position: relative;
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0 0 0;
      //background-color: $primary-color
      padding: 0; }
    .carousel {
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      left: 50%;
      background: $white-color;
      border-radius: 4px;
      width: 383px;
      max-width: 95%;
      padding: 30px 50px 0;
      @media (max-width: $breakpoint-ipad) {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
        width: 100%;
        max-width: 100%; }
      .carousel-indicators {
        top: 10px;
        li {
          border-color: $light-blue-color !important;
          width: 6.72px !important;
          height: 7.03px !important;
          &.active {
            background: #506BF0; } } } } }
  .crausel-side-left {
    position: relative;
    right: 0;
    top: 0;
    bottom: 0;
    .carousel {
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      left: 50%;
      background: $white-color;
      border-radius: 4px;
      width: 383px;
      max-width: 95%;
      padding: 30px 50px 0;
      .carousel-indicators {
        top: 10px;
        li {
          border-color: $light-blue-color;
          &.active {
            background: #506BF0; } } } } }
  .crausel-side-left {
    position: relative;
    right: 0;
    top: 0;
    bottom: 0;
    @media (max-width: $breakpoint-ipad) {
      flex: 0 0 100%;
      max-width: 100%; }
    .carousel {
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      left: 50%;
      background: #fff;
      border-radius: 4px;
      width: 383px;
      max-width: 95%;
      padding: 30px 50px 0;
      @media (max-width: $breakpoint-ipad) {
        position: relative;
        transform: none;
        top: auto;
        left: auto;
        margin-top: 0; }
      .carousel-indicators {
        top: 10px;
        li {
          border-color: #506BF0;
          &.active {
            background: $light-blue-color; } } } } } }
.forget-forms {
  min-height: inherit;
  form {
    min-height: inherit;
    .forget-form-inner {
      min-height: inherit;
      display: flex;
      justify-content: space-between;
      padding: 60px 0 120px;
      flex-direction: column;
      @media (max-width: $breakpoint-ipad) {
        padding: 30px 0; } } } }

.forgot-btns {
  margin: 30px 0 0;
  width: 100%;
  display: inline-block;
  .btn-primary {
    margin-bottom: 35px;
    width: 100%; } }
.otpFieldsInput {
  input {
    width: 43px !important;
    height: 44px;
    border-radius: 100%;
    border: none;
    background-color: $white-color;
    font-size: 16px;
    line-height: 1.5;
    margin-right: 15px;
    &:focus,
    &:hover,
    &:active {
      outline: none; }
    @media (max-width: $breakpoint-mobile) {
      width: 35px !important;
      height: 35px; } } }
.main-section {
  .page-section {
    padding: 35px 0;
    min-height: 80vh;
    min-height: calc(100vh - 365px);
    word-break: break-word;
    @media (max-width: $breakpoint-mobile) {
      padding: 20px 0; } }
  .page-heading {
    font-weight: normal;
    font-size: 34px;
    line-height: 1.3;
    color: $light-blue-color;
    margin: 0 0 25px;
    @media (max-width: $breakpoint-mobile) {
      font-size: 28px; } }
  a {
    color: $primary-color;
    &:hover,
    &:focus,
    &:active {
      text-decoration: none; } }
  h3 {
    font-weight: bold;
    font-size: 25px;
    line-height: 1.3;
    color: $dark-grey-color;
    margin: 0 0 25px;
    @media (max-width: $breakpoint-mobile) {
      font-size: 22px; } }
  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    color: $dark-grey-color;
    margin: 0 0 25px;
    @media (max-width: $breakpoint-mobile) {
      font-size: 18px; } }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.3;
    color: $dark-grey-color;
    letter-spacing: 0.5px;
    margin: 0 0 28px;
    @media (max-width: $breakpoint-mobile) {
      font-size: 14px;
      line-height: 1.3;
      margin: 0 0 15px; } }
  ul {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.3;
    color: $dark-grey-color;
    letter-spacing: 0.5px;
    margin: 0 0 25px;
    list-style: none;
    li {
      color: $dark-grey-color; }
    @media (max-width: $breakpoint-mobile) {
      font-size: 14px; }
    &.styled-ul {
      li {
        width: 100%;
        display: inline-block;
        position: relative;
        margin: 0 0 10px;
        padding: 0 0 0 55px;
        @media (max-width: $breakpoint-mobile) {
          padding: 0 0 0 20px; }
        &::after {
          content: '.';
          position: absolute;
          left: 0;
          top: -5px;
          width: 55px;
          text-align: center;
          @media (max-width: $breakpoint-mobile) {
            width: 20px; } } } } }
  table {
    margin: 0 0 25px;
    border-collapse: collapse;
    border: 1px solid $light-grey-color;
    tr, td, th {
      border: 1px solid $light-grey-color; }
    td, th {
      padding: 15px; }
    thead {
      background-color: $primary-color;
      color: $white-color;
      tr {
        th {
          color: $white-color; } } } } }
.table-responsive {
  text-align: center;
  table {
    margin-left: auto;
    margin-right: auto;
    text-align: left; } }
.privacy-table {
  width: 1000px;
  td, th {
    &:nth-child(1) {
      width: 350px; }
    &:nth-child(2) {
      width: 550px; }
    &:nth-child(3) {
      width: 150px; } } }
.privacy-table-contents {
  border-bottom: 1px solid $light-grey-color;
  margin-bottom: 45px; }

.styled-ol {
  width: 100%;
  display: inline-block;
  list-style-position: inside;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.3;
  color: $dark-grey-color;
  letter-spacing: 0.5px;
  margin: 0 0 25px;
  @media (max-width: $breakpoint-mobile) {
    font-size: 14px; }
  li {
    margin: 0 0 10px; } }
.privacy-table-index {
  color: $primary-color;
  li {
    margin: 0 0 18px; } }

.not-found-page {
  margin: 52px 0 20px;
  @media (max-width: $breakpoint-mobile) {
    margin: 30px 0; }
  .col {
    @media (max-width: $breakpoint-mobile) {
      max-width: 100%;
      flex: 0 0 100%;
      text-align: center; } }
  h1 {
    color: $dark-grey-color;
    letter-spacing: -1.5px;
    font-size: 72px;
    line-height: 1;
    font-weight: normal;
    @media (max-width: $breakpoint-mobile) {
      font-size: 45px; } }
  .not-found-btns {
    margin: 55px 0 20px;
    @media (max-width: $breakpoint-mobile) {
      margin: 25px 0 40px; }
    a {
      display: inline-block;
      vertical-align: middle;
      width: 166px;
      margin-right: 25px;
      padding: 11px 30px; }
    p {
      color: $body-color;
      display: inline-block;
      vertical-align: middle;
      width: 200px;
      max-width: 100%;
      margin: 0;
      @media (max-width: $breakpoint-mobile) {
        width: 100%;
        margin: 20px 0; } } } }

.carousel-indicators {
  text-align: left;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  justify-content: flex-start !important;
  li {
    width: 7.74px !important;
    height: 8.1px !important;
    border: 2px solid $optional-light-grey-color !important;
    background: transparent;
    border-radius: 100%;
    margin: 0 0 0 10px;
    opacity: 1 !important;
    box-sizing: border-box;
    &.active {
      background: $optional-light-grey-color; } } }
.active {
  .styled-radio-btn {
    .styled-radio + .input-radio-label {
        background-image: url('/images/radio-checked.png');
        border: 1px solid $primary-color;
        box-shadow: 0 0 0 1px $primary-color;
        background-color: rgba(48, 50, 200, 0.1); } } }
.styled-radio-btn {
  margin: 16px 0 0;
  position: relative;
  label {
    margin: 0; }
  input {
    display: inline-block;
    vertical-align: middle; }
  .styled-radio {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
    &:checked + .input-radio-label {
      background-image: url('/images/radio-checked.png');
      border: 1px solid $primary-color;
      box-shadow: 0 0 0 1px $primary-color;
      background-color: rgba(48, 50, 200, 0.1); } }
  .input-radio-label {
    display: inline-block;
    vertical-align: middle;
    color: $dark-grey-color;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 18px 10px 43px;
    background-image: url('/images/radio.png');
    background-repeat: no-repeat;
    background-position: 18px center;
    border: 1px solid $optional-light-grey-color;
    box-shadow: 0 0 0 1px $white-color;
    border-radius: 24px;
    width: 100%;
    display: inline-block;
    .emojiText {
      margin: 0 0 0 5px !important;
      display: inline-block;
      vertical-align: middle;
      word-break: break-all; }
    img {
      width: auto !important;
      max-width: 25px;
      display: inline-block;
      vertical-align: middle; } } }
.main-section {
  .step-banner {
    background: linear-gradient(219.41deg, $primary-color 0%, $light-blue-color 100%);
    color: $white-color;
    padding: 10px 0;
    .back-to-home {
      a {
        color: $white-color !important;
        svg {
          display: inline-block;
          vertical-align: middle; } } }
    h1 {
      color: $white-color;
      font-size: 72px;
      line-height: 1;
      letter-spacing: -1.5px;
      font-weight: normal;
      margin: 5px 0 0;
      @media (max-width: $breakpoint-mobile) {
        font-size: 40px;
        margin: 5px 0 0; } }
    p {
      font-weight: bold;
      font-size: 20px;
      line-height: 1.3;
      color: $white-color;
      margin: 0; } } }
.jUPPFA {
    opacity: 0.7;
    width: 100%;
    left: 0px;
    top: 0px;
    height: 100%;
    position: fixed;
    z-index: 99999;
    pointer-events: none;
    color: rgb(0, 0, 0); }

.mask {
  color:#04040487 {} }

.site-list {
  .page-detail {
    &:focus {}
    &:hover {
      cursor: default;
      border-color: $light-blue-color;
      .page-layout {
        &:focus {}
        &:hover {
          cursor: pointer; } } } } }
.avatar-user {
  &:focus,
  &:active {
    outline: none; } }
.upload-file {
  position: relative;
  color: $primary-color;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  @media (max-width: $breakpoint-mobile) {
    text-align: center; }
  &:focus,
  &:active {
    outline: none; }
  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('/images/plus.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 24px;
    height: 24px;
    @media (max-width: $breakpoint-mobile) {
      left: 26px; } }
  p {
    margin: 0;
    display: inline-block;
    width: 100%;
    padding: 0 0 0 26px;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.6;
    color: $primary-color;
    text-transform: capitalize;
    &:focus,
    &:active {
      outline: none; }
    i {
      font-style: unset;
      font-weight: 700; }
    a {
      font-weight: 700;
      &:focus,
      &:active {
        outline: none; } } } }

.upload-media-btn {
  width: 100%;
  display: inline-block;
  margin: 5px 0;
  button {
    position: relative;
    color: $primary-color;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    padding: 10px 17px 10px 41px;
    background: transparent;
    border: 1px solid $primary-color;
    border-radius: 25px;
    text-transform: capitalize;
    &:focus,
    &:active {
      outline: none; }
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      background-image: url('/images/plus.png');
      background-repeat: no-repeat;
      background-position: 0 0;
      width: 24px;
      height: 24px; } } }

.cat-list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    width: 100% !important;
    display: inline-block !important;
    background: rgba(28, 30, 153, 0.1);
    border: 2px solid $primary-color;
    border-radius: 24px;
    color: $primary-color;
    font-size: 16px;
    line-height: 36px;
    font-weight: 700;
    margin: 0 0 15px !important;
    background-image: url(/images/radio-checked.png);
    background-repeat: no-repeat;
    background-position: 14px center;
    height: 40px;
    padding-left: 38px;
    text-align: left;
    &::after {
      display: none; } } }
.website-loader {
  align-items: center;
  .spinner-border {
    color: $primary-color; } }
.drag-image-box {
  width: 100%;
  max-width: 225px;
  height: 100px;
  background: $light-bg-color;
  border: 1px dashed #9E9E9E;
  border-radius: 24px;
  padding: 16px;
  margin: 7px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    color: #9E9E9E;
    font-size: 18px;
    line-height: 1.5;
    padding: 0; } }
.modal-backdrop {
  z-index: 39; }
.modal {
  right: 0;
  padding: 0 !important;
  .modal-body {
    .row {
      @media (max-width: $breakpoint-mobile) {
        margin-left: 0;
        margin-right: 0; } }
    .col {
      @media (max-width: $breakpoint-mobile) {
        padding-left: 0;
        padding-right: 0; } } }
  .modal-dialog {
    max-width: 95%;
    @media (max-width: $breakpoint-mobile) {
      max-width: 100%;
      margin: 0; }
    .modal-content {
      width: 100%;
      border-radius: 16px;
      border: none;
      max-width: 100%;
      @media (max-width: $breakpoint-mobile) {
        margin: 0;
        border-radius: 0;
        max-height: 100vh;
        padding: 10px 0; }
      @media (max-width: $breakpoint-ipad) {
        margin: 0 auto; }
      .close {
        padding: 0;
        font-size: 0;
        width: 24px;
        height: 24px;
        background-image: url('/images/close.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        margin: 0;
        opacity: 1;
        &:focus {
          outline: none; } }
      .modal-footer {
        border: none;
        @media (max-width: $breakpoint-mobile) {
          padding-left: 0;
          padding-right: 0; } }
      .modal-title {
        color: $grey-color;
        font-size: 34px;
        line-height: 1.3;
        @media (max-width: $breakpoint-mobile) {
          text-align: center;
          font-size: 22px;
          margin: 15px 0 0;
          padding: 0 2%; } } } } }

.modal-btns {
  text-align: right;
  width: 100%;
  @media (max-width: $breakpoint-mobile) {
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
    padding: 0 50px 0 15px; }
  .btn {
    margin-left: 18px;
    @media (max-width: $breakpoint-mobile) {
      margin-left: 0;
      width: 100%;
 }      //border-radius: 0
    &:first-child {
      margin-left: 0; } } }

.password-reset-modal {
  .modal-dialog {
    .modal-content {
      padding: 65px;
      width: 616px;
      @media (max-width: $breakpoint-mobile) {
        padding: 20px 0; }
      .modal-body {
        text-align: center;
        @media (max-width: $breakpoint-mobile) {
          padding: 0; }
        h2 {
          font-size: 34px;
          line-height: 1.3;
          margin: 24px 0;
          @media (max-width: $breakpoint-mobile) {
            font-size: 25px; } }
        .btn {
          @media (max-width: $breakpoint-mobile) {
            width: 100%;
            border-radius: 0; } } } } } }
.social-connect-modal {
  .styled-radio-btn {
    .input-radio-label {
      border-color: transparent;
      background-color: $light-bg-color; }
    &#yelp-business {
      .input-radio-label {
        background-image: url('/images/yelp-business.png') !important; } }
    &#google-business {
      .input-radio-label {
        background-image: url('/images/google-business.png') !important; } }
    &#facebook-business {
      .input-radio-label {
        background-image: url('/images/facebook.png') !important; } }
    &#facebook {
      .input-radio-label {
        background-image: url('/images/facebook.png') !important; } }
    &#linkedin {
      .input-radio-label {
        background-image: url('/images/linkedin.png') !important; } }
    &#instagram {
      .input-radio-label {
        background-image: url('/images/instagram.png') !important; } }
    &#twitter {
      .input-radio-label {
        background-image: url('/images/twitter.png') !important; } }
    &#youtube {
      .input-radio-label {
        background-image: url('/images/youtube.png') !important; } }
    &#label {
      .input-radio-label {
        background-image: url('/images/facebook.png') !important; } } }
  .social-url-form {
    .form-group {
      .form-control {
        border: 2px solid rgba(51, 51, 51, 0.3); } }
    .social-url-title {
      width: 100%;
      display: inline-block;
      @media (max-width: $breakpoint-mobile) {
        margin: 20px 0 0; }
      h4 {
        font-size: 16px;
        line-height: 1.5;
        font-weight: normal;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-align: center;
        margin: 0 0 10px; }
      .social-url-img {
        text-align: center;
        img {
          height: 96px;
          width: auto; } } }
    .modal-btns {
      margin: 85px 0 0;
      @media (max-width: $breakpoint-mobile) {
        margin: 30px 0 50px; } } } }
.category-wrapper {
  width: 727px;
  max-width: 100%;
  margin: 12px 0 0;
  @media (max-width: $breakpoint-mobile) {
    padding: 0 2%; }
  .form-group {
    .styled-radio-btn {
      margin-left: 5%;
      width: 47.5%;
      display: inline-block;
      vertical-align: middle;
      @media (max-width: $breakpoint-mobile) {
        margin-left: 0;
        width: 100%; }
      &:nth-child(2n+1) {
        margin-left: 0;
        clear: left; } } } }

.progress {
  height: 27px !important;
  border-radius: 15px !important;
  border: 1px solid rgba(51, 51, 51, 0.3) !important;
  background-color: transparent !important;
  .progress-bar {
    background: $green-color;
    border-radius: 15px;
    height: 27px;
    color: $black-color;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
    padding: 0 9px;
    text-align: left; } }
.forgot-password-section {
  .form-side {
    @media (max-width: $breakpoint-ipad) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center; } } }
.name-website {
  .name-website-selector {
    padding-top: 12px;
    @media (max-width: $breakpoint-ipad) {
      flex: 0 0 100%;
      max-width: 100%;
      order: 2;
      padding: 15px 2%;
      margin-top: 15px; }
    .small-wrapper {
      position: relative; }
    .logo-optional {
      &.logo-optional-gap {
        margin-top: 85px; } } }
  .name-website-selector-preview {
    @media (max-width: $breakpoint-ipad) {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center; } } }
.wizard-preview {
  .wizrd-banner-content {
    h5 {
      width: 100% !important; } }
  h4 {
    color: #0A0A0A;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    margin: 0 0 2px;
    @media (max-width: $breakpoint-mobile) {
      font-size: 20px;
      margin: 0 0 5px; } } }
.final-blog-preview {
  text-align: center;
  .wizrd-blog-preview {
    display: inline-block;
    @media (max-width: $breakpoint-ipad) {
      margin-bottom: 30px; } } }
.small-loader {
  display: inline-block;
  position: absolute;
  right: -10px;
  top: -28px; }
.assessment-domain-selector {
  .small-loader {
    display: inline-block;
    position: relative;
    right: 100%;
    top: 12px; } }
.global-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  .lds-facebook {
    div {
      animation: lds-facebook-global 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
      left: 8px;
      width: 16px;
      &:nth-child(1) {
        left: 8px; }
      &:nth-child(2) {
        left: 32px; }
      &:nth-child(3) {
        left: 56px; } } } }

.lds-facebook {
  display: inline-block;
  position: relative;
  div {
    display: inline-block;
    position: absolute;
    left: 4px;
    width: 4px;
    background: $primary-color;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    &:nth-child(1) {
      left: 4px;
      animation-delay: -0.24s; }
    &:nth-child(2) {
      left: 12px;
      animation-delay: -0.12s; }
    &:nth-child(3) {
      left: 20px;
      animation-delay: 0; } } }
.react-confirm-alert {
  background: #ffff;
  padding: 25px; }
.react-confirm-alert-overlay {
    background: rgba(0, 0 ,0,0.5); }
.add-icon-btn {
  background: none;
  border: 0.5px solid #3032C8;
  border-radius: 8px;
  text-align: center;
  align-items: center;
  height: 33px;
  width: 33px; }
.blogs-pagination {
  width: 100%;
  display: inline-block;
  text-align: right;
  margin: 30px 0;
  .pagination {
    width: 100%;
    display: inline-block !important;
    margin: 6px 0px 40px;
    text-align: center;
    li {
      margin: 0 2px;
      width: auto !important;
      display: inline-block;
      a {
        width: auto !important;
        display: inline-block;
        font-size: 18px;
        line-height: 18px;
        font-weight: 500;
        border-radius: 5px;
        padding: 6px 9px 5px;
        opacity: 0.6;
        color: $black-color; }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-last-child(1),
      &:nth-last-child(2) {
        a {
          color: $primary-color; } }
      &:hover {
        text-decoration: none; }
      &.active {
        a {
          opacity: 1;
          color: $white-color;
          background-color: $primary-color; } }
      &.disabled {
        a {
            opacity: 0.3; } } } } }
.btn-loader {
  right: 0px !important; }
.small-up-loader {
    display: inline-block;
    position: relative;
    right: 50px;
    top: -38px; }

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 55px; }
  50%, 100% {
    top: 24px;
    height: 20px; } }
@keyframes lds-facebook-global {
  0% {
    top: 8px;
    height: 150px; }
  50%, 100% {
    top: 24px;
    height: 50px; } }
body.modal-open {
  padding: 0 !important;
  height: 100vh;
  .modal {
    overflow: hidden; } }

.edit-site-title {
  display: flex;
  justify-content: space-between;

  .preview-switch {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 0 0 6px;
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      font-weight: bold;
      color: #9E9E9E; }
    .preview-switcher-wrap {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      border: 2px solid #9E9E9E;
      border-radius: 10px;
      margin-left: 6px;
      position: relative;
      z-index: 2;
      .preview-switcher {
        width: 44px;
        height: 40px;
        line-height: 41px;
        display: inline-block;
        vertical-align: middle;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        margin-left: -1px;
        margin-bottom: -1px;
        margin-top: -1px;
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: 0;
          margin-right: -1px; }
        &.active {
          background-color: $primary-color;
          svg {
            path {
              fill: $white-color; } } } } } } }

.profile-add-payment {
  .default-error {
    color: #eb1c26; } }
.ElementsApp {
  .is-invalid {
    color: $black-color !important; } }
.site-header {
  display: inline-flex !important; }
.site-title {
  input {
    border-radius: 25px; } }
.webfin-color-selector {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  padding: 14px;
  display: inline-block;
  overflow: hidden;
  @media (max-width: $breakpoint-ipad) {
    width: 247px; }
  .webfin-color-selector-palete {
    width: 191px;
    height: 158px;
    margin-right: 14px;
    display: inline-block;
    vertical-align: middle;
    position: relative; }
  .webfin-color-selector-line {
    width: 13px;
    height: 158px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    border-radius: 50px;
    .hue-vertical {
      border-radius: 50px;
      & > div {
        & > div {
          height: 13px !important;
          width: 13px !important;
          margin-left: 2px;
          margin-right: 0;
          z-index: -1;
          box-shadow: none !important;
          background-color: transparent !important;
          border: 3px solid #fff;
          border-radius: 100% !important; } } } }
  .webfin-color-name {
    width: 100%;
    display: inline-block;
    margin: 10px 0 0;
    label {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.1px;
      font-weight: 700;
      margin-right: 14px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
      color: #1b1b1b; }
    .webfin-color-copy {
      display: inline-block;
      vertical-align: middle;
      border: 1px solid rgba(18, 17, 27, 0.2);
      border-radius: 3.39px;
      line-height: 0; }
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      line-height: 1.4;
      font-weight: normal; }
    .color-selector-preview {
      border: 1.5px solid rgba(166, 46, 46, 0.3);
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin-right: 6px;
      margin-left: 6px; }
    .copy-color {
      display: inline-block;
      vertical-align: middle;
      margin-left: 26px;
      border-radius: 0;
      line-height: 0;
      padding: 4px 6px 3px;
      border-left: 1px solid rgba(18, 17, 27, 0.2);
      svg {
        width: 18px;
        height: 18px; } }
    .form-control {
      border: none;
      display: inline-block;
      vertical-align: middle;
      height: auto;
      padding: 0;
      min-width: 0;
      width: 70px;
      font-size: 12px;
      line-height: 1.4;
      color: #12111B;
      opacity: 0.5; } } }
.signup-section {
  @media (max-width: $breakpoint-mobile) {
    padding: 0; }
  .sigunp-content-side.content-side {
    overflow: hidden;
    @media (max-width: $breakpoint-ipad) {
      order: 2; } }
  .sigunp-form-side {
    @media (max-width: $breakpoint-ipad) {
      order: 1; } } }
.react-confirm-alert {
  width: 616px;
  max-width: 95%;
  border-radius: 24px;
  padding: 48px;
  @media (max-width: $breakpoint-mobile) {
    padding: 25px; }
  .confirm-modal {
    width: 100%;
    display: inline-block;
    text-align: center;
    .confirm-modal-header {
      width: 100%;
      display: inline-block;
      margin: 0 0 18px;
      text-align: right;
      @media (max-width: $breakpoint-mobile) {
        margin: 0 0 12px; } }
    .confirm-modal-body {
      width: 100%;
      display: inline-block;
      h2 {
        color: $dark-grey-color;
        font-size: 34px;
        line-height: 1.3;
        margin: 0 0 24px;
        @media (max-width: $breakpoint-mobile) {
          font-size: 25px;
          margin: 0 0 10px; } }
      p {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: $dark-grey-color;
        margin: 0;
        @media (max-width: $breakpoint-mobile) {
          font-size: 14px; } }
      .confirm-modal-btns {
        width: 100%;
        display: inline-block;
        margin: 48px 0;
        @media (max-width: $breakpoint-mobile) {
          margin: 25px 0; }
        .confirm-btn {
          margin: 0 0 24px; }
        .cancel-btn {
          .btn {
            border: none;
            padding: 14px 0;
            color: $primary-color;
            text-decoration: underline; } } } } } }

.logo-upload-modal {
  .modal-dialog {
    width: 940px;
    .modal-content {
      .preview-logo {
        width: 100%;
        margin: 15px 0 0;
        display: flex;
        align-items: center;
        img {
          border-radius: 16px;
          width: 92px;
          height: 92px;
          object-fit: cover;
          margin-right: 15px; }
        .clear-logo {
          text-transform: capitalize;
          color: $red-color;
          font-weight: 700; }
        .search-wrapper {
          text-align: right;
          @media (max-width: $breakpoint-mobile) {
            margin: 15px 0 0; }
          .form-group {
            margin: 0;
            text-align: right;
            width: auto;
            @media (max-width: $breakpoint-mobile) {
              width: 100%;
              padding: 0 2%; }
            .form-control {
              background-color: #f7f7f7;
              width: 297px;
              max-width: 100%;
              background-image: url('/images/search.png');
              background-repeat: no-repeat;
              background-position: 16px;
              padding-left: 50px;
              @media (max-width: $breakpoint-mobile) {
                width: 100%; } } } } }
      .avatar-user {
        @media (max-width: $breakpoint-mobile) {
          padding: 0 2%; } } }
    .back-to-home {
      a {
        color: $dark-grey-color !important;
        svg {
          padding-right: 8px; } } }
    .modal-body {
      padding: 40px 40px 45px;
      @media (max-width: $breakpoint-mobile) {
        padding: 20px 15px; }
      .row {
        @media (max-width: $breakpoint-mobile) {
          align-items: center; } }
      .col {
        @media (max-width: $breakpoint-mobile) {
          max-width: 100%;
          flex: 0 0 100%;
          .avatar-user {
            width: 225px;
            margin: 0 auto; }
          .logo-preview {
            margin: 0 auto;
            float: none; } } } }
    .modal-footer {
      padding-left: 40px;
      padding-right: 40px;
      @media (max-width: $breakpoint-mobile) {
        padding-left: 15px !important;
        padding-right: 15px !important; } } }
  .drag-image-box {
    padding: 0;
    position: relative;
    img {
      border-radius: 24px;
      object-fit: cover;
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      max-width: unset;
      width: 101%;
      width: calc(100% + 2px);
      height: 102px;
      border: 3px solid $primary-color; } }
  .custom-color-row {
    width: 100%;
    display: inline-block;
    margin: 0 0 33px; }
  .preview-switch {
    width: 100%;
    display: inline-block;
    text-align: center;
    margin: 0 0 6px;
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      font-weight: bold;
      color: #9E9E9E; }
    .preview-switcher-wrap {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      border: 2px solid #9E9E9E;
      border-radius: 10px;
      margin-left: 6px;
      .preview-switcher {
        width: 44px;
        height: 40px;
        line-height: 41px;
        display: inline-block;
        vertical-align: middle;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        margin-left: -1px;
        margin-bottom: -1px;
        margin-top: -1px;
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: 0;
          margin-right: -1px; }
        &.active {
          background-color: $primary-color;
          svg {
            path {
              fill: $white-color; } } } } } } }
.header-banner-edit {
  text-align: center;
  margin: 30px 0 0;
  h5 {
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    color: $black-color;
    margin: 0 0 13px; } }
.dashboard-title {
  span {
    margin-left: 0px;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 900;
    &.in-progress {
      background-color: $in-progress-color;
      color: $white-color; }
    &.success {
      background-color: $success-color;
      color: $white-color; } } }
.wizrd-blog-preview.color-palate-preview,
.wizrd-blog-preview.logo-preview {
  width: 100%;
  max-width: 100%; }
.input-select {
  textarea {
    width: 350px !important;
    border: 1px solid #ddd !important;
    border-radius: 24px !important;
    min-height: 42px;
    padding: 10px !important;
    padding-left: 25px !important;
    font-size: 18px !important; }
  .ris-options {
    position: absolute;
    top: 100%;
    left: 24% !important;
    margin: 0;
    width: calc(100% - 54%) !important;
    background: #fff;
    transform: translate(-50%, 0);
    border: 1px solid #eee;
    overflow: auto;
    box-shadow: 0 2px 3px 1px #eee;
    max-height: 245px;
    /* height: 35px; */
    font-size: 18px !important; } }
.wizrd-blog-preview {
  @media (max-width: $breakpoint-mobile) {
    box-shadow: none;
    width: 100%;
    max-width: 100%; } }
//
.customSiteSkel {
  .content-col-4 {
    width: 40%;
    display: inline-block; }
  .content-col-8 {
    width: calc(100% - 42%);
    margin-left: 1.5%;
    display: inline-block; } }
//.edit-site-btns
////.content-col-12
//  //width: 31.8%
.buttonSkel {
  height: 42px;
  border-radius: 100px !important; }
// Signup Template
.signup-section {
  .content-side-inner {
    margin-top: 15px;
    position: relative;
    z-index: 10; }
  .wizrd-banner-content {
    h1 {
      font-size: 22px !important;
      @media (max-width: $breakpoint-mobile) {
        font-size: 17px !important; } }
    h5 {
      font-size: 16px !important;
      @media (max-width: $breakpoint-mobile) {
        font-size: 14px !important; } } }
  .wizrd-recent-blogs {
    padding-bottom: 0 !important; }
  .tabs {
    .nav-tabs {
      margin-bottom: 15px !important; }
    h4 {
      @media (max-width: $breakpoint-ipad) {
        color: $black-color !important; } }
    ul {
      li {
        @media (max-width: $breakpoint-ipad) {
          color: $black-color !important; } } } }
  .wizrd-blog-preview {
    &.final-preview {}
    @media (max-width: $breakpoint-ipad) {
      margin: 20px auto 0; } }
  @media (max-width: $breakpoint-ipad) {
    .form-side {
      min-height: auto !important; } } }
//
.forgot-password-section {
  .content-side-inner {
    margin-top: 15px; }
  .wizrd-banner-content {
    h1 {
      font-size: 22px !important;
      @media (max-width: $breakpoint-mobile) {
        font-size: 17px !important; } }
    h5 {
      font-size: 16px !important;
      @media (max-width: $breakpoint-mobile) {
        font-size: 14px !important; } } }
  .wizrd-recent-blogs {
    padding-bottom: 0 !important; }
  .tabs {
    .nav-tabs {
      margin-bottom: 15px !important; }
    .wizrd-blog-detail {
      h2 {
        font-size: 16px !important;
        text-align: left; } }
    .wizrd-blog-img {
        width: 145px;
        height: 115px;
        overflow: hidden; } }
  .wizrd-blog-preview {
    &.final-preview {}
    @media (max-width: $breakpoint-ipad) {
      margin: 20px auto 0; } }
  @media (max-width: $breakpoint-ipad) {
    .form-side {
      min-height: auto !important; } } }
.signup-container {
  padding: 0 15px;
  position: relative; }
.signup-preview-wrap {
  margin: 0 auto 30px;
  width: 567px;
  max-width: 100%;
  .wizrd-blog-preview {
    max-width: 100%;
    position: relative;
    @media (max-width: $breakpoint-mobile) {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      max-width: calc(100% + 30px); }
    .congrats-animation {
      position: absolute;
      text-align: center;
      left: -120px;
      right: -120px;
      img {
        position: absolute; }
      .congrats-animation-img1 {
        transform: rotate(-45deg);
        top: -90px;
        left: 0; }
      .congrats-animation-img2 {
        top: -115px;
        left: 50%;
        transform: translateX(-50%); }
      .congrats-animation-img3 {
        transform: rotate(45deg);
        right: 0;
        top: -90px; } } }
  .content-side-inner {
    ul {
      li {
        margin: 0 0 20px; } } }
  .wizrd-main-header {
    padding: 6px 20px;
    .wizrd-header-logo {
      a {
        font-size: 16px; } }
    .wizrd-site-navigation {
      .btn {
        padding: 4px 12px; } } } }
.confirm-container {
  .form-side-right {
    @media (max-width: $breakpoint-ipad) {
      order: 1; } }
  .crausel-side-left {
    @media (max-width: $breakpoint-ipad) {
      order: 2;
      margin-bottom: 0;
      padding: 0 15px 0; } } }
.forget-forms-element {
  width: 100%;
  display: inline-block; }
.name-website-selector {
  .domain-input {
    display: inline-flex;
    padding: 0;
    line-height: 48px;
    .form-group {
      width: 100%; }
    p {
      display: none; } } }

.edit-header-footer {
  .image-box {
    width: 100%;
    max-width: 194px;
    height: 67px;
    background: #F7F7F7;
    //border: 2px solid #3032C8
    margin: 0 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    span {
      width: 96%;
      height: 30px;
      border-radius: 100px; } } }


.blog-categories {
  .items {
    .badge {
      padding: 8px;
      border-radius: 100px;
      background: #848484cc;
      color: white;
      margin: 0 4px 4px 0px;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.3;
      font-weight: 500;
      color: #000; } } }
.blog-cat-input {
  align-items: flex-end;
  .ris-input {
    height: 30px !important;
    overflow: hidden !important;
    overflow-wrap: break-word !important; }
  .col-md-2 {
    padding-left: 0;
    @media (max-width: $breakpoint-mobile) {
      padding-left: 15px;
      text-align: center; } } }
.wizrd-blog-preview {
  &.wizrd-inner-preview {
    @media (max-width: $breakpoint-ipad) {
      border-radius: 0 !important; }
    .wizrd-blog-theme {
      .wizrd-site-main {
        .wizrd-recent-blogs {
          .wizrd-blog-list {
            li {
              a {
                .wizrd-blog-box {
                  .wizrd-blog-detail {
                    .custom-Skeleton-loader {
                      height: 10px; }
                    h2 {
                      line-height: 1; }
                    .wizrd-blog-excerpt-detail {
                      .wizrd-blog-author {
                        .wizrd-blog-author-name {
                          margin-bottom: -22px; } } } } } } } } } } } }
  .wizrd-blog-theme {
    @media (max-width: $breakpoint-ipad) {
      border-radius: 0; }
    .wizrd-site-main {
      .wizrd-recent-blogs {
        .wizrd-blog-list {
          li {
            a {
              .wizrd-blog-box {
                .wizrd-blog-detail {
                  h2 {
                    line-height: 1; }
                  .wizrd-blog-excerpt-detail {
                    .wizrd-blog-author {
                      .wizrd-blog-author-name,
                      .wizrd-blog-date {
                        .custom-Skeleton-loader {
                          width: 100px; } }
                      .wizrd-blog-author-name {
                        margin-bottom: 0;
                        @media (max-width: $breakpoint-mobile) {
                          margin-bottom: -22px; } } } } } } } } } } } } }

.wizrd-blog-preview {
  &.mobile-version {
    .wizrd-blog-theme {
      .wizrd-site-header {
        .wizrd-main-header {
          padding: 25px 18px 15px !important;
          @media (max-width: $breakpoint-mobile) {
            padding: 15px 18px 15px !important; } } }
      .wizrd-site-main {
        .wizrd-recent-blogs {
          .wizrd-blog-list {
            flex-direction: column;
            li {
              margin-bottom: 15px;
              @media (max-width: $breakpoint-mobile) {
                margin-bottom: 0; }
              a {}
              .wizrd-blog-box {
                .wizrd-blog-detail {
                  padding-top: 12px;
                  .wizrd-blog-excerpt-detail {
                    .wizrd-blog-author {
                      margin-top: 5px; } } } }
              &:nth-child(2) {
                display: inline-block;
                @media (max-width: $breakpoint-mobile) {
                  display: none; }
                .wizrd-blog-box {
                  .wizrd-blog-img {
                    img {
                      border-bottom-left-radius: 30px;
                      border-bottom-right-radius: 30px;
                      height: 133px; }
                    .wizrd-blog-category {
                      display: none; } }
                  .wizrd-blog-detail {
                    display: none; } } } } } } } } } }

.stop-scroll {
  overflow: hidden !important; }
.rdw-editor-main {
  overflow: auto;
  box-sizing: border-box;
  background: white;
  height: 500px;
  min-height: 500px;
  color: $black-color;
  padding: 20px; }

.custom-Skeleton-loader {
  background-color: lightgray;
  //background-image: linear-gradient(90deg, lightgray, rgb(245, 245, 245), lightgray)
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  height: 15px;
  //animation: 2s ease-in-out 0s infinite normal none running animationSkelton
  @media (max-width: $breakpoint-mobile) {
    height: 10px; } }

.most-out-wrapper {
  padding: 22px 7px !important;
  & .most-out {
    h4 {
      color: #000;
      text-align: center;
      font-weight: bold;
      font-size: 22px;
      line-height: 22px; }
    ul {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: center;
      margin-bottom: 0;
      li {
        width: 33%;
        img {
          width: 50px;
          height: 50px; }
        .img-lable {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #000000; } } } } }

@keyframes animationSkelton {
  0% {
    background-size: 0 100%; }
  100% {
    background-size: 100% 100%; } }
.form-group {
  .dummy-error {
    display: none !important; } }

body {
  .crisp-client {
    .cc-kv6t[data-full-view=true] {
      .cc-1xry .cc-unoo {
        z-index: 99999 !important; } } } }
.custom-color-modal.modal {
  .modal-dialog {
    .modal-content {
      @media (max-width: $breakpoint-mobile) {
        height: 100vh; } } } }

.plan-alert {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  width: 42%;
  height: 40px;
  padding: 7px;
  margin-left: 27%;
  position: absolute;
  z-index: 9999999;
  margin-top: -27px; }

.rdw-image-imagewrapper {
  img {
    max-width: 450px;
    max-height: 450px; } }

// .rdw-left-aligned-block
//   display: none
// .rdw-right-aligned-block
//   display: none
// .rdw-center-aligned-block
//   display: none

.rdw-editor-main {
  & h1,& .h1 {
    font-size: 2.5rem; }
  & h2,& .h2 {
    font-size: 2rem; }
  & h3,& .h3 {
    font-size: 1.75rem; }
  & h4,& .h4 {
    font-size: 1.5rem; }
  & h5,& .h5 {
    font-size: 1.25rem; }
  & h6,& .h6 {
    font-size: 1rem; } }

