@import '../../variables.sass';
.publisher-landing {
    margin-top: -25px;
    @media (max-width: $breakpoint-ipad) {
        margin-top: 0; }
    .container {
        max-width: 1480px !important;
        @media (max-width: 1550px) {
            max-width: 1140px !important; }
        @media (max-width: $breakpoint-ipad-pro) {
            max-width: 95% !important; }
        .landing-color-stepper {
            .container {
                max-width: 100% !important;
                padding: 0 !important; } } } }
.publisher-color-scheme {
    position: relative;
    padding: 46px 0 130px;
    @media (max-width: $breakpoint-ipad-pro) {
        padding: 50px 0;
        background: linear-gradient(113.13deg, #E7EEF9 -136.23%, #F1EEFF 70.11%), #F1EEFF; }
    @media (max-width: $breakpoint-ipad) {
        padding: 30px 0; }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 50%;
        left: 0;
        right: 0;
        background: linear-gradient(113.13deg, #E7EEF9 -136.23%, #F1EEFF 70.11%), #F1EEFF;
        border-top-right-radius: 100px;
        @media (max-width: $breakpoint-ipad-pro) {
            display: none; } }
    .container {
        position: relative;
        z-index: 2;
        max-width: 1222px !important;
        @media (max-width: 1550px) {
            max-width: 1000px !important; }
        @media (max-width: 1250px) {
            max-width: 800px !important; }
        @media (max-width: $breakpoint-ipad-pro) {
            max-width: 95% !important; }
        @media (max-width: $breakpoint-ipad) {
            max-width: 100% !important;
            padding: 0;
            .home-heading {
                padding: 0 15px; } } }
    .home-next-step-btn {
        background-image: url('/images/next-tooltip-bg.png');
        width: 192px;
        height: 123px;
        position: absolute;
        bottom: 17px;
        left: -68px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9;
        @media (max-width: $breakpoint-ipad) {
            bottom: 23px;
            left: -64px;
            display: none; }
        button {
            font-size: 14px;
            line-height: 1.3;
            font-weight: 600;
            color: #fff;
            background-color: #FF7A00;
            border-radius: 100px;
            width: 111.47px;
            text-align: center;
            padding: 7.54px 15px;
            border: none;
            text-transform: capitalize;
            position: absolute;
            bottom: 54px;
            left: 37px; } } }
.template-mobile-preview {
    position: absolute;
    bottom: 0;
    right: -165px;
    z-index: 2;
    @media (max-width: $breakpoint-ipad-pro) {
        display: none; } }
