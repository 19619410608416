@import '../variables.sass';
.site-footer {
    background: #FFF4EA;
    padding: 60px 0 0 !important;
    margin-top: 60px;
    @media (max-width: $breakpoint-ipad-pro) {
        padding: 30px 0 0 !important; }
    .container {
        max-width: 100% !important;
        padding-left: 65px;
        padding-right: 65px;
        @media (max-width: $breakpoint-ipad) {
            padding-left: 15px;
            padding-right: 15px; } }
    .row {
        margin-left: 0;
        margin-right: 0; }
    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        padding-left: 0;
        padding-right: 0; }
    .footer-top {
        align-items: center;
        margin-bottom: 60px !important;
        @media (max-width: $breakpoint-ipad-pro) {
            margin-bottom: 30px !important; }
        img {
            width: 128px; }
        .footer-social-icon-menu {
            &.text-right {
                @media (max-width: $breakpoint-ipad-pro) {
                    text-align: center !important;
                    margin-top: 20px; } } } }
    .copyright {
        text-align: center;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid rgba(18, 17, 27, 0.3);
        font-size: 14px;
        font-weight: 400; }
    .footer-logo-copyright {
        display: flex;
        align-items: center;
        @media (max-width: $breakpoint-ipad-pro) {
            flex-direction: column; }
        ul {
            margin-left: 76px;
            @media (max-width: 1550px) {
                margin-left: 40px; }
            @media (max-width: $breakpoint-ipad-pro) {
                margin-left: 0;
                margin-top: 20px; }
            li {
                padding: 0 20px;
                @media (max-width: $breakpoint-mobile) {
                    width: 100%; }
                a {
                    font-size: 16px;
                    font-weight: 700;
                    color: $black-color;
                    @media (max-width: $breakpoint-ipad-pro) {
                        font-size: 14px; }
                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $orange-secondary-color; } } } } }
    .social-links {
        margin-top: 0 !important;
        a {
            background-color: transparent !important;
            opacity: 0.4;
            width: auto !important;
            &:hover,
            &:focus {
                text-decoration: none;
                opacity: 1; } } } }
