.upgradePlanSection {
    background: $white-color;
    .upgradePlanText {
        p {
            font-weight: bold;
            font-size: 16px;
            color: $dark-grey-color; }
        h2 {
            font-style: normal;
            font-weight: normal;
            font-size: 34px;
            color: $light-blue-color;
            @media (max-width: $breakpoint-mobile) {
                font-size: 24px; } } }
    .react-multi-carousel-item {
        padding: 20px 10px; }
    .plan-detail {
        padding: 42px 25px !important;
        .plan-name {
            font-weight: bold;
            font-size: 36px;
            color: $black-color; }
        .plan-action {
            display: flex;
            flex-direction: column; } } }
.profile-add-payment {
    input {
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.5px;
        color: #1B1B1B;
        font-weight: normal;
        border: 1px solid $white-color;
        background-color: $white-color !important;
        border-radius: 24px;
        padding: 10px 15px;
        width: 100%;
        min-height: 48px; } }
.myAccount {
    &.row {
        margin: 0; }
    .header-profile-img {
        background: transparent !important;
        .dropdown-label {
            display: none !important;
            @media (max-width: $breakpoint-mobile) {
                display: inline-block !important; }
            @media (max-width: $breakpoint-ipad) {
                display: inline-block !important; } } } }
.profile-edit-info {
    .profile-avtar-info {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        .profile-avtar {
            margin-right: 20px; } } }
