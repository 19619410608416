@import '../../variables.sass';
.publisher-seo {
    padding: 122px 0 0;
    @media (max-width: $breakpoint-ipad-pro) {
        padding: 60px 0 0; }
    @media (max-width: $breakpoint-ipad) {
        padding: 30px 0 0; }
    .publisher-seo-compare {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 45px;
        @media (max-width: $breakpoint-ipad) {
            flex-wrap: wrap;
            margin-top: 30px; }
        .publisher-seo-individual {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc(50% - 15px);
            padding: 44px 30px 69px;
            @media (max-width: 1550px) {
                padding: 30px 30px 35px; }
            @media (max-width: $breakpoint-ipad) {
                width: 100%;
                margin-bottom: 30px; }
            &.lightGreen {
                background: #EBFFF8;
                border-radius: 20px; }
            &.lightRed {
                background: #FFEAEC;
                border-radius: 20px; }
            .publisher-seo-compare-name {
                margin-bottom: 36px;
                font-size: 1.916rem;
                line-height: 36px;
                color: $black-color;
                font-weight: 700;
                @media (max-width: $breakpoint-ipad-pro) {
                    font-size: 20px;
                    margin-bottom: 25px; }
                img {
                    width: 128px; } }
            .inlineData {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                width: 100%;
                @media (max-width: $breakpoint-mobile) {
                    flex-wrap: wrap; }
                .orb_label {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: -0.05em;
                    color: #12111B;
                    text-transform: capitalize;
                    margin-top: 15px;
                    @media (max-width: 1550px) {
                        font-size: 16px;
                        margin-top: 0; }
                    @media (max-width: $breakpoint-ipad-pro) {
                        font-size: 14px; } } } } }
    .publisher-seo-performace {
        display: flex;
        margin-top: 60px;
        align-items: center;
        @media (max-width: $breakpoint-ipad-pro) {
            margin-top: 30px; }
        @media (max-width: $breakpoint-ipad) {
            flex-direction: column;
            margin-top: 0; }
        .performace-graph {
            width: 915px;
            @media (max-width: 1550px) {
 }                //width: 59%
            @media (max-width: $breakpoint-ipad-pro) {
 }                //width: 55%
            @media (max-width: $breakpoint-ipad) {
                width: 100%; } }
        .performace-detail {
            width: 37%;
            padding-left: 61px;
            @media (max-width: 1550px) {
                padding-left: 45px;
                width: 41%; }
            @media (max-width: $breakpoint-ipad-pro) {
                width: 45%; }
            @media (max-width: $breakpoint-ipad) {
                width: 100%;
                padding-left: 0;
                padding-top: 30px; }
            h4 {
                font-weight: 600;
                font-size: 30px;
                line-height: 36px;
                color: $black-color;
                letter-spacing: -0.05em;
                margin-bottom: 37px;
                @media (max-width: 1550px) {
                    font-size: 26px;
                    margin-bottom: 25px; }
                @media (max-width: $breakpoint-ipad-pro) {
                    font-size: 24px;
                    margin-bottom: 20px; }
                @media (max-width: $breakpoint-ipad) {
                    font-size: 20px;
                    margin-bottom: 10px; } }
            ul {
                li {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                    color: $black-color;
                    position: relative;
                    background-image: url('/images/performance-checked.svg');
                    background-position: 0 12px;
                    background-repeat: no-repeat;
                    margin: 0 0 20px 0;
                    padding: 0 0 0 35px;
                    line-height: 30px;
                    letter-spacing: 0;
                    @media (max-width: 1550px) {
                        font-size: 16px;
                        line-height: 1.3; }
                    @media (max-width: $breakpoint-ipad-pro) {
                        font-size: 14px;
                        margin: 0 0 10px 0; }
                    &:last-child {
                        margin-bottom: 0; } } } } } }
.publisher-seo-chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    @media (max-width: $breakpoint-mobile) {
        flex-direction: row;
        flex-wrap: wrap; }
    .seo-chart {
        display: inline-block;
        width: auto;
        margin-left: 33px;
        @media (max-width: 1550px) {
            margin-left: 20px; }
        @media (max-width: $breakpoint-ipad-pro) {
            margin-left: 10px; }
        @media (max-width: $breakpoint-mobile) {
            width: 43%;
            margin-left: 2%;
            margin-bottom: 20px;
            &:nth-child(2n+10) {
                margin-left: 0; } }
        &:first-child {
            margin-left: 0; }
        .seo-chart-container {
            width: auto;
            display: inline-block;
            .seo-chart-label {
                font-size: 20px;
                line-height: 24px;
                color: #12111B;
                font-weight: 500;
                letter-spacing: 0;
                margin: 16px 0 0;
                @media (max-width: 1550px) {
                    font-size: 16px;
                    line-height: 1.3;
                    margin: 8px 0 0; }
                @media (max-width: $breakpoint-ipad-pro) {
                    font-size: 14px; }
                @media (max-width: $breakpoint-mobile) {
                    margin: 5px 0 0; } }
            .seo-chart-circle {
                position: relative;
                width: auto;
                display: inline-block;
                svg {
                    max-width: 100%;
                    @media (max-width: 1550px) {
                        width: 105px;
                        height: 105px; }
                    @media (max-width: $breakpoint-ipad-pro) {
                        width: 95px;
                        height: 95px; }
                    @media (max-width: $breakpoint-mobile) {
                        width: 90px;
                        height: 90px; } }
                .seo-chart-counter {
                    font-size: 30px;
                    line-height: 36px;
                    letter-spacing: 0;
                    font-weight: bold;
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    @media (max-width: 1550px) {
                        font-size: 20px;
                        line-height: 1.3; }
                    @media (max-width: $breakpoint-ipad-pro) {
                        font-size: 16px; } } } } } }
