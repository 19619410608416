@import '../../variables.sass';
.publisher-landing {
    .publisher-landing-hero {
        .container {
            max-width: 1222px !important;
            @media (max-width: 1550px) {
                max-width: 980px !important; }
            @media (max-width: $breakpoint-ipad-pro) {
                max-width: 710px !important; } } } }
.publisher-landing-hero {
    text-align: center;
    .publisher-landing-hero-inner {
        padding: 130px 0 30px;
        position: relative;
        overflow: hidden;
        @media (max-width: 1550px) {
            padding: 90px 0 30px; }
        @media (max-width: $breakpoint-ipad-pro) {
            padding: 50px 0; }
        @media (max-width: $breakpoint-ipad) {
            padding: 30px 0; }
        &:after {
            content: "";
            position: absolute;
            height: 1044px;
            width: 1044px;
            bottom: -100%;
            border-radius: 100%;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
            border: 1px solid rgba(85, 64, 191, 0.2);
            @media (max-width: $breakpoint-ipad-pro) {
                display: none; } } }
    h1 {
        font-size: 48px;
        line-height: 1.3;
        font-weight: 700;
        color: $black-color;
        max-width: 1140px;
        margin: 0 auto;
        width: 100%;
        @media (max-width: 1550px) {
            font-size: 40px; }
        @media (max-width: $breakpoint-ipad-pro) {
            font-size: 30px; }
        @media (max-width: $breakpoint-ipad) {
            font-size: 20px; }
        span {
            color: $purple-heading-color; } }
    p {
        font-weight: 500;
        font-size: 19px;
        color: #12111b;
        opacity: 0.8;
        max-width: 700px;
        margin: 22px auto 0;
        letter-spacing: 0;
        @media (max-width: $breakpoint-ipad) {
            font-size: 14px;
            margin: 15px auto 0; }
        b {
            font-weight: 800; } } }
