@import '../../variables.sass';
.publisher-faq {
    .publisher-faq-inner {
        .accordion-faq {
            margin-top: 40px;
            .accordion-card {
                border-top: 1px solid rgba(18, 17, 27, 0.3);
                border-radius: 0;
                padding-left: 0;
                position: relative;

                &.borderBottom {
                    border-bottom: 1px solid rgba(18, 17, 27, 0.3); }
                .accordion-card-header {
                    padding: 25px 0;
                    margin: 0;
                    @media (max-width: $breakpoint-ipad) {
                        padding: 15px 0; } }
                .btn-link {
                    font-weight: 600 !important;
                    font-size: 20px !important;
                    line-height: 24px !important;
                    color: $black-color !important;
                    text-align: left;
                    padding-left: 80px !important;
                    position: relative;
                    z-index: 1;
                    @media (max-width: $breakpoint-ipad) {
                        padding-left: 20px !important; }
                    @media (max-width: 1550px) {
                        font-size: 18px !important;
                        line-height: 22px !important; }
                    @media (max-width: $breakpoint-ipad) {
                        font-size: 14px !important;
                        line-height: 1.3 !important; } }
                .changeIcon {
                    position: absolute;
                    left: 24px;
                    top: 26px;
                    @media (max-width: $breakpoint-ipad) {
                        left: 0;
                        top: 18px; }
                    svg {
                        @media (max-width: $breakpoint-ipad) {
                            width: 10px;
                            height: 10px; } }
                    //.hideIcon
 }                    //  //display: none
                .hideIcon {
                    margin-top: -10px;
                    @media (max-width: $breakpoint-ipad) {
                        margin-top: -18px; } } }
            .collapse {
                & + .changeIcon {
                        .hideIcon {
                            display: none; }
                        .showIcon {
                            display: block !important; } } }
            .collapse {
                &.show + .changeIcon {
                            top: 36px !important;
                            .showIcon {
                                display: none !important; }
                            .hideIcon {
                                display: block !important; } } }
            .accordion-card-body {
                margin: 7px 40px 30px 22px !important;
                font-size: 14px;
                line-height: 1.5;
                color: #12111B;
                opacity: 0.6;
                position: relative;
                padding-left: 100px;
                width: 800px;
                max-width: 100%;
                @media (max-width: $breakpoint-ipad) {
                    margin: 0 15px 15px 15px !important;
                    padding-left: 0; }
                &:before {
                    content: "";
                    background: rgba(85, 64, 191, 0.5);
                    border-radius: 100px;
                    position: absolute;
                    left: 80px;
                    top: 4px;
                    bottom: 4px;
                    width: 2px;
                    @media (max-width: $breakpoint-ipad) {
                        display: none; } } } } } }
