@import '../../variables.sass';
.publisher-key-feature {
    padding: 121px 0 107px;
    @media (max-width: $breakpoint-ipad-pro) {
        padding: 60px 0; }
    @media (max-width: $breakpoint-ipad) {
        padding: 30px 0; }
    .publisher-key-feature-inner {
        .keySection {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 57px 0 0;
            @media (max-width: $breakpoint-ipad-pro) {
                margin: 30px 0 0; }
            @media (max-width: $breakpoint-mobile) {
                flex-direction: column; }
            .light-box,
            .dark-box {
                border-radius: 20px;
                padding: 50px 40px;
                margin-left: 2.3%;
                margin-bottom: 2.3%;
                width: 31.8%;
                @media (max-width: 1550px) {
                    padding: 30px; }
                @media (max-width: $breakpoint-ipad) {
                    width: 48.7%;
                    padding: 15px !important; }
                @media (max-width: $breakpoint-mobile) {
                    width: 100%;
                    margin-left: 0;
                    margin-bottom: 20px; }
                &:nth-child(3n+1) {
                    margin-left: 0;
                    @media (max-width: $breakpoint-ipad) {
                        margin-left: 2%; }
                    @media (max-width: $breakpoint-mobile) {
                        margin-left: 0; } }
                &:nth-child(2n+1) {
                    @media (max-width: $breakpoint-ipad) {
                        margin-left: 0 !important; } }
                .countDetail {
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: 400;
                    opacity: 0.8;
                    @media (max-width: 1550px) {
                        font-size: 16px;
                        line-height: 1.4; }
                    @media (max-width: $breakpoint-ipad-pro) {
                        font-size: 16px; } } }
            .light-box {
                background: #F1EEFF;
                .countDetail {
                    color: $black-color; } }
            .dark-box {
                background: #5540BF;
                .countDetail {
                    color: $white-color; } }
            .countPercentage {
                background: #FFFFFF;
                box-shadow: 4px 4px 20px rgba(85, 64, 191, 0.2);
                border-radius: 10px;
                //width: 90px
                height: 90px;
                font-size: 30px;
                line-height: 36px;
                font-weight: 700;
                margin-bottom: 35px;
                color: $primary-color;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 25px;
                padding-right: 25px;
                //text-align: center
                @media (max-width: 1550px) {
                    font-size: 25px;
                    line-height: 1.3;
                    margin-bottom: 25px;
                    padding-left: 15px;
                    padding-right: 15px; }
                @media (max-width: $breakpoint-ipad-pro) {
                    font-size: 22px; }
                @media (max-width: $breakpoint-mobile) {
                    font-size: 18px;
                    height: auto;
                    padding: 10px;
                    margin-bottom: 15px; } } } } }
